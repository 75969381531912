import '../css/futures.css'
import '../css/goexF.css'
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux'
import { checkSelectType, checkLogin, check_Error, changeBybit_markPrice, change_Bybit_openorder } from '../../store';
import { useTranslation } from "react-i18next";
import ErrorBoundary from '../ErrorBoundary'
import isEqual from 'lodash/isEqual';
import { changeNum, padZero } from "../common/function"
import '@fortawesome/fontawesome-free/css/all.css';

import ByFsection1 from './Byfutures/ByFsection1';
import ByFsection4 from './Byfutures/ByFsection4';
import ByFsection8 from './Byfutures/ByFsection8';
import ByFsection7 from './Byfutures/ByFsection7';
import ByFsection6 from './Byfutures/ByFsection6';

let data = []
let preArr = []
let arr = []
let pre_symbol = ""
let prePrice = 0
let openorder_data = []

function ByFutures() {
    const { t } = useTranslation();
    const CryptoJS = require('crypto-js');

    let By_apiKey = useSelector((state) => { return state.By_apiKey })
    let BybitF = useSelector((state) => { return state.Bybit_coinF });
    let dark = useSelector((state) => { return state.Darkmode.value });
    let Bybit_wallet_usdt = useSelector((state) => { return state.Bybit_wallet_usdt });
    let Bybit_position = useSelector((state) => { return state.Bybit_position });
    let login = useSelector((state) => { return state.login.value });

    const darkname = dark ? 'dark' : "";

    let { symbol } = useParams();
    let dispatch = useDispatch();

    const [windowtype, setWindowtype] = useState(true);
    const [windowtypeM, setWindowtypeM] = useState(true);
    const [tab, setTab] = useState(localStorage.getItem('f_coinList') ? localStorage.getItem('f_coinList') == "true" ? true : false : true)
    const [tab1, setTab1] = useState(localStorage.getItem('f_orderbook') ? localStorage.getItem('f_orderbook') == "true" ? true : false : true)
    const [tab3, setTab3] = useState(true)
    const [tab4, setTab4] = useState(1)
    const [tab5, setTab5] = useState(0)
    const [lastBuy, setLastBuy] = useState(0);
    const [lastSell, setLastSell] = useState(0);
    const [checkordertab, setCheckordertab] = useState(true)
    const [speedorder, setSpeedorder] = useState([0, 0])
    const [fwallet, setFwallet] = useState([])
    const [current, setCurrent] = useState('0');
    const [orderReload, setOrderReload] = useState(false)

    const [exinfo, setExinfo] = useState([])
    const [totalexinfo, setTotalExinfo] = useState([])
    const [positionAmount, setPositionAmount] = useState([false, ""])
    const [positionData, setPositionData] = useState([]);

    const [max, setMax] = useState(4)
    const [maxCnt, setMaxCnt] = useState(true)
    const [firstmargin, setFirstmargin] = useState([])
    const [maxPosition, setMaxPosition] = useState(0)
    const [marginbtn, setMarginbtn] = useState(0)
    const [marginbtn1, setMarginbtn1] = useState(1)

    const [tradeVolume, setTradeVolume] = useState("10000")
    const [currentPrice, setCurrentPrice] = useState(0.00000000001)
    const [checkCurrentPosition, setCheckCurrentPosition] = useState(false)
    const [btnbtn, setBtnbtn] = useState(0);

    const [markPrice, setMarkPrice] = useState(0)
    const [indexPrice, setIndexPrice] = useState(0)
    const [nextTime, setNextTime] = useState("00:00:00")
    const [close, setClose] = useState(false)
    const [fundingFee, setFundingFee] = useState(0)
    const [fundingFeeAmount, setFundingFeeAmount] = useState(0)
    const [positionDirect, setPositionDirect] = useState(0)
    const [risk_balance, setRisk_balance] = useState(0)
    const [risk_position, setRisk_position] = useState(0)
    const [margin_type, setMargin_type] = useState("")

    const [tmm, setTMM] = useState(0)
    const [upnl, setUPNL] = useState(0)
    const [pBoth, setPBoth] = useState([0, 0, 1])

    const [Mtab, setMtab] = useState(0)
    const [Mordertab, setMordertab] = useState(true)
    const [openorder, setOpenorder] = useState([])

    let USDTSymbolF = BybitF ? BybitF : [];
    dispatch(checkSelectType(2))

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString) {
            const obj = JSON.parse(objString);
            if (Date.now() > obj.expire) {
                localStorage.removeItem('token');
                dispatch(checkLogin(false));
            }
            else {
                dispatch(checkLogin(true));
            }
        }
        else {
            dispatch(checkLogin(false));
        }
    }, []);

    useEffect(() => {
        setMaxCnt(true)
        setMtab(0)
    }, [symbol])

    USDTSymbolF.map(function (a, i) {
        if (a.symbol === symbol) {
            if (maxCnt) {
                setMax(a.point)
                setMaxCnt(false)
            }
            data = {
                symbol: a.symbol, ccy: a.ccy, priceChangePercent: a.priceChangePercent, lastPrice: a.lastPrice, volume: a.volume
                , highPrice: a.highPrice, lowPrice: a.lowPrice, priceChange: a.priceChange, point: a.point
            }
        }
    })

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 1550) setTab(false)
            else setTab(true)
            if (window.innerWidth >= 1300) setWindowtype(true)
            else {
                setWindowtype(false)
                change_OrderbookTab(true)
            }
            if (window.innerWidth >= 1000) {
                setWindowtypeM(true)
                setMordertab(true)
            }
            else setWindowtypeM(false)
        };

        window.addEventListener('resize', handleResize);
        handleResize()

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        let objString = localStorage.getItem("TradeVolume")
        if (objString) {
            setTradeVolume(objString)
        }
    }, [])

    useEffect(() => {
        if (!currentPrice || Number(currentPrice) === 0.00000000001) {
            document.title = `${data.lastPrice ? Number(data.lastPrice).toFixed(max) : "0.0000"} | ${data.ccy ? data.ccy + "USDT" : ""} | Bybit`;
            setCurrentPrice(data.lastPrice ? data.lastPrice : "0.00000000001")
        }
        else {
            document.title = `${Number(currentPrice).toFixed(max)} | ${data.ccy ? data.ccy + "USDT" : ""} | Bybit`;
        }
    }, [currentPrice, data]);

    useEffect(() => {
        setCurrentPrice(0.00000000001)
    }, [symbol])

    useEffect(() => {
        if (By_apiKey?.S_api?.length > 0) {
            CheckMarginType()
        }
    }, [By_apiKey])

    useEffect(() => {
        if (By_apiKey?.S_api?.length > 0) {
            FuturesInstruments()
        }
    }, [symbol, By_apiKey])

    useEffect(() => {
        const by_timestamp = localStorage.getItem('bybit_timestamp');
        if (login && by_timestamp && By_apiKey?.S_api?.length > 0) {
            let socket = new WebSocket(`wss://stream.bybit.com/v5/private`);

            const generateSignature = (apiSecret, expires) => {
                const paramStr = `GET/realtime${expires}`;
                return CryptoJS.HmacSHA256(paramStr, apiSecret).toString(CryptoJS.enc.Hex);
            };

            const expires = Number(by_timestamp) + 100000;
            const signature = generateSignature(By_apiKey.S_sec, expires);

            const loginMessage = {
                op: "auth",
                args: [By_apiKey.S_api, expires.toString(), signature]
            }

            const subscribeMessage = {
                op: "subscribe",
                args: ["order"]
            }

            socket.onopen = () => {
                socket.send(JSON.stringify(loginMessage));
                const intervalId = setInterval(() => {
                    if (socket.readyState === WebSocket.OPEN) {
                        socket.send(JSON.stringify({ "op": "ping" }));
                    }
                }, 25000)

                return () => {
                    clearInterval(intervalId)
                }
            };

            socket.onmessage = function (event) {
                let tmp = JSON.parse(event.data);
                if (tmp?.op != "pong") {
                    if (tmp?.op == "auth" && tmp.success) {
                        socket.send(JSON.stringify(subscribeMessage))
                    }
                    else if (tmp.topic === "order") {
                        let list = tmp?.data ?? []
                        let arr = []
                        setOrderReload(e => !e)

                        for (let i = 0; i < list.length; i++) {
                            let data = list[i]
                            if (data.orderStatus == "New") {
                                let fil = openorder_data.filter((item) => item.orderId != data.orderId)
                                arr = [{ orderId: data.orderId, symbol: data.symbol, orderType: data.orderType, px: data.price, fillSz: 0, sz: data.qty, side: data.side, orderStatus: data.orderStatus, executedQty: data.cumExecQty, timeInForce: data.timeInForce }]
                                setOpenorder([...fil, ...arr])
                                dispatch(change_Bybit_openorder([...fil, ...arr]))
                                openorder_data = [...fil, ...arr]
                            }
                            else if (data.orderStatus == "Untriggered") {
                                let fil = openorder_data.filter((item) => item.orderId != data.orderId)
                                arr = [{ orderId: data.orderId, symbol: data.symbol, orderType: data.stopOrderType, px: data.triggerPrice, fillSz: 0, sz: data.qty, side: data.side, orderStatus: data.orderStatus, executedQty: data.cumExecQty, timeInForce: data.timeInForce }]
                                setOpenorder([...fil, ...arr])
                                dispatch(change_Bybit_openorder([...fil, ...arr]))
                                openorder_data = [...fil, ...arr]
                            }
                            else {
                                arr = openorder_data.filter((item) => item.orderId != data.orderId)
                                setOpenorder(arr)
                                dispatch(change_Bybit_openorder(arr))
                                openorder_data = arr
                            }
                        }
                    }
                }
            }
            socket.onclose = function (event) {
                console.log("Bybit Order Close")
            };
            socket.error = function (error) {
                window.location.reload()
            }
            return () => {
                socket.close();
            }
        }
    }, [login, By_apiKey])

    useEffect(() => {
        if (By_apiKey?.S_api?.length > 0) {
            FuturesAllOpenOrder()
        }
    }, [By_apiKey])

    const FuturesAllOpenOrder = async () => {
        const by_timestamp = localStorage.getItem('bybit_timestamp');
        if (by_timestamp) {
            const paramStr = `${by_timestamp}${By_apiKey.S_api}${10000}category=linear&settleCoin=USDT`
            await axios.get(`https://api.bybit.com/v5/order/realtime?category=linear&settleCoin=USDT`, {
                headers: {
                    "X-BAPI-SIGN": CryptoJS.HmacSHA256(paramStr, By_apiKey.S_sec).toString(CryptoJS.enc.Hex),
                    "X-BAPI-API-KEY": By_apiKey.S_api,
                    "X-BAPI-TIMESTAMP": by_timestamp,
                    "X-BAPI-RECV-WINDOW": 10000
                }
            }).then((response) => {
                let tmp = response.data
                if (tmp.retCode == 10004 || tmp.retCode == 10001) {
                    FuturesAllOpenOrder()
                }
                else if (tmp.retCode == 0) {
                    let arr = []
                    let list = tmp?.result?.list ?? []
                    for (let i = 0; i < list.length; i++) {
                        let data = list[i]
                        if (data.orderStatus == "Untriggered") arr.push({ orderId: data.orderId, symbol: data.symbol, orderType: data.stopOrderType, px: data.triggerPrice, fillSz: 0, sz: data.qty, side: data.side, orderStatus: data.orderStatus, executedQty: data.cumExecQty, timeInForce: data.timeInForce })
                        else arr.push({ orderId: data.orderId, symbol: data.symbol, orderType: data.orderType, px: data.price, fillSz: 0, sz: data.qty, side: data.side, orderStatus: data.orderStatus, executedQty: data.cumExecQty, timeInForce: data.timeInForce })
                    }
                    setOpenorder(arr)
                    dispatch(change_Bybit_openorder(arr))
                    openorder_data = arr
                }
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 76, "msg": error }))
            })
        }
        else {
            setTimeout(() => {
                FuturesAllOpenOrder()
            }, 1000)
        }
    }

    useEffect(() => {
        let nextArr = []
        let cnt = false
        let t_tmm = 0
        let t_upnl = 0
        let t_price = 0
        let t_quantity = 0
        let t_leverage = 1

        const objString = localStorage.getItem('token');
        if (objString) {
            arr = Bybit_position.filter(i => Number(i.size) != 0)
            arr.map(function (a, i) {
                if (symbol == a.symbol) {
                    t_price = Number(a?.avgPrice ? a.avgPrice : a.entryPrice)
                    t_quantity = Number(a.size) * (a.side == "Buy" ? 1 : -1)
                    t_leverage = Number(a.leverage)
                    cnt = true
                }
                else {
                    t_tmm += Number(a.positionValue)
                    t_upnl += Number(a.unrealisedPnl)
                }
                nextArr.push({ symbol: a.symbol, size: a.size })
            })

            setCheckCurrentPosition(cnt)

            let ArraysEqual = isEqual(preArr, nextArr);

            if (!ArraysEqual) {
                setOrderReload(e => !e)
            }

            preArr = nextArr
            setTMM(t_tmm)
            setUPNL(t_upnl)
            setPBoth([t_price, t_quantity, t_leverage])

            setPositionData(arr)
        }
    }, [Bybit_position, symbol])

    useEffect(() => {
        let cnt = 0
        let marginType = ""
        let risk_cross = 0
        let risk_isolated = 0
        let risk_cross_WB = 0
        let risk_isolated_WB = 0

        if (positionData) {
            for (let i = 0; i < positionData.length; i++) {
                let tmp = positionData[i]
                if (symbol == tmp.symbol) {
                    cnt = 1
                    setFundingFeeAmount(Math.abs(tmp.positionValue))
                    setPositionDirect(tmp.side == "Buy" ? 1 : -1)

                    risk_isolated = Math.abs(Number(tmp.positionValue))
                    risk_isolated_WB = Math.abs(Number(tmp.positionValue) / Number(tmp.leverage))
                }
                if (marginbtn == 0) {
                    risk_cross += Math.abs(Number(tmp.positionValue))
                }
                else {
                    risk_cross_WB += Math.abs(Number(tmp.positionValue) / Number(tmp.leverage))
                }
            }
            if (cnt === 0) {
                setFundingFeeAmount(0)
                setPositionDirect(0)
                setRisk_balance(0)
                setRisk_position(0)
                setMargin_type("0")
            }
            else {
                setMargin_type(marginType)
                if (marginbtn == 1) {
                    setRisk_position(risk_isolated)
                    setRisk_balance(risk_isolated_WB)
                }
                else {
                    setRisk_position(risk_cross)
                    setRisk_balance(risk_cross_WB)
                }
            }
        }
    }, [positionData, marginbtn])

    useEffect(() => {
        pre_symbol = symbol
        let socket = new WebSocket(`wss://stream.bybit.com/v5/public/linear`);

        socket.onopen = () => {
            const subscribeMessage = JSON.stringify({
                op: 'subscribe',
                args: [`tickers.${symbol}`]
            });

            socket.send(subscribeMessage);
        };

        socket.onmessage = function (event) {
            let tmp = JSON.parse(event.data);
            if (tmp) {
                if (tmp?.data?.markPrice) {
                    setMarkPrice(tmp?.data?.markPrice)
                    dispatch(changeBybit_markPrice(tmp?.data?.markPrice))
                }
                if (tmp?.data?.indexPrice) {
                    setIndexPrice(tmp?.data?.indexPrice)
                }
                if (tmp?.data?.fundingRate) {
                    setFundingFee(tmp?.data?.fundingRate)
                }
                if (tmp?.data?.nextFundingTime) {
                    changeNextTime(tmp?.data?.nextFundingTime)
                }
            }
        }
        socket.onclose = function (event) {
            if (pre_symbol == symbol) {
                setClose(e => !e)
            }
        };
        socket.error = function (error) {
            window.location.reload()
        }
        return () => {
            socket.close();
        }
    }, [symbol, close])

    const changeNextTime = (time) => {
        const intervalId = setInterval(() => {
            let date = Math.floor((Number(time) - new Date()) / 1000)
            if (date === 0) clearInterval(intervalId)
            let formattedDate = `${padZero(Math.floor(date / (60 * 60)))}:${padZero(Math.floor((date / 60) % 60))}:${padZero(Math.floor(date % 60))}`;
            setNextTime(formattedDate)
        }, 1000)
        return () => clearInterval(intervalId);
    }

    useEffect(() => {
        if (Bybit_wallet_usdt.length > 0) {
            setCurrent(Bybit_wallet_usdt[0].availableToWithdraw)
            setFwallet(Bybit_wallet_usdt[0])
        }
    }, [Bybit_wallet_usdt])

    useEffect(() => {
        ExchangeInformation()
        MarginInformation()
    }, [symbol])

    const ExchangeInformation = async () => {
        await axios.get('https://api.bybit.com/v5/market/instruments-info?category=linear'
        ).then((response) => {
            let tmp = response?.data?.result?.list
            if (tmp) {
                tmp.map(function (a, i) {
                    if (a.symbol == symbol) {
                        setExinfo(a)
                    }
                })
                setTotalExinfo(tmp)
            }
        }).catch((error) => {
            dispatch(check_Error({ "bol": true, "no": 46, "msg": error }))
        })
    }

    const MarginInformation = async () => {
        await axios.get(`https://api.bybit.com/v5/market/risk-limit?category=linear&symbol=${symbol}`
        ).then((response) => {
            let tmp = response?.data?.result?.list
            if (tmp) {
                // console.log(tmp)
                setFirstmargin(tmp)
            }
        }).catch((error) => {
            dispatch(check_Error({ "bol": true, "no": 46, "msg": error }))
        })
    }

    const CheckMarginType = async () => {
        const by_timestamp = localStorage.getItem('bybit_timestamp');
        if (by_timestamp) {
            const paramStr = `${by_timestamp}${By_apiKey.S_api}${10000}`
            await axios.get(`https://api.bybit.com/v5/account/info`, {
                headers: {
                    "X-BAPI-SIGN": CryptoJS.HmacSHA256(paramStr, By_apiKey.S_sec).toString(CryptoJS.enc.Hex),
                    "X-BAPI-API-KEY": By_apiKey.S_api,
                    "X-BAPI-TIMESTAMP": by_timestamp,
                    "X-BAPI-RECV-WINDOW": 10000
                }
            }).then((response) => {
                let tmp = response.data
                if (tmp.retCode == 10004 || tmp.retCode == 10001) {
                    CheckMarginType()
                }
                else if (tmp.retCode == 0) {
                    setMarginbtn(tmp?.result?.marginMode == "REGULAR_MARGIN" ? 0 : 1 ?? 0)
                }
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 110.2, "msg": error }))
            })
        }
        else {
            setTimeout(() => {
                CheckMarginType()
            }, 1000)
        }
    }

    const FuturesInstruments = async () => {
        const by_timestamp = localStorage.getItem('bybit_timestamp');
        if (by_timestamp) {
            const paramStr = `${by_timestamp}${By_apiKey.S_api}${10000}category=linear&settleCoin=USDT&symbol=${symbol}`
            await axios.get(`https://api.bybit.com/v5/position/list?category=linear&settleCoin=USDT&symbol=${symbol}`, {
                headers: {
                    "X-BAPI-SIGN": CryptoJS.HmacSHA256(paramStr, By_apiKey.S_sec).toString(CryptoJS.enc.Hex),
                    "X-BAPI-API-KEY": By_apiKey.S_api,
                    "X-BAPI-TIMESTAMP": by_timestamp,
                    "X-BAPI-RECV-WINDOW": 10000
                }
            }).then((response) => {
                let tmp = response.data
                if (tmp.retCode == 10004 || tmp.retCode == 10001) {
                    FuturesInstruments()
                }
                else if (tmp.retCode == 0) {
                    setMarginbtn1(tmp?.result?.list[0]?.leverage ?? 1)
                }
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 110.3, "msg": error }))
            })
        }
        else {
            setTimeout(() => {
                FuturesInstruments()
            }, 1000)
        }
    }

    const changeTab = (e) => {
        setTab(e)
        localStorage.setItem('f_coinList', e)
    }

    const change_OrderbookTab = (e) => {
        setTab1(e)
        localStorage.setItem('f_orderbook', e)
    }

    const [isBlinking, setIsBlinking] = useState(false);
    const [priceDirect, setPriceDirect] = useState(false)

    useEffect(() => {
        if (prePrice != currentPrice) {
            if (currentPrice > prePrice) setPriceDirect(true)
            else setPriceDirect(false)
            prePrice = currentPrice
        }
    }, [data])

    const handleButtonClick = () => {
        setIsBlinking(true);

        setTimeout(() => {
            setIsBlinking(false);
        }, 400);
    };
    let positionD = positionData.filter((item) => item.symbol == symbol)

    return (
        <div className={`futures ${darkname}`}>
            {
                windowtype
                    ?
                    <div className='futures-box'>
                        <ErrorBoundary>
                            <ByFsection1 tab={tab} changeTab={changeTab} USDTSymbolF={USDTSymbolF} symbol={symbol} />
                        </ErrorBoundary>
                        <div className={tab ? 'futures-section2' : 'futures-section2-check'}>
                            <div className='futures-section3'>
                                <div className='futures-section5'>
                                    <ErrorBoundary>
                                        <ByFsection6 tab={tab} tab1={tab1} changeTab={changeTab} change_OrderbookTab={change_OrderbookTab} data={data} symbol={symbol} exinfo={exinfo} max={max} maxPosition={maxPosition} positionData={positionData} currentPrice={currentPrice} firstmargin={firstmargin} marginbtn={marginbtn} setMarginbtn={setMarginbtn} marginbtn1={marginbtn1} setMarginbtn1={setMarginbtn1} markPrice={markPrice} nextTime={nextTime} fundingFee={fundingFee} positionDirect={positionDirect} orderReload={orderReload} indexPrice={indexPrice} fundingFeeAmount={fundingFeeAmount} />
                                    </ErrorBoundary>
                                    <ErrorBoundary>
                                        <ByFsection7 tab1={tab1} tab3={tab3} tab4={tab4} setTab4={setTab4} setTab3={setTab3} setLastBuy={setLastBuy} setLastSell={setLastSell} data={data} setCheckordertab={setCheckordertab} setSpeedorder={setSpeedorder} symbol={symbol} exinfo={exinfo} setMax={setMax} max={max} tradeVolume={tradeVolume} setCurrentPrice={setCurrentPrice} currentPrice={currentPrice} btnbtn={btnbtn} markPrice={markPrice} />
                                    </ErrorBoundary>
                                </div>
                                <ErrorBoundary>
                                    <ByFsection8 tab5={tab5} setTab5={setTab5} symbol={symbol} windowtypeM={windowtypeM} USDTSymbolF={USDTSymbolF} setPositionAmount={setPositionAmount} exinfo={exinfo} positionData={positionData} setTradeVolume={setTradeVolume} tradeVolume={tradeVolume} totalexinfo={totalexinfo} currentPrice={currentPrice} checkCurrentPosition={checkCurrentPosition} setOrderReload={setOrderReload} marginbtn={marginbtn} />
                                </ErrorBoundary>
                            </div>
                            <ErrorBoundary>
                                <ByFsection4 data={data} lastBuy={lastBuy} lastSell={lastSell} symbol={symbol} setCheckordertab={setCheckordertab} checkordertab={checkordertab} speedorder={speedorder} exinfo={exinfo} positionAmount={positionAmount} max={max} firstmargin={firstmargin} marginbtn={marginbtn} setMarginbtn={setMarginbtn} marginbtn1={marginbtn1} setMarginbtn1={setMarginbtn1} currentPrice={currentPrice} checkCurrentPosition={checkCurrentPosition} fwallet={fwallet} current={current} btnbtn={btnbtn} setBtnbtn={setBtnbtn} setMaxPosition={setMaxPosition} risk_balance={risk_balance} risk_position={risk_position} margin_type={margin_type} tmm={tmm} upnl={upnl} pBoth={pBoth} />
                            </ErrorBoundary>
                        </div>
                    </div>
                    :
                    <div className='futures-box'>
                        <ErrorBoundary>
                            <ByFsection1 tab={tab} changeTab={changeTab} USDTSymbolF={USDTSymbolF} symbol={symbol} />
                        </ErrorBoundary>
                        <div className='futures-section9'>
                            <ErrorBoundary>
                                <ByFsection6 tab={tab} tab1={tab1} changeTab={changeTab} change_OrderbookTab={change_OrderbookTab} data={data} symbol={symbol} exinfo={exinfo} max={max} maxPosition={maxPosition} positionData={positionData} currentPrice={currentPrice} firstmargin={firstmargin} marginbtn={marginbtn} setMarginbtn={setMarginbtn} marginbtn1={marginbtn1} setMarginbtn1={setMarginbtn1} markPrice={markPrice} nextTime={nextTime} fundingFee={fundingFee} positionDirect={positionDirect} orderReload={orderReload} indexPrice={indexPrice} fundingFeeAmount={fundingFeeAmount} />
                            </ErrorBoundary>
                            <ErrorBoundary>
                                <ByFsection8 tab5={tab5} setTab5={setTab5} symbol={symbol} windowtypeM={windowtypeM} USDTSymbolF={USDTSymbolF} setPositionAmount={setPositionAmount} exinfo={exinfo} positionData={positionData} setTradeVolume={setTradeVolume} tradeVolume={tradeVolume} totalexinfo={totalexinfo} currentPrice={currentPrice} checkCurrentPosition={checkCurrentPosition} setOrderReload={setOrderReload} marginbtn={marginbtn} />
                            </ErrorBoundary>
                            <div className='futures-section10'>
                                <ErrorBoundary>
                                    <ByFsection7 tab1={tab1} tab3={tab3} tab4={tab4} setTab4={setTab4} setTab3={setTab3} setLastBuy={setLastBuy} setLastSell={setLastSell} data={data} setCheckordertab={setCheckordertab} setSpeedorder={setSpeedorder} symbol={symbol} exinfo={exinfo} setMax={setMax} max={max} tradeVolume={tradeVolume} setCurrentPrice={setCurrentPrice} currentPrice={currentPrice} btnbtn={btnbtn} markPrice={markPrice} />
                                </ErrorBoundary>
                                <ErrorBoundary>
                                    <ByFsection4 data={data} lastBuy={lastBuy} lastSell={lastSell} symbol={symbol} setCheckordertab={setCheckordertab} checkordertab={checkordertab} speedorder={speedorder} exinfo={exinfo} positionAmount={positionAmount} max={max} firstmargin={firstmargin} marginbtn={marginbtn} setMarginbtn={setMarginbtn} marginbtn1={marginbtn1} setMarginbtn1={setMarginbtn1} currentPrice={currentPrice} checkCurrentPosition={checkCurrentPosition} fwallet={fwallet} current={current} btnbtn={btnbtn} setBtnbtn={setBtnbtn} setMaxPosition={setMaxPosition} risk_balance={risk_balance} risk_position={risk_position} margin_type={margin_type} tmm={tmm} upnl={upnl} pBoth={pBoth} />
                                </ErrorBoundary>
                            </div>

                            <div style={{ padding: "5px 5px 0" }}>
                                <div className='futures-section4-top-9 display-none-700-ok'>
                                    <div className='futures-section4-top-9-1 red'>{t('futures_152')}</div>
                                    <div className='futures-section4-top-9-2 green'>{fwallet.balance ? changeNum((risk_balance * 100 / (Number(fwallet.balance) + Number(fwallet.crossUnPnl))).toFixed(2)) : "0.00"}%</div>
                                    <div className='futures-section4-top-9-2'>%</div>
                                </div>
                                <div className='futures-section4-top-10 display-none-700-block'>
                                    <div className='futures-section4-top-10-1'>
                                        <div className='futures-section4-top-10-1-1'>{t('futures_0')}</div>
                                        <div className='futures-section4-top-10-1-2'>{fwallet.walletBalance ? changeNum(Number(fwallet.walletBalance).toFixed(3)) : "0.000"}</div>
                                        <div className='futures-section4-top-10-1-2'>USDT</div>
                                    </div>
                                    <div className='futures-section4-top-10-1'>
                                        <div className='futures-section4-top-10-1-1'>{t('futures_1')}</div>
                                        <div className='futures-section4-top-10-1-2'>{fwallet.walletBalance ? changeNum(Number(fwallet.walletBalance).toFixed(3)) : "0.000"}</div>
                                        <div className='futures-section4-top-10-1-2'>USDT</div>
                                    </div>
                                    <div className='futures-section4-top-10-1'>
                                        <div className='futures-section4-top-10-1-1'>{t('futures_2')}</div>
                                        <div className='futures-section4-top-10-1-2'>{fwallet.availableToWithdraw ? changeNum(Number(fwallet.availableToWithdraw).toFixed(3)) : "0.000"}</div>
                                        <div className='futures-section4-top-10-1-2'>USDT</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </div>
    )
}

export default ByFutures;