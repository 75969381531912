import '../../css/wallet.css'
import axios from 'axios';
import Page from '../../Page';
import { Link, useNavigate, useLocation } from "react-router-dom"
import { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotate, faCircleArrowRight, faEllipsis, faXmark, faMagnifyingGlass, faCircleArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from 'react-redux'
import { checkSelectType, checkLogin, addImg, changeWalletPage, check_Error } from '../../../store';
import Message from '../../message/message';
import Wallet_Message from '../../message/wallet_message';
import { SyncLoader } from "react-spinners";
import { useTranslation } from "react-i18next";
import { findSmallest, MakeNumber, changeNum, RoundDown, changeTime, padZero, makeSamllest, changeUnits, UTCTimestamp } from "../../common/function"
import Namu_Message from '../../message/namu_message';

function Binance_Gift({ darkname, BcurrentUSDT, setReload }) {
    const { t } = useTranslation();
    let dispatch = useDispatch();

    const [tab, setTab] = useState(false)
    const [tab1, setTab1] = useState(0)
    const [isToggled1, setIsToggled1] = useState(false);
    const [usernum, setUsernum] = useState("")
    const [amount, setAmount] = useState("")
    const [text, setText] = useState("")
    const [giftHistory_receive, setGiftHistory_receive] = useState([])
    const [giftHistory_send, setGiftHistory_send] = useState([])

    const [spinner, setSpinner] = useState(false)
    const [message, setMessage] = useState([])
    const [modal_message, setModal_Message] = useState([])
    const [modal_buyModal, setModal_BuyModal] = useState(false)

    const toggleButton1 = () => {
        // setIsToggled1(!isToggled1);
    };

    const onChangeUsernum = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^0-9]/g, "")
        setUsernum(onlyNumber)
    }
    const onChangeAmount = (e) => {
        const { value } = e.target
        let onlyNumber = value.replace(/[^0-9]/g, '');
        if (onlyNumber && onlyNumber.length > 0 && onlyNumber.charAt(0) == '0') {
            onlyNumber = onlyNumber.substring(1);
        }
        setAmount(onlyNumber)
    }
    const onChangeText = (e) => {
        let onlyNumber = e.target.value
        if (onlyNumber.length <= 20) {
            setText(onlyNumber)
        }
    }

    const [code, setCode] = useState(['', '', '', '', '', '']); // 초기값으로 6개의 빈 문자열을 가진 배열 생성

    const handleInputChange = (e, index) => {
        const value = e.target.value;
        if (/^\d*$/.test(value) && value.length <= 1) {
            const newCode = [...code];
            newCode[index] = value;
            setCode(newCode);

            // 다음 입력란으로 이동
            if (index < code.length - 1 && value) {
                const nextInput = e.target.parentNode.nextSibling.querySelector('input');
                if (nextInput) {
                    nextInput.focus();
                }
            }

            // 이전 입력란으로 이동
            if (index > 0 && !value) {
                const prevInput = e.target.parentNode.previousSibling.querySelector('input');
                if (prevInput) {
                    prevInput.focus();
                }
            }
        }
    };

    const CheckGoogleOTP = async () => {
        let objString = localStorage.getItem('token');
        if (objString) {
            let obj = JSON.parse(objString)
            let jwt = obj.value;

            setSpinner(true)

            await axios.get(process.env.REACT_APP_API + "/api/v1/account/user/verify/otp", {
                params: { otp: code.join('') },
                headers: { Authorization: `Bearer ${jwt}` }
            }).then((response) => {
                if (response?.data?.code > 0) {
                    onSendGift()
                }
                else {
                    setModal_Message({ type: "binance", code: response?.data?.code, msg: t('message_42') })
                    setModal_BuyModal(true)
                    setW_confirm(false)
                    setSpinner(false);
                }
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 84, "msg": error }))
            })
        }
    }

    const onSendGift = async () => {
        const objString = localStorage.getItem('token');
        if (objString) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            await axios.post(process.env.REACT_APP_API + `/api/v1/wallet/cm/send/gift`, {
                friendId: usernum,
                amount: amount,
                message: text,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                if (response?.data?.code >= 0) {
                    setTimeout(() => {
                        setReload(e => !e)
                        setSpinner(false);
                        setUsernum("")
                        setAmount("")
                        setText("")
                        setCode(['', '', '', '', '', ''])
                        setMessage({ type: 0, code: Number(response?.data?.code), msg: `${t('message_18')}` })
                    }, 2000);
                }
                else {
                    setTimeout(() => {
                        setSpinner(false);
                        setMessage({ type: 0, code: Number(response?.data?.code), msg: `${t('message_19')}` })
                    }, 1000);
                }
                setW_confirm(false)
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 85, "msg": error }))
            })
        }
    }

    useEffect(() => {
        GiftHistory()
    }, [])

    const GiftHistory = async () => {
        const objString = localStorage.getItem('token');
        if (objString) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            await axios.get(process.env.REACT_APP_API + `/api/v1/wallet/cm/get/gift`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                if (response?.data?.code >= 0) {
                    let tmp = response?.data?.data
                    if (tmp) {
                        setGiftHistory_receive(tmp.receive)
                        setGiftHistory_send(tmp.send)
                    }
                }
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 86, "msg": error }))
            })
        }
    }

    const [w_message, setW_message] = useState([])
    const [buyModal, setBuyModal] = useState(false);
    const [w_confirm, setW_confirm] = useState(false)

    useEffect(() => {
        if (w_confirm) {
            setBuyModal(false)
            setW_confirm(false)
            CheckGoogleOTP()
        }
    }, [w_confirm])

    const Check_Modal = () => {
        let mes = []
        // mes.push(`Exchange: Binance`)
        // mes.push(`Buddy ID: ${usernum}`)
        // mes.push(`Amount: ${amount}`)
        // mes.push(`Message for Your Buddy: ${text}`)

        setW_message({ code: 0, msg: mes })
    }

    const [page, setPage] = useState(1)
    const [page1, setPage1] = useState(1)
    let filter_giftHistory_receive = giftHistory_receive.slice((page - 1) * 10, (page) * 10)
    let filter_giftHistory_send = giftHistory_send.slice((page1 - 1) * 10, (page1) * 10)

    return (
        <div className='history-right'>
            <Namu_Message message={modal_message} buyModal={modal_buyModal} setBuyModal={setModal_BuyModal} res={0} />
            <Message message={message} />
            <Wallet_Message message={w_message} buyModal={buyModal} setBuyModal={setBuyModal} setW_confirm={setW_confirm} />
            {
                spinner
                    ?
                    <div className='dashboard-right-bottom1-modal'>
                        <SyncLoader color={"#5381e3"} />
                    </div>
                    :
                    ""
            }
            <div className='history-right-top'>
                <div className='history-right-top-1'>{t('header_39')}</div>
                <p className='history-right-top-2'>{t('header_40')}</p>
            </div>
            <div className='wallet-right-form'>
                <div className='wallet-right-form-1'>
                    <div className='wallet-right-form-1-box'>
                        <div className='wallet-right-form-1-box-1'>
                            <p>{t('wallet_10')}</p>
                            <div className={`wallet-right-form-1-box-1-label ${isToggled1 ? 'toggled' : ''} ${darkname}`} onClick={toggleButton1}>
                                <div className="wallet-right-form-1-box-1-btn slider"></div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    isToggled1
                        ?
                        <div className='wallet-right-form-3'>
                            <div className='wallet-right-form-3-1'>{t('wallet_10')}</div>
                            <div className='wallet-right-form-3-2'>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className='wallet-right-form-3-2-2'>{t('wallet_167')}</p>
                                    <p className='wallet-right-form-3-2-3'>
                                        {t('wallet_168')}
                                    </p>
                                    <div className='wallet-right-form-3-2-4'>
                                        <div className='wallet-right-form-3-2-5'>
                                            {t('wallet_25')}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className='wallet-right-form-3-2-2'>{t('wallet_169')}</p>
                                    <p className='wallet-right-form-3-2-3'>
                                        {t('wallet_170')}
                                    </p>
                                    <div className='wallet-right-form-3-2-4'>
                                        <div className='wallet-right-form-3-2-5'>
                                            {t('wallet_25')}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className='wallet-right-form-3-2-2'>{t('wallet_171')}</p>
                                    <p className='wallet-right-form-3-2-3'>
                                        {t('wallet_172')}
                                    </p>
                                    <div className='wallet-right-form-3-2-4'>
                                        <div className='wallet-right-form-3-2-5'>
                                            {t('wallet_25')}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className='wallet-right-form-3-2-2'>{t('wallet_173')}</p>
                                    <p className='wallet-right-form-3-2-3'>
                                        {t('wallet_174')}
                                    </p>
                                    <div className='wallet-right-form-3-2-4'>
                                        <div className='wallet-right-form-3-2-5'>
                                            {t('wallet_25')}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        ""
                }
                <div className={`wallet-right-form-4 ${darkname}`}>
                    <div className='wallet-right-form-4-1'>{t('wallet_175')}</div>
                    <div className='wallet-right-form-4-2'>
                        <div className={`wallet-right-form-4-3 ${darkname}`} onClick={() => setTab(e => !e)}>
                            {t('exchange_0')}
                        </div>
                        {
                            tab
                                ?
                                <>
                                    <div className="header-modal" onClick={() => setTab(false)}></div>
                                    <div className={`wallet-right-form-4-3-box ${darkname}`}>
                                        <div className={`wallet-right-form-4-3-box-1 ${darkname}`} onClick={() => { dispatch(checkSelectType(1)); dispatch(changeWalletPage(1)); setTab(false) }}>{t('exchange_0')}</div>
                                        <div className={`wallet-right-form-4-3-box-1 ${darkname}`} onClick={() => { dispatch(checkSelectType(2)); dispatch(changeWalletPage(2)); setTab(false) }}>{t('exchange_2')}</div>
                                    </div>
                                </>
                                :
                                ""
                        }
                    </div>
                </div>
                <div className={`wallet-right-form-4 ${darkname}`}>
                    <div className='wallet-right-form-4-1'>{t('wallet_176')}</div>
                    <div className='wallet-right-form-4-2'>
                        <div className={`wallet-right-form-4-3 ${darkname}`}>
                            <input type='number' className='wallet-right-form-4-3-input' value={usernum} onChange={onChangeUsernum} maxLength={6} placeholder='0' />
                        </div>
                    </div>
                </div>
                <div className={`wallet-right-form-4 ${darkname}`}>
                    <div className='wallet-right-form-4-1'>{t('wallet_177')}</div>
                    <div className='wallet-right-form-4-2'>
                        <div className={`wallet-right-form-4-3 ${darkname}`} style={{ cursor: "auto" }}>
                            {BcurrentUSDT.availableBalance ? changeNum(RoundDown(BcurrentUSDT.availableBalance, 6)) : "0.00000"}
                        </div>
                    </div>
                </div>
                <div className={`wallet-right-form-4 ${darkname}`}>
                    <div className='wallet-right-form-4-1'>{t('wallet_178')}</div>
                    <div className='wallet-right-form-4-2'>
                        <div className={`wallet-right-form-4-3 ${darkname}`}>
                            <input type='number' className='wallet-right-form-4-3-input' value={amount} onChange={onChangeAmount} placeholder='0' />
                        </div>
                    </div>
                </div>
                <div className={`wallet-right-form-4 ${darkname}`}>
                    <div className='wallet-right-form-4-1'>{t('wallet_179')}</div>
                    <div className={`wallet-right-form-4-9 ${darkname}`}>
                        <textarea className='wallet-right-form-4-9-1' cols="30" rows="10" maxLength={20} value={text} onChange={onChangeText}></textarea>
                        <div className='wallet-right-form-4-9-2'>(<span>{text && text.length}</span> / 20)</div>
                    </div>
                </div>
                <div className={`wallet-right-form-4 ${darkname}`}>
                    <div className='wallet-right-form-4-1'>{t('wallet_180')}</div>
                    <div className='wallet-right-form-4-2' style={{ opacity: ".5" }}>
                        {t('wallet_181')}
                    </div>
                    <div className='wallet-right-form-8'>
                        {code.map((digit, index) => (
                            <div className={`wallet-right-form-8-1 ${darkname}`}>
                                <input
                                    key={index}
                                    type="number"
                                    maxLength="1"
                                    value={digit}
                                    onChange={(e) => handleInputChange(e, index)}
                                    className={`wallet-right-form-8-2 ${darkname}`}
                                />
                            </div>
                        ))}
                    </div>
                </div>
                <div className='wallet-right-form-9'>
                    <div className={`wallet-right-form-9-1 ${darkname}`} onClick={() => Check_Modal()}>{t('header_39')}</div>
                </div>
                <div className='wallet-right-form-7'>
                    <div className='wallet-right-form-7-1'>{t('wallet_182')}</div>
                    <div className='wallet-right-form-7-4'>
                        <div className={tab1 === 0 ? `wallet-right-form-7-4-1 current ${darkname}` : `wallet-right-form-7-4-1 ${darkname}`} onClick={() => setTab1(0)}>{t('wallet_183')}</div>
                        <div className={tab1 === 1 ? `wallet-right-form-7-4-1 current ${darkname}` : `wallet-right-form-7-4-1 ${darkname}`} onClick={() => setTab1(1)}>{t('wallet_184')}</div>
                    </div>
                    <div className='wallet-right-form-7-3'>
                        <div className={`wallet-right-form-7-3-top ${darkname}`}>
                            <div className='wallet-right-form-7-3-top-6'>{t('wallet_185')}</div>
                            <div className='wallet-right-form-7-3-top-6'>{t('wallet_186')}</div>
                            <div className='wallet-right-form-7-3-top-6'>{t('wallet_187')}</div>
                            <div className='wallet-right-form-7-3-top-6'>{t('wallet_188')}</div>
                        </div>
                        <div className='wallet-right-form-7-3-bottom'>
                            {
                                tab1 === 0
                                    ?
                                    <>
                                        {
                                            filter_giftHistory_receive.map(function (a, i) {
                                                const date = UTCTimestamp(new Date(a.time));
                                                const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())}:${padZero(date.getSeconds())}`;
                                                return (
                                                    <div className={`wallet-right-form-7-3-bottom-1 ${darkname}`}>
                                                        <div className='wallet-right-form-7-3-bottom-5'>{formattedDate}</div>
                                                        <div className='wallet-right-form-7-3-bottom-5'>{a.from_id}</div>
                                                        <div className='wallet-right-form-7-3-bottom-5'>{a.amount}</div>
                                                        <div className='wallet-right-form-7-3-bottom-5'>{a.message}</div>
                                                    </div>
                                                )
                                            })
                                        }
                                        <Page initialData={giftHistory_receive} page={page} setPage={setPage} maxFirstPage={10} />
                                    </>
                                    :
                                    <>
                                        {
                                            filter_giftHistory_send.map(function (a, i) {
                                                const date = UTCTimestamp(new Date(a.time));
                                                const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())}:${padZero(date.getSeconds())}`;
                                                return (
                                                    <div className={`wallet-right-form-7-3-bottom-1 ${darkname}`}>
                                                        <div className='wallet-right-form-7-3-bottom-5'>{formattedDate}</div>
                                                        <div className='wallet-right-form-7-3-bottom-5'>{a.to_id}</div>
                                                        <div className='wallet-right-form-7-3-bottom-5'>{a.amount}</div>
                                                        <div className='wallet-right-form-7-3-bottom-5'>{a.message}</div>
                                                    </div>
                                                )
                                            })
                                        }
                                        <Page initialData={giftHistory_send} page={page1} setPage={setPage1} maxFirstPage={10} />
                                    </>

                            }
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Binance_Gift;