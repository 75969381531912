import './nav.css'
import { useSelector } from 'react-redux'
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

let lang_arr = ""
function Footer() {
    const { t } = useTranslation();
    let dark = useSelector((state) => { return state.Darkmode.value });
    let Languages = useSelector((state) => { return state.Languages.value })
    const darkname = dark ? 'dark' : "";

    let location = useLocation();

    const [loca, setLoca] = useState(false)
    const [exch, setExch] = useState(false)

    useEffect(() => {
        if (location.pathname == '/') setLoca(true)
        else setLoca(false)
        if (location.pathname.includes("spot") || location.pathname.includes("futures")) setExch(true)
        else setExch(false)
    }, [location])

    if (Languages) {
        lang_arr = Languages.toLowerCase()
        if (lang_arr == "vi-vn") {
            lang_arr = "en-us"
        }
    }

    return (
        <div className={`footer ${darkname} ${exch ? "none" : ""}`}>
            <div className='footer-box'>
                {
                    loca
                        ?
                        <div className='footer-box-wrap'>
                            <div className='footer-box-left'>
                                <img src={dark ? "/img/namu_dark.png" : "/img/namu_light.png"}></img>
                            </div>
                            <div className='footer-box-right'>
                                <div className='footer-box-right-menu'>
                                    <div className='footer-box-right-box'>
                                        <div className='footer-box-right-txt-1'>{t('footer_2')}</div>
                                        <div className={`footer-box-right-txt-2  ${darkname}`} onClick={() => window.open(`https://namubit.zendesk.com/hc/${lang_arr}/sections/27196026855449`)}>{t('footer_3')}</div>
                                        <div className={`footer-box-right-txt-2  ${darkname}`} onClick={() => window.open(`https://namubit.zendesk.com/hc/${lang_arr}/articles/28798542131993`)}>{t('footer_4')}</div>
                                        <div className={`footer-box-right-txt-2  ${darkname}`} onClick={() => window.open(`https://namubit.zendesk.com/hc/${lang_arr}/articles/28798558893465`)}>{t('footer_5')}</div>
                                        {/* <div className={`footer-box-right-txt-2  ${darkname}`} onClick={() => window.open('https://namubit.zendesk.com/hc/en-us/sections/27201818390041')}>{t('footer_6')}</div> */}
                                        <div className={`footer-box-right-txt-2  ${darkname}`} onClick={() => window.open(`https://namubit.zendesk.com/hc/${lang_arr}/categories/27196020984857`)}>{t('footer_7')}</div>
                                    </div>
                                    <div className='footer-box-right-box'>
                                        <div className='footer-box-right-txt-1'>{t('footer_8')}</div>
                                        <div className={`footer-box-right-txt-2  ${darkname}`} onClick={() => window.open(`https://www.namubit.com/futures/binance/BTCUSDT`)}>{t('footer_9')}</div>
                                        <div className={`footer-box-right-txt-2  ${darkname}`} onClick={() => window.open(`https://www.namubit.com/spot/binance/BTCUSDT`)}>{t('footer_10')}</div>
                                    </div>
                                    <div className='footer-box-right-box'>
                                        <div className='footer-box-right-txt-1'>{t('footer_11')}</div>
                                        <div className={`footer-box-right-txt-2  ${darkname}`} onClick={() => window.open(`https://namubit.zendesk.com/hc/${lang_arr}`)}>{t('footer_12')}</div>
                                        <div className={`footer-box-right-txt-2  ${darkname}`} onClick={() => window.open(`https://www.youtube.com/@NAMUBIT-Official`)}>{t('footer_13')}</div>
                                    </div>
                                    <div className='footer-box-right-box'>
                                        <div className='footer-box-right-txt-1'>{t('footer_14')}</div>
                                        <div className={`footer-box-right-txt-2  ${darkname}`}>
                                            <img src='/img/telegram.png' style={{ width: "35px" }} onClick={() => window.open('https://t.me/namubit')} />
                                            <img src='/img/youtube.png' style={{ width: "30px" }} onClick={() => window.open('https://www.youtube.com/@NAMUBIT-Official')} />
                                            <img src='/img/twitter.png' style={{ width: "29px" }} onClick={() => window.open('https://x.com/NamubitOfficial')} />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        ""
                }
                <div className='footer-box-bottom'>{t('footer_15')}</div>
            </div>
        </div>
    )
}

export default Footer;