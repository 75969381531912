import "../css/wallet.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkSelectType, checkLogin, changeWalletPage, changeOKX_Message, check_Error } from '../../store';
import AOS from "aos";
import { useTranslation } from "react-i18next";

import OKX_Overview from "./wallet/okx_overview";
import OKX_Deposit from "./wallet/okx_deposit";
import OKX_Withdrawal from "./wallet/okx_withdraw";
import OKX_Exchange from "./wallet/okx_exchange";
import OKX_Transfer from "./wallet/okx_transfer";
import OKX_Gift from "./wallet/okx_gift";
import OKX_QuickWithdrawal from "./wallet/okx_quickWithdrawal";

import Common_Deposit from "../common/C_wallet/c_deposit";
import Common_Withdrawal from "../common/C_wallet/c_withdraw";
import Common_Exchange from "../common/C_wallet/c_exchange";
import Common_Transfer from "../common/C_wallet/c_transfer";
import Common_Gift from "../common/C_wallet/c_gift";

import { C_Wallet_Sidebar } from '../common/C_wallet/W_wallet';

function Wallet() {
    const { t } = useTranslation();
    let dark = useSelector((state) => { return state.Darkmode.value; });
    let OKXS = useSelector((state) => { return state.OKX_coinS; });
    let check_KYC = useSelector((state) => { return state.check_KYC.value; });
    let Wallet_Page = useSelector((state) => { return state.Wallet_Page.value; });
    let OKX_wallet = useSelector((state) => { return state.OKX_wallet; });
    let OKX_wallet_usdt = useSelector((state) => { return state.OKX_wallet_usdt; });
    let API_Address = useSelector((state) => { return state.API_Address.value })
    let SelectType = useSelector((state) => { return state.SelectType.value })
    let OKX_Mode = useSelector((state) => { return state.OKX_Mode.value })

    const darkname = dark ? "dark" : "";

    const location = useLocation();
    let dispatch = useDispatch();
    const navigate = useNavigate();

    const [tab, setTab] = useState(location.state === null ? 0 : location.state.num);
    const [balance, setBalance] = useState([]);
    const [currentUSDT, setCurrentUSDT] = useState("");
    const [walletData, setWalletData] = useState([]);
    const [reload, setReload] = useState(false);
    const [fastCoin, setFastCoin] = useState("");
    const [check_etcKyc, setCheck_etcKyc] = useState(false);

    useEffect(() => {
        const objString = localStorage.getItem("token");
        if (objString) {
            const obj = JSON.parse(objString);
            if (Date.now() > obj.expire) {
                localStorage.removeItem("token");
                dispatch(checkLogin(false));
                navigate("/");
            }
            else {
                dispatch(checkLogin(true));
            }
        }
        else {
            dispatch(checkLogin(false));
            navigate("/");
        }
    }, []);

    useEffect(() => {
        if (OKX_wallet) {
            setBalance(OKX_wallet);
            setCurrentUSDT(OKX_wallet_usdt);
        }
    }, [OKX_wallet]);

    useEffect(() => {
        let data = [];
        if (OKXS && balance) {
            for (let i = 0; i < OKXS.length; i++) {
                let num = 0;
                for (let j = 0; j < balance.length; j++) {
                    if (OKXS[i].ccy == balance[j].ccy) {
                        num = 1;
                        data.push({
                            symbol: OKXS[i].ccy, name: OKXS[i].name, fullName: OKXS[i].fullName,
                            lastPrice: OKXS[i].lastPrice, volume: OKXS[i].volume, availBal: balance[j].availBal, frozenBal: balance[j].frozenBal,
                        });
                    }
                }
                if (num == 0)
                    data.push({
                        symbol: OKXS[i].ccy, name: OKXS[i].name, fullName: OKXS[i].fullName,
                        lastPrice: OKXS[i].lastPrice, volume: OKXS[i].volume, availBal: 0, frozenBal: 0,
                    });
            }

            for (let j = 0; j < balance.length; j++) {
                if ("USDT" == balance[j].ccy) {
                    data.push({
                        symbol: "USDT", name: "USDT", fullName: "USDT",
                        lastPrice: 1, volume: balance[j].eq, availBal: balance[j].availBal, frozenBal: balance[j].frozenBal,
                    });
                }
            }

            const isSymbolIncluded = data.some((item) => item.symbol === "USDT");
            if (!isSymbolIncluded) {
                data.push({
                    symbol: "USDT", name: "USDT", fullName: "USDT",
                    lastPrice: 1, volume: 0, availBal: 0, frozenBal: 0,
                });
            }

            data.sort((a, b) => Number(b.volume) * Number(b.lastPrice) - Number(a.volume) * Number(a.lastPrice));
            setWalletData(data);
        }
    }, [balance, OKXS]);

    useEffect(() => {
        if (location.state === null) setTab(0);
        else {
            if ((location.state.num === 5 && check_KYC && check_KYC.bnKyc === 0) || (location.state.num === 5 && check_KYC && check_KYC.etcKyc === 0)) {
                setCheck_etcKyc(true);
                setTab(location.state.num);
            }
            else {
                setCheck_etcKyc(false);
                setTab(location.state.num);
            }
        }
    }, [location, check_KYC]);

    useEffect(() => {
        AOS.init();
    }, []);

    useEffect(() => {
        document.title = "NAMUBIT│One-Stop Trading Platform - Crypto Trading Universe";
    }, [API_Address]);

    return (
        <div
            className={`wallet ${darkname}`}>
            <div
                className="history-container"
                style={{ filter: (check_KYC && check_KYC.etcKyc === 0 && Wallet_Page === 0 && tab != 0) || check_etcKyc ? "blur(5px)" : "", }} >
                <div className="history-wrap">
                    <C_Wallet_Sidebar tab={tab} setTab={setTab} />
                    {
                        tab === 0
                            ?
                            <OKX_Overview darkname={darkname} walletData={walletData} setFastCoin={setFastCoin} />
                            :
                            tab === 1
                                ?
                                Wallet_Page === -1
                                    ?
                                    <Common_Deposit darkname={darkname} />
                                    :
                                    <OKX_Deposit darkname={darkname} walletData={walletData} fastCoin={fastCoin} />

                                :
                                tab === 2
                                    ?
                                    Wallet_Page === -1
                                        ?
                                        <Common_Withdrawal darkname={darkname} />
                                        :
                                        <OKX_Withdrawal darkname={darkname} walletData={walletData} reload={reload} setReload={setReload} fastCoin={fastCoin} />
                                    :
                                    tab === 3
                                        ?
                                        Wallet_Page === -1
                                            ?
                                            <Common_Exchange darkname={darkname} />
                                            :
                                            <OKX_Exchange walletData={walletData} currentUSDT={currentUSDT} setReload={setReload} fastCoin={fastCoin} />
                                        :
                                        tab === 4
                                            ?
                                            Wallet_Page === -1
                                                ?
                                                <Common_Transfer darkname={darkname} />
                                                :
                                                <OKX_Transfer darkname={darkname} />
                                            :
                                            tab === 5
                                                ?
                                                check_etcKyc
                                                    ?
                                                    <OKX_Overview darkname={darkname} walletData={walletData} currentUSDT={currentUSDT} setFastCoin={setFastCoin} />
                                                    :
                                                    <OKX_QuickWithdrawal reload={reload} setReload={setReload} />
                                                :
                                                Wallet_Page === -1
                                                    ?
                                                    <Common_Gift darkname={darkname} />
                                                    :
                                                    <OKX_Gift darkname={darkname} />
                    }
                </div>
            </div>
            {(check_KYC && check_KYC.etcKyc === 0 && Wallet_Page === 0 && tab != 0) || check_etcKyc
                ?
                <div className="spot-kyc-modal">
                    <div className={`spot-kyc-modal-1 ${darkname}`}>
                        <div className="spot-kyc-modal-text">{t("message_4")}<br />{t("message_5")}</div>
                        <Link to={`/dashboard`} state={{ num: 1 }} onClick={() => { setCheck_etcKyc(false); dispatch(changeWalletPage(-1)); }}>
                            <div className="spot-kyc-modal-btn">{t("message_6")}</div>
                        </Link>
                    </div>
                </div>
                :
                ""
            }
        </div>
    );
}

export default Wallet;
