import './css/wallet.css'
import axios from 'axios';
import { Link, useNavigate, useLocation } from "react-router-dom"
import { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotate, faCircleArrowRight, faEllipsis } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from 'react-redux'
import { checkLogin, check_Error } from '../store';
import { SyncLoader } from "react-spinners";
import { useTranslation } from "react-i18next";

function ForgotPassword_A() {
    const { t } = useTranslation();
    let dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    let dark = useSelector((state) => { return state.Darkmode.value });
    let API_Address = useSelector((state) => { return state.API_Address.value })
    const darkname = dark ? 'dark' : "";

    const [page, setPage] = useState(location.state === null ? 0 : location.state.page);
    const [id, setId] = useState(location.state === null ? "" : location.state.id);
    const [userId, setUserId] = useState(location.state === null ? 0 : location.state.userId);
    const [type, setType] = useState(location.state === null ? "" : location.state.type);
    const [otp, setOtp] = useState(location.state === null ? false : location.state.otp);
    const [loginCheck, setLoginCheck] = useState(location.state === null ? false : location.state.loginCheck)
    const [certifyNum, setCertifyNum] = useState("")
    const [spinner, setSpinner] = useState(false)

    const onCertifyNum = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^0-9]/g, "")
        setCertifyNum(onlyNumber)
    }

    useEffect(() => {
        if (location.state === null) {
            navigate('/')
        }
        else {
            setPage(location.state.page)
            setId(location.state.id)
            setUserId(location.state.userId)
            setType(location.state.type)
            setOtp(location.state.otp)
            setLoginCheck(location.state.loginCheck)
        }
        onSendCertify()
    }, [location])

    const onSendCertify = async () => {
        setCertifyNum("")
        if (page === 0) {
            await axios.get(process.env.REACT_APP_API + "/api/v1/account/user/regist/certify", {
                params: {
                    id: userId,
                    type: type,
                    form: "regist"
                }
            }).then((response) => {
                if (response.data.code > 0) {
                }
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 20, "msg": error }))
            })
        }
        else {
            if (!otp) {
                await axios.get(process.env.REACT_APP_API + "/api/v1/account/user/get/certify", {
                    params: {
                        id: userId,
                        type: type,
                        form: "login"
                    }
                }).then((response) => {
                    if (response.data.code > 0) {
                    }
                }).catch((error) => {
                    dispatch(check_Error({ "bol": true, "no": 21, "msg": error }))
                })
            }
        }
    }

    const onSubmit = async () => {
        setSpinner(true)
        if (page === 0) {
            await axios.get(process.env.REACT_APP_API + "/api/v1/account/user/regist/check/certify", {
                params: {
                    id: userId,
                    number: certifyNum,
                }
            }).then((response) => {
                if (response.data.code > 0) {
                    alert(`${t('message_27')}`)
                    navigate('/login')
                }
                else {
                    alert(response.data.msg)
                }
                setSpinner(false)
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 22, "msg": error }))
            })
        }
        else {
            if (otp && userId != 90) {
                await axios.get(process.env.REACT_APP_API + "/api/v1/account/user/verify/otp", {
                    params: {
                        otp: certifyNum,
                        id: userId,
                        login: true
                    }
                }).then((response) => {
                    if (response.data.code > 0) {
                        const obj = {
                            value: response.data.data.jwt,
                            expire: loginCheck ? Date.now() + 1000 * 60 * 60 * 24 * 5 : Date.now() + 1000 * 60 * 60 * 24,
                            type: "binance",
                            login: type
                        }
                        const objString = JSON.stringify(obj);
                        localStorage.setItem('token', objString)
                        dispatch(checkLogin(true));
                        alert(`${t('message_27')}`)
                        navigate('/')
                    }
                    else {
                        alert(response.data.msg)
                    }
                    setSpinner(false)
                }).catch((error) => {
                    dispatch(check_Error({ "bol": true, "no": 23, "msg": error }))
                })
            }
            else {
                await axios.get(process.env.REACT_APP_API + "/api/v1/account/user/check/certify", {
                    params: {
                        id: userId,
                        number: certifyNum,
                        login: true
                    }
                }).then((response) => {
                    if (response.data.code > 0) {
                        const obj = {
                            value: response.data.data.jwt,
                            expire: loginCheck ? Date.now() + 1000 * 60 * 60 * 24 * 5 : Date.now() + 1000 * 60 * 60 * 24,
                            type: "binance",
                            login: type
                        }
                        const objString = JSON.stringify(obj);
                        localStorage.setItem('token', objString)
                        dispatch(checkLogin(true));
                        alert(`${t('message_27')}`)
                        navigate('/')
                        setSpinner(false)
                    }
                    else {
                        alert(response.data.msg)
                    }
                    setSpinner(false)
                }).catch((error) => {
                    dispatch(check_Error({ "bol": true, "no": 24, "msg": error }))
                })
            }
        }
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            onSubmit()
        }
    };

    const inputRef = useRef(null);
    useEffect(() => {
        document.title = "NAMUBIT│One-Stop Trading Platform - Crypto Trading Universe";
        inputRef.current.focus();
    }, [API_Address]);

    return (
        <div className={`otp ${darkname}`}>
            <div className={`otp-1 ${darkname}`}>
                <div className='otp-2'>
                    {
                        spinner
                            ?
                            <div className='dashboard-right-bottom1-modal'>
                                <SyncLoader color={"#5381e3"} />
                            </div>
                            :
                            ""
                    }
                    <div className='otp-h4'>{otp ? `${t('otp_0')}` : type === "email" ? `${t('otp_1')}` : `${t('otp_2')}`}</div>
                    <div className='otp-2-wrap'>
                        <p className='otp-2-wrap-p'>
                            <span style={{ opacity: ".5" }}>{otp ? `${t('otp_3')}` : type === "email" ? `${t('otp_4')}` : `${t('otp_5')}`}</span><br />

                            {
                                otp
                                    ?
                                    ""
                                    :
                                    <span>{id}</span>
                            }
                        </p>
                        <br />
                        {
                            otp
                                ?
                                <div className={`otp-2-3 ${darkname}`}>
                                    {
                                        spinner
                                            ?
                                            <input type='text' placeholder={`${t('otp_6')}`} maxLength={6} value={certifyNum} onKeyDown={handleKeyPress} onChange={onCertifyNum} disabled />
                                            :
                                            <input type='text' placeholder={`${t('otp_6')}`} maxLength={6} value={certifyNum} onKeyDown={handleKeyPress} onChange={onCertifyNum} ref={inputRef} />
                                    }
                                </div>
                                :
                                <>
                                    <div className={`otp-2-3 ${darkname}`}>
                                        {
                                            spinner
                                                ?
                                                <input type='text' placeholder={`${t('otp_7')}`} maxLength={6} value={certifyNum} onKeyDown={handleKeyPress} onChange={onCertifyNum} disabled />
                                                :
                                                <input type='text' placeholder={`${t('otp_7')}`} maxLength={6} value={certifyNum} onKeyDown={handleKeyPress} onChange={onCertifyNum} ref={inputRef} />
                                        }
                                    </div>
                                    <div className='otp-2-4'>
                                        <div className='otp-2-4-1'>{t('otp_8')}</div>
                                        <p>|</p>
                                        <div className='otp-2-4-2' onClick={() => onSendCertify()}>{t('otp_9')}</div>
                                    </div>
                                </>
                        }
                    </div>
                    <div className='otp-2-5'>
                        <div className='otp-2-5-btn' onClick={() => onSubmit()}>{t('otp_10')}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword_A;