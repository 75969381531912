import '../../css/wallet.css'
import axios from 'axios';
import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { check_Error } from '../../../store';

import { C_Deposit } from '../../common/C_wallet/W_wallet';

function Bybit_Deposit({ walletData, fastCoin }) {
    const CryptoJS = require('crypto-js');

    let dispatch = useDispatch();
    let By_apiKey = useSelector((state) => { return state.By_apiKey })
    const [message, setMessage] = useState([])
    const [buyModal, setBuyModal] = useState(false);

    const [search, setSearch] = useState(fastCoin)
    const [selectSearch, setSelectSearch] = useState(fastCoin)
    const [BselectCoin, SetBSelectCoin] = useState([])
    const [selectedOption, setSelectedOption] = useState("");
    const [currency, setCurrency] = useState([])
    const [dhistory, setDhistory] = useState([])
    const [BselectCoinList, SetBSelectCoinList] = useState([])
    const [currencyList, setCurrencyList] = useState([])
    const [dhistoryList, setDhistoryList] = useState([])

    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const onChangeSearch = (e) => {
        setSearch(e.target.value)
    }

    let BS_filterSymbol = []
    if (walletData) {
        BS_filterSymbol = walletData.filter((p) => {
            return (p.symbol.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
        })
    }

    useEffect(() => {
        if (By_apiKey?.S_api?.length > 0) {
            DepositCurrency()
        }
    }, [selectSearch, By_apiKey])

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            B_DepositLink(token)
        }
    }, [selectedOption])

    useEffect(() => {
        DepositHistory(0)
    }, [])

    const B_DepositLink = async (token) => {
        await axios.get(process.env.REACT_APP_API + `/api/v1` + '/wallet/bybit/deposit/address', {
            params: {
                coin: selectSearch,
                chainType: selectedOption
            },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            let tmp = response.data
            if (tmp.code === 1000) {
                if (selectedOption.length > 0) {
                    SetBSelectCoin(tmp?.data?.result?.chains)
                }
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    const DepositCurrency = async () => {
        const by_timestamp = localStorage.getItem('bybit_timestamp');
        if (by_timestamp) {
            const paramStr = `${by_timestamp}${By_apiKey.S_api}${10000}coin=${selectSearch}`
            await axios.get(`https://api.bybit.com/v5/asset/coin/query-info?coin=${selectSearch}`, {
                headers: {
                    "X-BAPI-SIGN": CryptoJS.HmacSHA256(paramStr, By_apiKey.S_sec).toString(CryptoJS.enc.Hex),
                    "X-BAPI-API-KEY": By_apiKey.S_api,
                    "X-BAPI-TIMESTAMP": by_timestamp,
                    "X-BAPI-RECV-WINDOW": 10000
                }
            }).then((response) => {
                let tmp = response.data
                if (tmp.retCode == 10004 || tmp.retCode == 10001) {
                    DepositCurrency()
                }
                else if (tmp.retCode == 0) {
                    if (selectSearch.length > 0) {
                        setCurrency(tmp?.result?.rows[0].chains ?? [])
                    }
                }
            }).catch((error) => {
                console.log(error)
            })
        }
        else {
            setTimeout(() => {
                DepositCurrency()
            }, 1000)
        }
    }

    const DepositHistory = async (n) => {
        const objString = localStorage.getItem('token');
        if (objString) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            await axios.get(process.env.REACT_APP_API + `/api/v1` + '/wallet/bybit/deposit/history', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                let tmp = response.data
                if (tmp?.code === 1000) {
                    if (n === 1) setMessage({ type: "binance", code: tmp?.code, msg: "Refresh successful" })
                    setDhistory(tmp.data)
                }
                else {
                    setMessage({ type: "binance", code: -1, msg: "Refresh Failed" })
                }
                if (n == 1) setBuyModal(true)
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 82, "msg": error }))
            })
        }
    }

    useEffect(() => {
        if (BselectCoin) {
            SetBSelectCoinList({
                address: BselectCoin.addressDeposit,
                tag: BselectCoin.tagDeposit
            })
        }
    }, [BselectCoin])

    useEffect(() => {
        if (currency) {
            let arr = []
            for (let i = 0; i < currency.length; i++) {
                arr.push({
                    chain: currency[i].chain,
                    chainType: currency[i].chainType,
                    confirmation: currency[i].confirmation
                })
            }
            setCurrencyList(arr)
        }
    }, [currency])

    useEffect(() => {
        if (dhistory) {
            let arr = []
            for (let i = 0; i < dhistory.length; i++) {
                arr.push({
                    time: dhistory[i].time,
                    status: dhistory[i].status,
                    txId: dhistory[i].txId,
                    amount: dhistory[i].amount,
                    coin: dhistory[i].coin,
                    chain: dhistory[i].chain
                })
            }
            setDhistoryList(arr)
        }
    }, [dhistory])

    return (
        <C_Deposit message={message} setMessage={setMessage} buyModal={buyModal} setBuyModal={setBuyModal} search={search} setSearch={setSearch} onChangeSearch={onChangeSearch} setSelectSearch={setSelectSearch} BselectCoin={BselectCoinList} selectedOption={selectedOption} handleSelectChange={handleSelectChange} currency={currencyList} dhistory={dhistoryList} BS_filterSymbol={BS_filterSymbol} DepositHistory={DepositHistory} />
    )
}

export default Bybit_Deposit;