import { useSelector } from 'react-redux';
import '../css/modal.css'
import { useEffect, useState } from 'react';
import '@fortawesome/fontawesome-free/css/all.css';

function Modal({ message }) {
    let dark = useSelector((state) => { return state.Darkmode.value });
    const darkname = dark ? 'dark' : "";
    const [buyModal, setBuyModal] = useState(false);

    useEffect(() => {
        if (message.type) {
            setBuyModal(true)
            setTimeout(() => {
                setBuyModal(false);
            }, 2500);
        }
    }, [message]);

    return (
        <>
            {
                message.type == "okx"
                    ?
                    <div className={`buy-modal ${buyModal ? 'show' : 'hide'} ${Number(message.code) === 0 ? "modal-green" : "modal-red"} ${darkname}`}>
                        <div>{message.msg}</div>
                    </div>
                    :
                    <div className={`buy-modal ${buyModal ? 'show' : 'hide'} ${Number(message.code) >= 0 ? "modal-green" : "modal-red"} ${darkname}`} style={{ zIndex: Number(message.code) === -123 ? "100000" : "" }}>
                        <div>{message.msg}</div>
                    </div>
            }
        </>
    )
}

export default Modal;