import '../css/history.css'
import axios from 'axios';
import { Link, useNavigate, useLocation } from "react-router-dom"
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { changeOKX_Message, checkLogin, checkSelectType, check_Error } from '../../store';
import { useTranslation } from "react-i18next";
import { changeTime, padZero, UTCTimestamp } from "../common/function"

import { C_Detail, C_FuturesFee, C_History_Sidebar, C_Summary } from '../common/C_history/W_history';

function BHistory() {
    const { t } = useTranslation();
    let dark = useSelector((state) => { return state.Darkmode.value });
    let SelectType = useSelector((state) => { return state.SelectType.value })
    let API_Address = useSelector((state) => { return state.API_Address.value })
    let OKX_Mode = useSelector((state) => { return state.OKX_Mode.value })
    const darkname = dark ? 'dark' : "";
    const location = useLocation();
    let dispatch = useDispatch();
    const navigate = useNavigate();

    const [tab, setTab] = useState(location.state === null ? 0 : location.state.num)
    const [personal_fee, setPersonal_fee] = useState(0.25)

    useEffect(() => {
        if (location.state === null) setTab(0)
        else setTab(location.state.num)
    }, [location])

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString) {
            const obj = JSON.parse(objString);
            if (Date.now() > obj.expire) {
                localStorage.removeItem('token');
                dispatch(checkLogin(false));
                navigate('/')
            }
            else {
                dispatch(checkLogin(true));
            }
        }
        else {
            dispatch(checkLogin(false));
            navigate('/')
        }
    }, []);

    useEffect(() => {
        document.title = "NAMUBIT│One-Stop Trading Platform - Crypto Trading Universe";
    }, [API_Address]);

    return (
        <div className={`history ${darkname}`}>
            <div className='history-container'>
                <div className='history-wrap'>
                    <C_History_Sidebar tab={tab} setTab={setTab} />
                    {
                        tab === 0
                            ?
                            <SpotSummary personal_fee={personal_fee} />
                            :
                            tab === 1
                                ?
                                <SpotDetail personal_fee={personal_fee} />
                                :
                                tab === 2
                                    ?
                                    <FuturesSummary personal_fee={personal_fee} />
                                    :
                                    tab === 3
                                        ?
                                        <FuturesDetail personal_fee={personal_fee} />
                                        :
                                        <FuturesFee />
                    }
                </div>
            </div>
        </div>
    )
}

let s_s_id = 0
function SpotSummary({ personal_fee }) {
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedDate1, setSelectedDate1] = useState(new Date());
    const [spotOrderHistory, setSpotOrderHistory] = useState([]);
    const [spotsum, setSpotsum] = useState([])
    const [totalVolume, setTotalVolume] = useState(0)
    const [totalSum, setTotalSum] = useState(0)
    const [spinner, setSpinner] = useState(true)

    let dispatch = useDispatch();

    useEffect(() => {
        setSpinner(true)
        SpotOrderHistory()
    }, [selectedDate, selectedDate1])

    const SpotOrderHistory = async () => {
        const objString = localStorage.getItem('token');
        if (objString) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            await axios.get(process.env.REACT_APP_API + "/api/v1/trade/bn/spot/history", {
                params: {
                    reqId: s_s_id++,
                    begin: changeTime(selectedDate),
                    end: changeTime(selectedDate1) + 86399999
                },
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                let reqId = response?.data?.reqId
                let tmp = response?.data?.data?.data
                if (tmp && s_s_id - 1 === Number(reqId)) {
                    setSpotOrderHistory(tmp)
                }
                setSpinner(false)
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 49, "msg": error }))
            })
        }
    }

    useEffect(() => {
        let summary = []
        let totalV = 0
        let totalS = 0

        if (spotOrderHistory) {
            for (let i = 0; i < spotOrderHistory.length; i++) {
                let check = true
                for (let j = 0; j < summary.length; j++) {
                    if (spotOrderHistory[i].symbol === summary[j].symbol) {
                        check = false
                        summary[j].volume += Number(spotOrderHistory[i].last_executed_price) * Number(spotOrderHistory[i].last_executed_quantity)
                        summary[j].pnl += 0
                        summary[j].fee += Number(spotOrderHistory[i].commission_amount) * (spotOrderHistory[i].side === "BUY" ? Number(spotOrderHistory[i].last_executed_price) : 1)
                        summary[j].reward += 0
                    }
                }
                if (check) {
                    let arr = spotOrderHistory[i].symbol.split('USDT')
                    summary.push({
                        symbol: spotOrderHistory[i].symbol,
                        ccy: arr[0],
                        volume: Number(spotOrderHistory[i].last_executed_price) * Number(spotOrderHistory[i].last_executed_quantity),
                        pnl: 0,
                        fee: Number(spotOrderHistory[i].commission_amount) * (spotOrderHistory[i].side === "BUY" ? Number(spotOrderHistory[i].last_executed_price) : 1),
                        reward: 0
                    })
                }
                totalV += Number(spotOrderHistory[i].last_executed_price) * Number(spotOrderHistory[i].last_executed_quantity)
                totalS -= Number(spotOrderHistory[i].commission_amount) * (spotOrderHistory[i].side === "BUY" ? Number(spotOrderHistory[i].last_executed_price) : 1)
            }
        }

        setSpotsum(summary)
        setTotalVolume(totalV)
        setTotalSum(totalS)
    }, [spotOrderHistory])

    return (
        <C_Summary selectedDate={selectedDate} selectedDate1={selectedDate1} setSelectedDate={setSelectedDate} setSelectedDate1={setSelectedDate1} spotsum={spotsum} totalVolume={totalVolume} totalSum={totalSum} spinner={spinner} personal_fee={personal_fee} type={"spot"} />
    )
}

let s_d_id = 0
function SpotDetail({ personal_fee }) {
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedDate1, setSelectedDate1] = useState(new Date());
    const [spotOrderHistory, setSpotOrderHistory] = useState([]);
    const [spotsum, setSpotsum] = useState([])
    const [totalVolume, setTotalVolume] = useState(0)
    const [totalFee, setTotalFee] = useState(0)
    const [checksym, setChecksym] = useState([])
    const [spinner, setSpinner] = useState(true)
    const [tableData, setTableData] = useState([])

    let dispatch = useDispatch();

    useEffect(() => {
        setSpinner(true)
        SpotOrderHistory()
    }, [selectedDate, selectedDate1])

    const SpotOrderHistory = async () => {
        const objString = localStorage.getItem('token');
        if (objString) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            await axios.get(process.env.REACT_APP_API + "/api/v1/trade/bn/spot/history", {
                params: {
                    reqId: s_d_id++,
                    begin: changeTime(selectedDate),
                    end: changeTime(selectedDate1) + 86399999
                },
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                let reqId = response?.data?.reqId
                let tmp = response?.data?.data?.data
                if (tmp && s_d_id - 1 === Number(reqId)) {
                    setSpotOrderHistory(tmp)
                }
                setSpinner(false)
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 49, "msg": error }))
            })
        }
    }

    useEffect(() => {
        let summary = []
        let data = []
        let totalV = 0
        let totalP = 0
        let totalF = 0

        if (spotOrderHistory) {
            for (let i = 0; i < spotOrderHistory.length; i++) {
                let check = true
                for (let j = 0; j < summary.length; j++) {
                    if (spotOrderHistory[i].symbol === summary[j].symbol) {
                        check = false
                        summary[j].volume += Number(spotOrderHistory[i].last_executed_price) * Number(spotOrderHistory[i].last_executed_quantity)
                        summary[j].pnl += 0
                        summary[j].fee += Number(spotOrderHistory[i].commission_amount) * (spotOrderHistory[i].side === "BUY" ? Number(spotOrderHistory[i].last_executed_price) : 1)
                        summary[j].reward += 0
                    }
                }
                if (check) {
                    let arr = spotOrderHistory[i].symbol.split('USDT')
                    summary.push({
                        symbol: spotOrderHistory[i].symbol,
                        ccy: arr[0],
                        volume: Number(spotOrderHistory[i].last_executed_price) * Number(spotOrderHistory[i].last_executed_quantity),
                        pnl: 0,
                        fee: Number(spotOrderHistory[i].commission_amount) * (spotOrderHistory[i].side === "BUY" ? Number(spotOrderHistory[i].last_executed_price) : 1),
                        reward: 0
                    })
                }

                const date = UTCTimestamp(new Date(spotOrderHistory[i].transaction_time));
                const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())}:${padZero(date.getSeconds())}`;

                data.push({
                    symbol: spotOrderHistory[i].symbol,
                    time: formattedDate,
                    side: spotOrderHistory[i].side,
                    price: Number(spotOrderHistory[i].last_executed_price),
                    amount: Number(spotOrderHistory[i].last_executed_quantity),
                    fee: Number(spotOrderHistory[i].commission_amount) * (spotOrderHistory[i].side == "BUY" ? Number(spotOrderHistory[i].last_executed_price) : 1),
                    reward: 0
                })

                totalV += Number(spotOrderHistory[i].last_executed_price) * Number(spotOrderHistory[i].last_executed_quantity)
                totalP += 0
                totalF += Number(spotOrderHistory[i].commission_amount) * (spotOrderHistory[i].side === "BUY" ? Number(spotOrderHistory[i].last_executed_price) : 1)
            }
        }

        setTableData(data)
        setSpotsum(summary)
        setChecksym(Array.from({ length: summary.length }, () => false))
        setTotalVolume(totalV)
        setTotalFee(totalF)
    }, [spotOrderHistory])

    const handleButtonClick = (e) => {
        setChecksym(prevState => {
            const newState = [...prevState];
            for (let i = 0; i < newState.length; i++) {
                if (i === e && !newState[i]) newState[i] = true
                else newState[i] = false
            }
            return newState;
        });
    };

    return (
        <C_Detail selectedDate={selectedDate} selectedDate1={selectedDate1} setSelectedDate={setSelectedDate} setSelectedDate1={setSelectedDate1} spotsum={spotsum} tableData={tableData} totalVolume={totalVolume} totalFee={totalFee} checksym={checksym} spinner={spinner} personal_fee={personal_fee} handleButtonClick={handleButtonClick} type={"spot"} />
    )
}

let f_s_id = 0
function FuturesSummary({ personal_fee }) {
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedDate1, setSelectedDate1] = useState(new Date());
    const [futuresOrderHistory, setFuturesOrderHistory] = useState([]);
    const [futuressum, setFuturessum] = useState([])
    const [totalVolume, setTotalVolume] = useState(0)
    const [totalSum, setTotalSum] = useState(0)
    const [spinner, setSpinner] = useState(true)

    let dispatch = useDispatch();

    useEffect(() => {
        setSpinner(true)
        FuturesOrderHistory()
    }, [selectedDate, selectedDate1])

    const FuturesOrderHistory = async () => {
        const objString = localStorage.getItem('token');
        if (objString) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            await axios.get(process.env.REACT_APP_API + `/api/v1/trade/bn/futures/history`, {
                params: {
                    reqId: f_s_id++,
                    begin: changeTime(selectedDate),
                    end: changeTime(selectedDate1) + 86399999
                },
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                let reqId = response?.data?.reqId
                let tmp = response?.data?.data?.data
                if (tmp && f_s_id - 1 === Number(reqId)) {
                    let arrTmp = tmp.filter(i => i.execution_type == "TRADE")
                    setFuturesOrderHistory(arrTmp)
                    setSpinner(false)
                }
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 50, "msg": error }))
            })
        }
    }

    useEffect(() => {
        let summary = []
        let totalV = 0
        let totalS = 0

        if (futuresOrderHistory) {
            for (let i = 0; i < futuresOrderHistory.length; i++) {
                let check = true
                for (let j = 0; j < summary.length; j++) {
                    if (futuresOrderHistory[i].symbol === summary[j].symbol) {
                        check = false
                        summary[j].volume += Number(futuresOrderHistory[i].last_filled_price) * Number(futuresOrderHistory[i].order_last_filled_quantity)
                        summary[j].pnl += Number(futuresOrderHistory[i].realized_profit)
                        summary[j].fee += Number(futuresOrderHistory[i].commission)
                        summary[j].reward += Number(futuresOrderHistory[i].reward)
                    }
                }
                if (check) {
                    let arr = futuresOrderHistory[i].symbol.split('USDT')
                    summary.push({
                        symbol: futuresOrderHistory[i].symbol,
                        ccy: arr[0],
                        volume: Number(futuresOrderHistory[i].last_filled_price) * Number(futuresOrderHistory[i].order_last_filled_quantity),
                        pnl: Number(futuresOrderHistory[i].realized_profit),
                        fee: Number(futuresOrderHistory[i].commission),
                        reward: Number(futuresOrderHistory[i].reward)
                    })
                }
                totalV += Number(futuresOrderHistory[i].last_filled_price) * Number(futuresOrderHistory[i].order_last_filled_quantity)
                totalS += Number(futuresOrderHistory[i].realized_profit) - Number(futuresOrderHistory[i].commission) + Number(futuresOrderHistory[i].reward)
            }
        }

        setFuturessum(summary)
        setTotalVolume(totalV)
        setTotalSum(totalS)
    }, [futuresOrderHistory])

    return (
        <C_Summary selectedDate={selectedDate} selectedDate1={selectedDate1} setSelectedDate={setSelectedDate} setSelectedDate1={setSelectedDate1} spotsum={futuressum} totalVolume={totalVolume} totalSum={totalSum} spinner={spinner} personal_fee={personal_fee} type={"futures"} />
    )
}

let f_d_id = 0
function FuturesDetail({ personal_fee }) {
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedDate1, setSelectedDate1] = useState(new Date());
    const [futuresOrderHistory, setFuturesOrderHistory] = useState([]);
    const [futuressum, setFuturessum] = useState([])
    const [checksym, setChecksym] = useState([])
    const [totalVolume, setTotalVolume] = useState(0)
    const [totalPnl, setTotalPnl] = useState(0)
    const [totalFee, setTotalFee] = useState(0)
    const [totalReward, setTotalReward] = useState(0)
    const [spinner, setSpinner] = useState(true)
    const [tableData, setTableData] = useState([])

    let dispatch = useDispatch();

    useEffect(() => {
        setSpinner(true)
        FuturesOrderHistory()
    }, [selectedDate, selectedDate1])

    const FuturesOrderHistory = async () => {
        const objString = localStorage.getItem('token');
        if (objString) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            await axios.get(process.env.REACT_APP_API + `/api/v1/trade/bn/futures/history`, {
                params: {
                    reqId: f_d_id++,
                    begin: changeTime(selectedDate),
                    end: changeTime(selectedDate1) + 86399999
                },
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                let reqId = response?.data?.reqId
                let tmp = response?.data?.data?.data
                if (tmp && f_d_id - 1 === Number(reqId)) {
                    let arrTmp = tmp.filter(i => i.execution_type == "TRADE")
                    setFuturesOrderHistory(arrTmp)
                    setSpinner(false)
                }
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 51, "msg": error }))
            })
        }
    }

    useEffect(() => {
        let summary = []
        let data = []
        let totalV = 0
        let totalP = 0
        let totalF = 0
        let totalR = 0

        if (futuresOrderHistory) {
            for (let i = 0; i < futuresOrderHistory.length; i++) {
                let check = true
                for (let j = 0; j < summary.length; j++) {
                    if (futuresOrderHistory[i].symbol === summary[j].symbol) {
                        check = false
                        summary[j].volume += Number(futuresOrderHistory[i].last_filled_price) * Number(futuresOrderHistory[i].order_last_filled_quantity)
                        summary[j].pnl += Number(futuresOrderHistory[i].realized_profit)
                        summary[j].fee += Number(futuresOrderHistory[i].commission)
                        summary[j].reward += Number(futuresOrderHistory[i].reward)
                    }
                }
                if (check) {
                    let arr = futuresOrderHistory[i].symbol.split('USDT')
                    summary.push({
                        symbol: futuresOrderHistory[i].symbol,
                        ccy: arr[0],
                        volume: Number(futuresOrderHistory[i].last_filled_price) * Number(futuresOrderHistory[i].order_last_filled_quantity),
                        pnl: Number(futuresOrderHistory[i].realized_profit),
                        fee: Number(futuresOrderHistory[i].commission),
                        reward: Number(futuresOrderHistory[i].reward)
                    })
                }

                const date = UTCTimestamp(new Date(futuresOrderHistory[i].transaction_time));
                const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())}:${padZero(date.getSeconds())}`;

                data.push({
                    symbol: futuresOrderHistory[i].symbol,
                    time: formattedDate,
                    side: futuresOrderHistory[i].side,
                    price: Number(futuresOrderHistory[i].last_filled_price),
                    amount: Number(futuresOrderHistory[i].order_last_filled_quantity),
                    fee: Number(futuresOrderHistory[i].commission),
                    pnl: Number(futuresOrderHistory[i].realized_profit),
                    reward: Number(futuresOrderHistory[i].reward)
                })

                totalV += Number(futuresOrderHistory[i].last_filled_price) * Number(futuresOrderHistory[i].order_last_filled_quantity)
                totalP += Number(futuresOrderHistory[i].realized_profit)
                totalF += Number(futuresOrderHistory[i].commission)
                totalR += Number(futuresOrderHistory[i].reward)
            }
        }

        setTableData(data)
        setFuturessum(summary)
        setChecksym(Array.from({ length: summary.length }, () => false))
        setTotalVolume(totalV)
        setTotalPnl(totalP)
        setTotalFee(totalF)
        setTotalReward(totalR)
    }, [futuresOrderHistory])

    const handleButtonClick = (e) => {
        setChecksym(prevState => {
            const newState = [...prevState];
            for (let i = 0; i < newState.length; i++) {
                if (i === e && !newState[i]) newState[i] = true
                else newState[i] = false
            }
            return newState;
        });
    };

    return (
        <C_Detail selectedDate={selectedDate} selectedDate1={selectedDate1} setSelectedDate={setSelectedDate} setSelectedDate1={setSelectedDate1} spotsum={futuressum} tableData={tableData} totalVolume={totalVolume} totalFee={totalFee} totalPnl={totalPnl} totalReward={totalReward} checksym={checksym} spinner={spinner} personal_fee={personal_fee} handleButtonClick={handleButtonClick} type={"futures"} />
    )
}

function FuturesFee({ }) {
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedDate1, setSelectedDate1] = useState(new Date());
    const [feeData, setFeeDate] = useState([])
    const [feeList, setFeeList] = useState([])

    let dispatch = useDispatch();

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            FundingFee(token)
        }
    }, [selectedDate, selectedDate1])

    const FundingFee = async (token) => {
        await axios.get(process.env.REACT_APP_API + `/api/v1/wallet/bn/funding-fee`, {
            params: {
                startTime: selectedDate.getTime(),
                endTime: selectedDate1.getTime(),
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            let tmp = response?.data?.data
            if (tmp) setFeeDate(tmp)
        }).catch((error) => {
            dispatch(check_Error({ "bol": true, "no": 52, "msg": error }))
        })
    }

    useEffect(() => {
        if (feeData) {
            let arr = []
            for (let i = 0; i < feeData.length; i++) {
                const date = UTCTimestamp(feeData[i].time);
                const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())}:${padZero(date.getSeconds())}`;

                arr.push({
                    time: formattedDate,
                    symbol: feeData[i].symbol,
                    fee: feeData[i].income
                })
            }
            setFeeList(arr)
        }
    }, [feeData])

    return (
        <C_FuturesFee selectedDate={selectedDate} selectedDate1={selectedDate1} setSelectedDate={setSelectedDate} setSelectedDate1={setSelectedDate1} feeList={feeList} />
    )
}

export default BHistory;