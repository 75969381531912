import axios from 'axios';
import base64 from 'base-64';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import {
    Binance_addCoinS, Binance_addCoinF, OKX_addCoinS, OKX_addCoinF, change_Binance_position, change_OKX_position, check_OKX_Instruments, addCoin_OKX_S_Favorite, addCoin_OKX_F_Favorite, addCoin_Binance_S_Favorite,
    addCoin_Binance_F_Favorite, changeKYC, change_Binance_wallet_spot, change_Binance_wallet_futures, change_OKX_wallet, change_Binance_openorder_futures, change_OKX_wallet_usdt, change_OKX_liquidation,
    change_Binance_ListenKey, check_Error, checkOKX_timestamp, Bybit_addCoinS, Bybit_addCoinF, change_Bybit_wallet, change_Bybit_wallet_usdt, addCoin_Bybit_S_Favorite, addCoin_Bybit_F_Favorite,
    change_Bybit_position
} from './store';
import { useLocation } from 'react-router-dom';
import isEqual from 'lodash/isEqual';
import { findSmallest } from './Pages/common/function';

const Binance_urlS = 'https://api.binance.com/api/v3/ticker/24hr';
const Binance_urlF = 'https://fapi.binance.com/fapi/v1/ticker/24hr';
const OKX_urlS = 'https://www.okx.com/api/v5/market/tickers?instType=SPOT';
const OKX_urlF = 'https://www.okx.com/api/v5/market/tickers?instType=SWAP';

let cnt = 0
let preArr = []
let bn_name = []
let okx_name = []

let version = "1.241018.01"

function Api() {
    let dispatch = useDispatch();
    let login = useSelector((state) => { return state.login.value });
    let SelectType = useSelector((state) => { return state.SelectType.value })
    let API_Address = useSelector((state) => { return state.API_Address.value })
    let Reload = useSelector((state) => { return state.Reload.value })
    let Bybit_Mode = useSelector((state) => { return state.Bybit_Mode.value })

    let location = useLocation();

    const [instrumentsData, setInstrumentData] = useState([])
    const [loca, setLoca] = useState("main")
    const [listenkey_futures, setListenkey_futures] = useState("")
    const [listenkey_spot, setListenkey_spot] = useState("")
    const [zen, setZen] = useState(false)

    useEffect(() => {
        if (location.pathname == '/') setLoca("main")
        else if (location.pathname.includes("market")) setLoca("market")
        else if (location.pathname.includes("spot")) setLoca("spot")
        else if (location.pathname.includes("futures")) setLoca("futures")
        else if (location.pathname.includes("wallet")) setLoca("wallet")
        else if (location.pathname.includes("dashboard")) setLoca("dashboard")
        else setLoca("else")
        CheckVersion()
    }, [location])

    const Zendesk = (lo) => {
        if (document.body.childNodes[3]) {
            if (lo == "main" || lo == "dashboard") {
                document.body.childNodes[3].style.visibility = "visible"
            }
            else {
                document.body.childNodes[3].style.visibility = "hidden"
            }
        }
    }

    useEffect(() => {
        if (API_Address == "rf") {
            const intervalId = setInterval(() => {
                if (document.body.childNodes[3]) {
                    setZen(true)
                    clearInterval(intervalId)
                }
            }, 200)
        }
    }, [API_Address])

    useEffect(() => {
        if (zen) {
            Zendesk(loca)
        }
    }, [loca, zen])

    useEffect(() => {
        if (login) {
            CheckKYC();
        }
    }, [login, location]);

    useEffect(() => {
        if (login) {
            Favorite();
            Listenkey()
            By_TimeStamp()
            const intervalId = setInterval(() => {
                By_TimeStamp()
            }, 4000)
            return () => clearInterval(intervalId);
        }
    }, [login]);

    useEffect(() => {
        if (login) {
            Timestamp();
        }
    }, [login, Reload]);

    // Bybit 초기 Timestamp 값
    const By_TimeStamp = async () => {
        await axios.get(`https://api.bybit.com/v5/market/time`, {
        }).then((response) => {
            let tmp = response.data
            if (tmp.retCode == 10004 || tmp.retCode == 10001) {
                By_TimeStamp()
            }
            else if (tmp.retCode == 0) {
                if (tmp?.time) {
                    localStorage.setItem("bybit_timestamp", tmp.time.toString())
                }
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    //OKX 초기 Timestamp 값 
    const Timestamp = async () => {
        let local_time = localStorage.getItem("okx_timestamp")
        if (local_time && JSON.parse(local_time).delay >= Date.now()) {
            dispatch(checkOKX_timestamp(JSON.parse(local_time).time))
        }
        else {
            await axios.get("https://www.okx.com/api/v5/public/time", {
            }).then((response) => {
                let tmp = response?.data?.data
                if (tmp && tmp[0] && tmp[0].ts) {
                    localStorage.removeItem("okx_timestamp")
                    dispatch(checkOKX_timestamp(String(Number(tmp[0].ts) / 1000)))
                    let obj = {
                        time: String(Number(tmp[0].ts) / 1000),
                        delay: Date.now() + 20000
                    }
                    const objString = JSON.stringify(obj);
                    localStorage.setItem("okx_timestamp", objString)
                }
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 9, "msg": error }))
            })
        }
    }

    // 버전확인
    const CheckVersion = () => {
        const objString = localStorage.getItem('version');
        if (objString) {
            if (objString != version) {
                localStorage.setItem('version', version);
                window.location.reload(true);
            }
        }
        else {
            localStorage.setItem('version', version);
            window.location.reload(true);
        }
    }

    // 즐겨찾기 목록 불러오기
    const Favorite = async () => {
        let objString = localStorage.getItem('token');
        if (objString) {
            let obj = JSON.parse(objString)
            let token = obj.value;

            await axios.get(process.env.REACT_APP_API + "/api/v1/account/user/bookmark", {
                headers: { Authorization: `Bearer ${token}` }
            }).then((response) => {
                let tmp = response?.data?.data
                if (tmp) {
                    tmp.map(function (a, i) {
                        if (a.exchange === "okx") {
                            if (a.type === "spot") dispatch(addCoin_OKX_S_Favorite(a.coin))
                            else dispatch(addCoin_OKX_F_Favorite(a.coin))
                        }
                        else if (a.exchange === "binance") {
                            if (a.type === "spot") dispatch(addCoin_Binance_S_Favorite(a.coin))
                            else dispatch(addCoin_Binance_F_Favorite(a.coin))
                        }
                        else {
                            if (a.type === "spot") dispatch(addCoin_Bybit_S_Favorite(a.coin))
                            else dispatch(addCoin_Bybit_F_Favorite(a.coin))
                        }
                    })
                }
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 1, "msg": error }))
            })
        }
    }

    // KYC 확인
    const CheckKYC = async () => {
        let objString = localStorage.getItem('token');
        if (objString) {
            let obj = JSON.parse(objString)
            let jwt = obj.value;

            await axios.get(process.env.REACT_APP_API + "/api/v1/account/user/check/kyc", {
                headers: { Authorization: `Bearer ${jwt}` }
            }).then((response) => {
                let tmp = response?.data?.data
                if (tmp) {
                    dispatch(changeKYC(tmp))
                }
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 2, "msg": error }))
            })
        }
    }

    //  Bn Listenkey 확인
    const Listenkey = async () => {
        let objString = localStorage.getItem('token');
        if (objString) {
            let obj = JSON.parse(objString);
            let token = obj.value;
            await axios.get(process.env.REACT_APP_API + "/api/v1/account/user/get/listenkey", {
                headers: { Authorization: `Bearer ${token}` }
            }).then((response) => {
                let tmp = response?.data?.data
                if (tmp) {
                    if (tmp.listenKey) {
                        dispatch(change_Binance_ListenKey({ "futures": tmp.listenKey }))
                        setListenkey_futures(tmp.listenKey)
                    }
                    if (tmp.spotListenKey) {
                        dispatch(change_Binance_ListenKey({ "spot": tmp.spotListenKey }))
                        setListenkey_spot(tmp.spotListenKey)
                    }
                }
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 3, "msg": error }))
            })
        }
    }



    return (
        <div id="api">
            <OKX_Instruments setInstrumentData={setInstrumentData} />
            <OKX_Wallet />
            <Bybit_Wallet />
            <Binance_Name />
            <Binance_Wallet_Spot listenkey_spot={listenkey_spot} />
            <Binance_Wallet_Futures listenkey_futures={listenkey_futures} />
            {(loca == "main" || loca == "market" && SelectType == 0 || loca == "futures" && SelectType == 0) ? <OKX_Futures instrumentsData={instrumentsData} /> : ""}
            {(loca == "main" || loca == "market" && SelectType == 1 || loca == "futures" && SelectType == 1) ? <Binance_Futures /> : ""}
            {(loca == "main" || loca == "market" && SelectType == 2 || loca == "futures" && SelectType == 2) ? <Bybit_Futures /> : ""}
            {(loca == "futures" && SelectType == 0) ? <OKX_Position /> : ""}
            {/* {(loca == "futures" && SelectType == 1) ? <Binance_Position listenkey_futures={listenkey_futures} /> : ""} */}
            {(loca == "futures" && SelectType == 2) ? <Bybit_Position /> : ""}
            {(loca == "market" || loca == "spot" || loca == "wallet") && SelectType == 0 ? <OKX_Spot /> : ""}
            {loca == "main" || ((loca == "market" || loca == "spot" || loca == "wallet") && SelectType == 1) ? <Binance_Spot /> : ""}
            {(loca == "market" || loca == "spot" || loca == "wallet") && SelectType == 2 ? <Bybit_Spot /> : ""}
        </div>
    )
}

function Bybit_Spot() {
    const [BybitdataSpot, setBybitDataSpot] = useState([]);
    let dispatch = useDispatch()
    let Bybit_S_favorite = useSelector((state) => { return state.Bybit_S_favorite })

    const getData = async () => {
        await axios.get('https://api.bybit.com/v5/market/tickers?category=spot', {
        }).then((response) => {
            let tmp = response.data
            if (tmp?.result) {
                setBybitDataSpot(tmp?.result?.list ?? [])
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    useEffect(() => {
        getData()
        const intervalId = setInterval(() => {
            getData()
        }, 1000)
        return () => clearInterval(intervalId);
    }, [])

    useEffect(() => {
        if (BybitdataSpot) {
            let Bybit_spot_arr = []
            BybitdataSpot.map(function (a, i) {
                if (a.symbol.includes('USDT')) {
                    if (a.volume24h > 0 || a.lastPrice > 0) {
                        let arr = a.symbol.split('USDT')
                        let point = Math.max(findSmallest(a.lastPrice), Math.max(findSmallest(a.highPrice24h), findSmallest(a.lowPrice24h)))
                        // let name = okx_name ? okx_name.filter((item) => item.ccy == arr[0]) : []

                        if (arr[0] != "USDT" && !arr[1]) {
                            if (Bybit_S_favorite && Bybit_S_favorite.includes(arr[0])) {
                                Bybit_spot_arr.push({
                                    symbol: a.symbol, ccy: arr[0], name: arr[0] + "/USDT", fullName: a.symbol, priceChangePercent: Number(a.price24hPcnt) * 100,
                                    lastPrice: a.lastPrice, volume: Number(a.volume24h), highPrice: a.highPrice24h, lowPrice: a.lowPrice24h, priceChange: (Number(a.lastPrice) - Number(a.prevPrice24h)), check: false, favorite: true, point: point, exch: "Bybit"
                                })
                            }
                            else {
                                Bybit_spot_arr.push({
                                    symbol: a.symbol, ccy: arr[0], name: arr[0] + "/USDT", fullName: a.symbol, priceChangePercent: Number(a.price24hPcnt) * 100,
                                    lastPrice: a.lastPrice, volume: Number(a.volume24h), highPrice: a.highPrice24h, lowPrice: a.lowPrice24h, priceChange: (Number(a.lastPrice) - Number(a.prevPrice24h)), check: false, favorite: false, point: point, exch: "Bybit"
                                })
                            }
                        }
                    }
                }
            })
            dispatch(Bybit_addCoinS(Bybit_spot_arr))
        }
    }, [BybitdataSpot, Bybit_S_favorite])

    return (
        <div id='Bybit_Spot'></div>
    )
}

function Bybit_Futures() {
    const [BybitdataFutures, setBybitDataFutures] = useState([]);
    let dispatch = useDispatch()
    let Bybit_F_favorite = useSelector((state) => { return state.Bybit_F_favorite })


    const getData = async () => {
        await axios.get('https://api.bybit.com/v5/market/tickers?category=linear', {
        }).then((response) => {
            let tmp = response.data
            if (tmp?.result) {
                setBybitDataFutures(tmp?.result?.list ?? [])
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    useEffect(() => {
        getData()
        const intervalId = setInterval(() => {
            getData()
        }, 1000)
        return () => clearInterval(intervalId);
    }, [])

    useEffect(() => {
        if (BybitdataFutures) {
            let Bybit_futures_arr = []
            BybitdataFutures.map(function (a, i) {
                if (a.symbol.includes('USDT')) {
                    if (a.volume24h > 0 || a.lastPrice > 0) {
                        let arr = a.symbol.split('USDT')
                        let point = Math.max(findSmallest(a.lastPrice), Math.max(findSmallest(a.highPrice24h), findSmallest(a.lowPrice24h)))
                        // let name = okx_name ? okx_name.filter((item) => item.ccy == arr[0]) : []

                        if (arr[0] != "USDT") {
                            if (Bybit_F_favorite && Bybit_F_favorite.includes(arr[0])) {
                                Bybit_futures_arr.push({
                                    symbol: a.symbol, ccy: arr[0], name: arr[0] + "/USDT", fullName: a.symbol, priceChangePercent: Number(a.price24hPcnt) * 100,
                                    lastPrice: a.lastPrice, volume: Number(a.volume24h), highPrice: a.highPrice24h, lowPrice: a.lowPrice24h, priceChange: (Number(a.lastPrice) - Number(a.prevPrice24h)), check: false, favorite: true, point: point, exch: "Bybit"
                                })
                            }
                            else {
                                Bybit_futures_arr.push({
                                    symbol: a.symbol, ccy: arr[0], name: arr[0] + "/USDT", fullName: a.symbol, priceChangePercent: Number(a.price24hPcnt) * 100,
                                    lastPrice: a.lastPrice, volume: Number(a.volume24h), highPrice: a.highPrice24h, lowPrice: a.lowPrice24h, priceChange: (Number(a.lastPrice) - Number(a.prevPrice24h)), check: false, favorite: false, point: point, exch: "Bybit"
                                })
                            }
                        }
                    }
                }
            })
            dispatch(Bybit_addCoinF(Bybit_futures_arr))
        }
    }, [BybitdataFutures, Bybit_F_favorite])

    return (
        <div id='Bybit_Futures'></div>
    )
}

function Bybit_Wallet() {
    const CryptoJS = require('crypto-js');
    let login = useSelector((state) => { return state.login.value });
    let By_apiKey = useSelector((state) => { return state.By_apiKey })
    let Reload = useSelector((state) => { return state.Reload.value })
    let dispatch = useDispatch();

    const [close, setClose] = useState(false)

    useEffect(() => {
        if (bn_cnt > 0) {
            setClose(e => !e)
        }
        bn_cnt += 1
    }, [Reload])

    useEffect(() => {
        if (login && By_apiKey?.S_api?.length > 0) {
            SpotBalance()
            SpotBalanceUSDT()
            const intervalId = setInterval(() => {
                SpotBalance()
                SpotBalanceUSDT()
            }, 1000)
            return () => clearInterval(intervalId);
        }
    }, [login, By_apiKey])

    const SpotBalance = async () => {
        const by_timestamp = localStorage.getItem('bybit_timestamp');
        if (by_timestamp) {
            const paramStr = `${by_timestamp}${By_apiKey.S_api}${10000}accountType=UNIFIED`
            await axios.get(`https://api.bybit.com/v5/account/wallet-balance?accountType=UNIFIED`, {
                headers: {
                    "X-BAPI-SIGN": CryptoJS.HmacSHA256(paramStr, By_apiKey.S_sec).toString(CryptoJS.enc.Hex),
                    "X-BAPI-API-KEY": By_apiKey.S_api,
                    "X-BAPI-TIMESTAMP": by_timestamp,
                    "X-BAPI-RECV-WINDOW": 10000
                }
            }).then((response) => {
                let tmp = response.data
                // console.log(tmp)
                if (tmp.retCode == 10004 || tmp.retCode == 10001) {
                    SpotBalance()
                }
                else if (tmp.retCode === 0) {
                    dispatch(change_Bybit_wallet(tmp?.result?.list[0]?.coin ?? []))
                }
            }).catch((error) => {
                console.log(error)
            })
        }
        else {
            setTimeout(() => {
                SpotBalance()
            }, 1000)
        }
    }

    const SpotBalanceUSDT = async () => {
        const by_timestamp = localStorage.getItem('bybit_timestamp');
        if (by_timestamp) {
            const paramStr = `${by_timestamp}${By_apiKey.S_api}${10000}accountType=UNIFIED&coin=USDT`
            await axios.get(`https://api.bybit.com/v5/account/wallet-balance?accountType=UNIFIED&coin=USDT`, {
                headers: {
                    "X-BAPI-SIGN": CryptoJS.HmacSHA256(paramStr, By_apiKey.S_sec).toString(CryptoJS.enc.Hex),
                    "X-BAPI-API-KEY": By_apiKey.S_api,
                    "X-BAPI-TIMESTAMP": by_timestamp,
                    "X-BAPI-RECV-WINDOW": 10000
                }
            }).then((response) => {
                let tmp = response.data
                if (tmp.retCode == 10004 || tmp.retCode == 10001) {
                    SpotBalanceUSDT()
                }
                else if (tmp.retCode === 0) {
                    dispatch(change_Bybit_wallet_usdt(tmp?.result?.list[0]?.coin ?? []))
                }
            }).catch((error) => {
                console.log(error)
            })
        }
        else {
            setTimeout(() => {
                SpotBalanceUSDT()
            }, 1000)
        }
    }

    // useEffect(() => {
    //     const by_timestamp = localStorage.getItem('bybit_timestamp');
    //     if (login && by_timestamp && By_apiKey?.S_api?.length > 0) {
    //         let socket = new WebSocket(`wss://stream.bybit.com/v5/private`);

    //         const generateSignature = (apiSecret, expires) => {
    //             const paramStr = `GET/realtime${expires}`;
    //             return CryptoJS.HmacSHA256(paramStr, apiSecret).toString(CryptoJS.enc.Hex);
    //         };

    //         const expires = Number(by_timestamp) + 100000;
    //         const signature = generateSignature(By_apiKey.S_sec, expires);

    //         const loginMessage = {
    //             op: "auth",
    //             args: [By_apiKey.S_api, expires.toString(), signature]
    //         }

    //         const subscribeMessage = {
    //             op: "subscribe",
    //             args: ["wallet"]
    //         }

    //         socket.onopen = () => {
    //             socket.send(JSON.stringify(loginMessage));
    //             const intervalId = setInterval(() => {
    //                 if (socket.readyState === WebSocket.OPEN) {
    //                     socket.send(JSON.stringify({ "op": "ping" }));
    //                 }
    //             }, 25000)

    //             return () => {
    //                 clearInterval(intervalId)
    //             }
    //         };

    //         socket.onmessage = function (event) {
    //             let nextArr = []
    //             let tmp = JSON.parse(event.data);
    //             if (tmp?.op != "pong") {
    //                 if (tmp?.op == "auth" && tmp.success) {
    //                     socket.send(JSON.stringify(subscribeMessage))
    //                 }
    //                 else if (tmp.topic === "wallet") {
    //                 }
    //             }
    //         }
    //         socket.onclose = function (event) {
    //         };
    //         socket.error = function (error) {
    //             window.location.reload()
    //         }
    //         return () => {
    //             socket.close();
    //         }
    //     }
    // }, [login, close, By_apiKey])

    return (
        <div id='Bybit_Wallet'></div>
    )
}

let bybit_position = []
function Bybit_Position() {
    const CryptoJS = require('crypto-js');
    let login = useSelector((state) => { return state.login.value });
    let By_apiKey = useSelector((state) => { return state.By_apiKey })
    let Reload = useSelector((state) => { return state.Reload.value })
    let dispatch = useDispatch();

    const [close, setClose] = useState(false)

    let Alert = useSelector((state) => { return state.Alert });
    const [isPlaying, setIsPlaying] = useState(false);
    const [isPlaying1, setIsPlaying1] = useState(false);
    const [isPlaying2, setIsPlaying2] = useState(false);
    const [isPlaying3, setIsPlaying3] = useState(false);
    const [isPlaying4, setIsPlaying4] = useState(false);

    const playAudio = () => {
        setIsPlaying(true);
        setTimeout(() => {
            setIsPlaying(false);
        }, 1000)
    };

    const playAudio1 = () => {
        setIsPlaying1(true);
        setTimeout(() => {
            setIsPlaying1(false);
        }, 1000)
    };

    const playAudio2 = () => {
        setIsPlaying2(true);
        setTimeout(() => {
            setIsPlaying2(false);
        }, 1000)
    };

    const playAudio3 = () => {
        setIsPlaying3(true);
        setTimeout(() => {
            setIsPlaying3(false);
        }, 1000)
    };

    const playAudio4 = () => {
        setIsPlaying4(true);
        setTimeout(() => {
            setIsPlaying4(false);
        }, 1000)
    };

    useEffect(() => {
        if (bn_cnt > 0) {
            setClose(e => !e)
        }
    }, [Reload])

    useEffect(() => {
        if (login && By_apiKey?.S_api?.length > 0) {
            FuturesPosition()
            const intervalId = setInterval(() => {
                FuturesPosition()
            }, 1000)
            return () => clearInterval(intervalId);
        }
    }, [login, By_apiKey])

    const FuturesPosition = async () => {
        const by_timestamp = localStorage.getItem('bybit_timestamp');
        if (by_timestamp) {
            const paramStr = `${by_timestamp}${By_apiKey.S_api}${10000}category=linear&settleCoin=USDT`
            await axios.get(`https://api.bybit.com/v5/position/list?category=linear&settleCoin=USDT`, {
                headers: {
                    "X-BAPI-SIGN": CryptoJS.HmacSHA256(paramStr, By_apiKey.S_sec).toString(CryptoJS.enc.Hex),
                    "X-BAPI-API-KEY": By_apiKey.S_api,
                    "X-BAPI-TIMESTAMP": by_timestamp,
                    "X-BAPI-RECV-WINDOW": 10000
                }
            }).then((response) => {
                let tmp = response.data
                if (tmp.retCode == 10004 || tmp.retCode == 10001) {
                    FuturesPosition()
                }
                else if (tmp.retCode == 0) {
                    bybit_position = tmp?.result?.list ?? []
                    dispatch(change_Bybit_position(tmp?.result?.list ?? []))
                }
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 110.1, "msg": error }))
            })
        }
        else {
            setTimeout(() => {
                FuturesPosition()
            }, 1000)
        }
    }

    useEffect(() => {
        const by_timestamp = localStorage.getItem('bybit_timestamp');
        let reconnectTimeout = null; // 재연결을 위한 타이머 변수
        let pingInterval = null; // Ping 메시지를 위한 setInterval 변수
        let socket = null; // WebSocket 객체

        const connectSocket = () => {
            if (login && by_timestamp && By_apiKey?.S_api?.length > 0) {
                socket = new WebSocket(`wss://stream.bybit.com/v5/private`);

                const generateSignature = (apiSecret, expires) => {
                    const paramStr = `GET/realtime${expires}`;
                    return CryptoJS.HmacSHA256(paramStr, apiSecret).toString(CryptoJS.enc.Hex);
                };

                const expires = Number(by_timestamp) + 100000;
                const signature = generateSignature(By_apiKey.S_sec, expires);

                const loginMessage = {
                    op: "auth",
                    args: [By_apiKey.S_api, expires.toString(), signature]
                };

                const subscribeMessage = {
                    op: "subscribe",
                    args: ["position"]
                };

                socket.onopen = () => {
                    socket.send(JSON.stringify(loginMessage));

                    pingInterval = setInterval(() => {
                        if (socket.readyState === WebSocket.OPEN) {
                            socket.send(JSON.stringify({ "op": "ping" }));
                        }
                    }, 25000); // 25초마다 핑 메시지

                    console.log("WebSocket connected");
                };

                socket.onmessage = function (event) {
                    let tmp = JSON.parse(event.data);
                    if (tmp?.op !== "pong") {
                        if (tmp?.op === "auth" && tmp.success) {
                            socket.send(JSON.stringify(subscribeMessage));
                        } else if (tmp.topic === "position") {
                            if (tmp.data[0].positionStatus === "Liq" && Alert[1] === "ON") {
                                playAudio3();
                            } else if (tmp?.data.length === 1 && Alert[2] === "ON") {
                                playAudio();
                                if (tmp?.data[0]?.adlRankIndicator === 0) {
                                    let arr = bybit_position.filter((item) => item.symbol === tmp.data[0].symbol);
                                    if (arr.length > 0 && Alert[1] === "ON") {
                                        if (arr[0]?.unrealisedPnl > 0) {
                                            playAudio1();
                                        } else if (arr[0]?.unrealisedPnl < 0) {
                                            playAudio2();
                                        } else if (arr[0]?.unrealisedPnl === 0) {
                                            playAudio4();
                                        }
                                    }
                                }
                            }
                        }
                    }
                };

                socket.onclose = function () {
                    console.log("WebSocket disconnected, attempting to reconnect...");
                    clearInterval(pingInterval); // 기존 ping interval 정리
                    if (!reconnectTimeout) {
                        reconnectTimeout = setTimeout(() => {
                            reconnectTimeout = null; // 타이머 초기화
                            connectSocket(); // 5초 후 재연결 시도
                        }, 3000); // 3초 후 재연결 시도
                    }
                };

                socket.onerror = function (error) {
                    console.log("WebSocket error:", error);
                    socket?.close(); // 에러 발생 시 소켓 닫고 재연결 시도
                };
            }
        };

        connectSocket(); // WebSocket 연결 시작

        return () => {
            socket?.close(); // 컴포넌트가 언마운트될 때 소켓 닫기
            clearInterval(pingInterval); // ping interval 해제
            if (reconnectTimeout) clearTimeout(reconnectTimeout); // 재연결 타이머 해제
        };
    }, [login, close, By_apiKey]);

    return (
        <div id='Bybit_Position'>
            {
                isPlaying
                    ?
                    <audio autoPlay={isPlaying}>
                        <source src="/audio/Sound.6.mp3" type="audio/mp3" />
                    </audio>
                    :
                    ""
            }
            {
                isPlaying1
                    ?
                    <audio autoPlay={isPlaying1}>
                        <source src="/audio/Sound.3.mp3" type="audio/mp3" />
                    </audio>
                    :
                    ""
            }
            {
                isPlaying2
                    ?
                    <audio autoPlay={isPlaying2}>
                        <source src="/audio/Sound.4.mp3" type="audio/mp3" />
                    </audio>
                    :
                    ""

            }
            {
                isPlaying3
                    ?
                    <audio autoPlay={isPlaying3}>
                        <source src="/audio/Sound.5.mp3" type="audio/mp3" />
                    </audio>
                    :
                    ""
            }
            {
                isPlaying4
                    ?
                    <audio autoPlay={isPlaying4}>
                        <source src="/audio/Sound.2.mp3" type="audio/mp3" />
                    </audio>
                    :
                    ""
            }
        </div>
    )
}

function Binance_Name({ }) {
    let dispatch = useDispatch()
    useEffect(() => {
        BinanceName()
    }, [])

    const BinanceName = async () => {
        await axios.get(process.env.REACT_APP_API + `/api/v1/market/bn/get/coin-info`, {
        }).then((response) => {
            let tmp = response?.data
            if (tmp) {
                bn_name = tmp
            }
        }).catch((error) => {
            // dispatch(check_Error({ "bol": true, "no": 4, "msg": error }))
        })
    }

    return (
        <div id='OKX_Instruments'></div>
    )
}

function OKX_Instruments({ setInstrumentData }) {
    let dispatch = useDispatch()

    useEffect(() => {
        OKXName()
        FuturesInstruments()
    }, [])

    const FuturesInstruments = async () => {
        let arr = []
        await axios.get(process.env.REACT_APP_API + `/api/v1/market/okx/instruments/swap`
        ).then((response) => {
            let tmp = response?.data?.data
            if (tmp) {
                tmp.map(function (a, i) {
                    if (a.instId.includes('USDT')) {
                        arr.push({ instId: a.instId, ctVal: Number(a.ctVal) })
                    }
                })
                setInstrumentData(arr)
                dispatch(check_OKX_Instruments(arr))
            }
        }).catch((error) => {
            // dispatch(check_Error({ "bol": true, "no": 5, "msg": error }))
        })
    }

    const OKXName = async () => {
        await axios.get(process.env.REACT_APP_API + `/api/v1/market/okx/get/ccy-info`, {
        }).then((response) => {
            let tmp = response?.data
            if (tmp) {
                okx_name = tmp
            }
        }).catch((error) => {
            // dispatch(check_Error({ "bol": true, "no": 6, "msg": error }))
        })
    }

    return (
        <div id='OKX_Instruments'></div>
    )
}

function OKX_Spot({ }) {
    let OKX_S_favorite = useSelector((state) => { return state.OKX_S_favorite })

    const [OKXdataSpot, setOKXDataSpot] = useState([]);
    let dispatch = useDispatch()

    const getData = async () => {
        await axios.get(OKX_urlS)
            .then(response => {
                let tmp = response?.data?.data
                if (tmp) {
                    setOKXDataSpot(tmp)
                }
            }).catch(error => {
                if (error.response && error.response.status === 429) {
                }
                else {
                    // dispatch(check_Error({ "bol": true, "no": 7, "msg": error }))
                }
            });
    }

    useEffect(() => {
        getData()
        const intervalId = setInterval(() => {
            getData()
        }, 1000)
        return () => clearInterval(intervalId);
    }, [])

    useEffect(() => {
        if (OKXdataSpot) {
            let okx_spot_arr = []
            OKXdataSpot.map(function (a, i) {
                if (a.instId.includes('USDT')) {
                    if (a.volCcy24h > 0 || a.last > 0) {
                        let arr = a.instId.split('-')
                        let point = Math.max(findSmallest(a.last), Math.max(findSmallest(a.high24h), findSmallest(a.low24h)))
                        let name = Array.isArray(okx_name) ? okx_name.filter((item) => item.ccy == arr[0]) : []

                        if (arr[0] != "USDT") {
                            if (OKX_S_favorite && OKX_S_favorite.includes(arr[0])) {
                                okx_spot_arr.push({
                                    symbol: a.instId, ccy: arr[0], name: arr[0] + "/USDT", fullName: name && name[0] ? name[0].name : arr[0] + "USDT", priceChangePercent: (Number(a.last) - Number(a.open24h)) * 100 / Number(a.open24h),
                                    lastPrice: a.last, volume: Number(a.vol24h), highPrice: a.high24h, lowPrice: a.low24h, priceChange: (Number(a.last) - Number(a.open24h)), check: false, favorite: true, point: point, exch: "OKX"
                                })
                            }
                            else {
                                okx_spot_arr.push({
                                    symbol: a.instId, ccy: arr[0], name: arr[0] + "/USDT", fullName: name && name[0] ? name[0].name : arr[0] + "USDT", priceChangePercent: (Number(a.last) - Number(a.open24h)) * 100 / Number(a.open24h),
                                    lastPrice: a.last, volume: Number(a.vol24h), highPrice: a.high24h, lowPrice: a.low24h, priceChange: (Number(a.last) - Number(a.open24h)), check: false, favorite: false, point: point, exch: "OKX"
                                })
                            }
                        }
                    }
                }
            })
            dispatch(OKX_addCoinS(okx_spot_arr))
        }
    }, [OKXdataSpot])
    return (
        <div id='OKX_spot_api'></div>
    )
}

function OKX_Futures({ instrumentsData }) {
    let OKX_F_favorite = useSelector((state) => { return state.OKX_F_favorite })

    const [OKXdataFutures, setOKXDataFutures] = useState([]);
    let dispatch = useDispatch()

    const getData = async () => {
        await axios.get(OKX_urlF)
            .then(response => {
                let tmp = response?.data?.data
                if (tmp) {
                    setOKXDataFutures(tmp)
                }
            }).catch(error => {
                if (error.response && error.response.status === 429) {
                }
                else {
                    // dispatch(check_Error({ "bol": true, "no": 8, "msg": error }))
                }
            });
    }

    useEffect(() => {
        getData()
        const intervalId = setInterval(() => {
            getData()
        }, 1000)
        return () => clearInterval(intervalId);
    }, [])

    useEffect(() => {
        if (OKXdataFutures) {
            let okx_futures_arr = []
            OKXdataFutures.map(function (a, i) {
                if (a.instId.includes('USDT')) {
                    if (a.volCcy24h > 0 || a.last > 0) {
                        let arr = a.instId.split('-')
                        let point = Math.max(findSmallest(a.last), Math.max(findSmallest(a.high24h), findSmallest(a.low24h)))
                        let name = Array.isArray(okx_name) ? okx_name.filter((item) => item.ccy == arr[0]) : []

                        let ctVal = 1
                        if (instrumentsData) {
                            let inst = instrumentsData.filter((item) => item.instId == a.instId)
                            ctVal = inst && inst[0] ? inst[0].ctVal : 1
                        }

                        if (arr[0] != "USDT") {
                            if (OKX_F_favorite && OKX_F_favorite.includes(arr[0])) {
                                okx_futures_arr.push({
                                    symbol: a.instId, ccy: arr[0], name: arr[0] + "USDT", fullName: name && name[0] ? name[0].name : arr[0] + "USDT", priceChangePercent: (Number(a.last) - Number(a.open24h)) * 100 / Number(a.open24h),
                                    lastPrice: a.last, volume: Number(a.vol24h) * ctVal, highPrice: a.high24h, lowPrice: a.low24h, priceChange: (Number(a.last) - Number(a.open24h)), check: false, favorite: true, point: point, exch: "OKX"
                                })
                            }
                            else {
                                okx_futures_arr.push({
                                    symbol: a.instId, ccy: arr[0], name: arr[0] + "USDT", fullName: name && name[0] ? name[0].name : arr[0] + "USDT", priceChangePercent: (Number(a.last) - Number(a.open24h)) * 100 / Number(a.open24h),
                                    lastPrice: a.last, volume: Number(a.vol24h) * ctVal, highPrice: a.high24h, lowPrice: a.low24h, priceChange: (Number(a.last) - Number(a.open24h)), check: false, favorite: false, point: point, exch: "OKX"
                                })
                            }
                        }
                    }
                }
            })
            dispatch(OKX_addCoinF(okx_futures_arr))
        }
    }, [OKXdataFutures, instrumentsData])
    return (
        <div id='OKX_futures_api'></div>
    )
}

function OKX_Position() {
    let login = useSelector((state) => { return state.login.value });
    let OKX_timestamp = useSelector((state) => { return state.OKX_timestamp.value });
    let dispatch = useDispatch();
    const CryptoJS = require('crypto-js');

    const [timestamp, setTimestamp] = useState("")


    useEffect(() => {
        if (OKX_timestamp && OKX_timestamp.length > 0) {
            setTimestamp(OKX_timestamp)
        }
    }, [OKX_timestamp])

    // OKX 타임스탬프
    const Timestamp = async () => {
        let local_time = localStorage.getItem("okx_timestamp")
        if (local_time && JSON.parse(local_time).delay >= Date.now()) {
            setTimestamp(JSON.parse(local_time).time)
        }
        else {
            await axios.get("https://www.okx.com/api/v5/public/time", {
            }).then((response) => {
                let tmp = response?.data?.data
                if (tmp && tmp[0] && tmp[0].ts) {
                    localStorage.removeItem("okx_timestamp")
                    setTimestamp(String(Number(tmp[0].ts) / 1000))
                    let obj = {
                        time: String(Number(tmp[0].ts) / 1000),
                        delay: Date.now() + 20000
                    }
                    const objString = JSON.stringify(obj);
                    localStorage.setItem("okx_timestamp", objString)
                }
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 9, "msg": error }))
            })
        }
    }

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString && timestamp.length > 0 && login) {
            const obj = JSON.parse(objString);
            let token = obj.value;
            let payload = token.substring(token.indexOf('.') + 1, token.lastIndexOf('.'));
            let dec = base64.decode(payload);

            let pre_name = []
            let pre_data = []

            const loginMessage = {
                op: "login",
                args: [
                    {
                        apiKey: JSON.parse(dec).apiKey,
                        passphrase: JSON.parse(dec).passphrase,
                        timestamp: timestamp,
                        sign: CryptoJS.enc.Base64.stringify(CryptoJS.HmacSHA256(timestamp + 'GET' + '/users/self/verify', JSON.parse(dec).secretKey))
                    }
                ]
            }
            const subscribeMessage = {
                op: "subscribe",
                args: [
                    {
                        channel: "positions",
                        instType: "SWAP"
                    }, {
                        channel: "balance_and_position",
                    }
                ]
            }
            let socket = JSON.parse(dec).userId === 1 ? new WebSocket(`wss://wspap.okx.com:8443/ws/v5/private?brokerId=9999`) : new WebSocket(`wss://ws.okx.com:8443/ws/v5/private`);
            socket.onopen = function () {
                socket.send(JSON.stringify(loginMessage))
                const intervalId = setInterval(() => {
                    if (socket.readyState === WebSocket.OPEN) {
                        socket.send("ping");
                    }
                }, 25000)

                return () => {
                    clearInterval(intervalId)
                }
            }
            socket.onmessage = function (event) {
                if (event.data != "pong") {
                    let tmp = JSON.parse(event.data);
                    if (tmp.event === "login") {
                        socket.send(JSON.stringify(subscribeMessage))
                    }
                    if (tmp && tmp.arg && tmp.data && tmp.arg.channel == "positions") {
                        let arr = tmp.data

                        if (arr.length === 1) {
                            if (pre_name.includes(arr[0].instId)) {
                                if (arr && arr[0] && arr[0].adl && arr[0].adl.length > 0) {
                                    pre_data = arr
                                    dispatch(change_OKX_position(arr))
                                }
                                else {
                                    if (arr && arr[0]) {
                                        let ab = pre_name.filter((item) => item != arr[0].instId)
                                        let arr1 = pre_data.filter((item) => item.instId != arr[0].instId)
                                        pre_name = ab
                                        pre_data = arr1
                                        dispatch(change_OKX_position(arr1))
                                    }
                                }
                            }
                            else {
                                if (arr && arr[0] && arr[0].adl && arr[0].adl.length > 0) {
                                    pre_name.push(arr[0].instId)
                                    let arr1 = [...pre_data, arr[0]]
                                    dispatch(change_OKX_position(arr1))
                                }
                            }
                        }
                        else {
                            for (let i = 0; i < arr.length; i++) {
                                if (!pre_name.includes(arr[i].instId)) pre_name.push(arr[i].instId)
                            }
                            pre_data = arr
                            dispatch(change_OKX_position(arr))
                        }
                    }
                    else if (tmp && tmp.arg && tmp.data && tmp.arg.channel == "balance_and_position") {
                        let eventType = tmp.data[0].eventType
                        if (eventType == "liquidation") dispatch(change_OKX_liquidation())
                    }
                }
            }
            socket.onclose = function (event) {
                console.log("okx_postion close")
                Timestamp()
            };
            socket.error = function (error) {
                window.location.reload()
            }
            return () => {
                socket.close();
            }
        }
    }, [login, timestamp])
    return (
        <div id='OKX_Position'></div>
    )
}

function OKX_Wallet() {
    let login = useSelector((state) => { return state.login.value });
    let OKX_timestamp = useSelector((state) => { return state.OKX_timestamp.value });
    let dispatch = useDispatch();
    const CryptoJS = require('crypto-js');

    const [timestamp, setTimestamp] = useState("")

    useEffect(() => {
        if (OKX_timestamp && OKX_timestamp.length > 0) {
            setTimestamp(OKX_timestamp)
        }
    }, [OKX_timestamp])

    // OKX 타임스탬프
    const Timestamp = async () => {
        let local_time = localStorage.getItem("okx_timestamp")
        if (local_time && JSON.parse(local_time).delay >= Date.now()) {
            setTimestamp(JSON.parse(local_time).time)
        }
        else {
            await axios.get("https://www.okx.com/api/v5/public/time", {
            }).then((response) => {
                let tmp = response.data.data
                if (tmp && tmp[0] && tmp[0].ts) {
                    localStorage.removeItem("okx_timestamp")
                    setTimestamp(String(Number(tmp[0].ts) / 1000))
                    dispatch(checkOKX_timestamp(String(Number(tmp[0].ts) / 1000)))
                    let obj = {
                        time: String(Number(tmp[0].ts) / 1000),
                        delay: Date.now() + 20000
                    }
                    const objString = JSON.stringify(obj);
                    localStorage.setItem("okx_timestamp", objString)
                }
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 10, "msg": error }))
            })
        }
    }

    useEffect(() => {
        if (login) {
            const objString = localStorage.getItem('token');
            if (objString) {
                const obj = JSON.parse(objString);
                let token = obj.value;

                SpotBalance(token)
                SpotBalanceUSDT(token);
            }
        }
    }, [login])

    const SpotBalanceUSDT = async (token) => {
        await axios.get(process.env.REACT_APP_API + "/api/v1/wallet/okx/balance", {
            params: {
                ccy: "USDT"
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            let tmp = response?.data?.data
            if (tmp && tmp[0] && tmp[0].details && tmp[0].details[0]) {
                dispatch(change_OKX_wallet_usdt(tmp[0].details))
            }
        }).catch((error) => {
            dispatch(check_Error({ "bol": true, "no": 11, "msg": error }))
        })
    }

    const SpotBalance = async (token) => {
        await axios.get(process.env.REACT_APP_API + "/api/v1/wallet/okx/balance", {
            params: {
                ccy: ""
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            let tmp = response?.data?.data
            if (tmp && tmp[0] && tmp[0].details) {
                dispatch(change_OKX_wallet(tmp[0].details))
            }
        }).catch((error) => {
            dispatch(check_Error({ "bol": true, "no": 12, "msg": error }))
        })
    }

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString && timestamp.length > 0 && login) {
            const obj = JSON.parse(objString);
            let token = obj.value;
            let payload = token.substring(token.indexOf('.') + 1, token.lastIndexOf('.'));
            let dec = base64.decode(payload);

            const loginMessage = {
                op: "login",
                args: [
                    {
                        apiKey: JSON.parse(dec).apiKey,
                        passphrase: JSON.parse(dec).passphrase,
                        timestamp: timestamp,
                        sign: CryptoJS.enc.Base64.stringify(CryptoJS.HmacSHA256(timestamp + 'GET' + '/users/self/verify', JSON.parse(dec).secretKey))
                    }
                ]
            }
            const subscribeMessage = {
                op: "subscribe",
                args: [
                    {
                        channel: "account"
                    }
                ]
            }

            let socket = JSON.parse(dec).userId === 1 ? new WebSocket(`wss://wspap.okx.com:8443/ws/v5/private?brokerId=9999`) : new WebSocket(`wss://ws.okx.com:8443/ws/v5/private`);
            socket.onopen = function () {
                socket.send(JSON.stringify(loginMessage))
                const intervalId = setInterval(() => {
                    if (socket.readyState === WebSocket.OPEN) {
                        socket.send("ping");
                    }
                }, 25000)

                return () => {
                    clearInterval(intervalId)
                }
            }

            socket.onmessage = function (event) {
                let nextArr = []
                if (event.data != "pong") {
                    let tmp = JSON.parse(event.data);
                    if (tmp.event === "login") {
                        socket.send(JSON.stringify(subscribeMessage))
                    }
                    if (tmp && tmp.data && tmp.data[0] && tmp.data[0].details && tmp.data[0].details[0]) {
                        let detail = tmp.data[0].details

                        detail.map(function (a, i) {
                            nextArr.push({ symbol: a.ccy, size: a.availBal })
                        })

                        let ArraysEqual = isEqual(preArr, nextArr);
                        if (!ArraysEqual) {
                            dispatch(change_OKX_wallet(detail))
                        }
                        preArr = nextArr

                        let usdt = detail.filter((item) => item.ccy == "USDT")
                        if (usdt && usdt[0] && usdt[0].availBal) {
                            dispatch(change_OKX_wallet_usdt(usdt))
                        }
                    }
                }
            }
            socket.onclose = function (event) {
                if (cnt > 0) {
                    Timestamp()
                    console.log("OKX_wallet Close")
                }
                cnt += 1
            };
            socket.error = function (error) {
                window.location.reload()
            }
            return () => {
                socket.close();
            }
        }
    }, [login, timestamp])

    return (
        <div id='OKX_Wallet'></div>
    )
}

let bn_cnt = 0

function Binance_Wallet_Spot({ listenkey_spot }) {
    let Reload = useSelector((state) => { return state.Reload.value })
    let dispatch = useDispatch();

    const [close, setClose] = useState(false)

    useEffect(() => {
        if (bn_cnt > 0) {
            setClose(e => !e)
        }
    }, [Reload])

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString && listenkey_spot && listenkey_spot.length > 0) {
            let socket = null;
            let reconnectTimeout = null; // 재연결을 위한 타임아웃 변수
            let id = null; // 필요에 따라 추가된 setInterval 변수

            const connectSocket = () => {
                socket = new WebSocket(`wss://stream.binance.com/ws/${listenkey_spot}`);

                socket.onopen = () => {
                    console.log("WebSocket connected");
                    if (reconnectTimeout) {
                        clearTimeout(reconnectTimeout); // 성공적으로 연결되었으므로 기존 타임아웃 정리
                        reconnectTimeout = null; // 타임아웃 초기화
                    }
                };

                socket.onmessage = function (event) {
                    let tmp = JSON.parse(event.data);
                    if (tmp && tmp.e === "outboundAccountPosition") {
                        if (tmp.B && tmp.B.length > 0) {
                            let usdt = tmp.B.filter((item) => item.a === "USDT");
                            dispatch(change_Binance_wallet_spot(usdt));
                        }
                    }
                };

                socket.onclose = function () {
                    console.log("WebSocket disconnected, attempting to reconnect...");
                    if (!reconnectTimeout) {
                        reconnectTimeout = setTimeout(() => {
                            console.log('Reconnecting...');
                            connectSocket(); // 5초 후 재연결 시도
                        }, 3000); // 3초 간격으로 재연결 시도
                    }
                };

                socket.onerror = function (error) {
                    console.log('WebSocket error:', error);
                    socket?.close(); // 에러 발생 시 소켓을 닫고 재연결 시도
                };
            };

            connectSocket(); // WebSocket 연결 시작

            return () => {
                socket?.close(); // 컴포넌트가 언마운트될 때 소켓 닫기
                if (reconnectTimeout) clearTimeout(reconnectTimeout); // 타임아웃 해제
                if (id) clearInterval(id); // 필요시 setInterval 해제
            };
        }
    }, [listenkey_spot, close]);

    return (
        <div id='Binance_Wallet_spot'></div>
    )
}

function Binance_Wallet_Futures({ listenkey_futures }) {
    let Reload = useSelector((state) => { return state.Reload.value })
    const CryptoJS = require('crypto-js');
    let login = useSelector((state) => { return state.login.value });
    let dispatch = useDispatch();

    const [close, setClose] = useState(false)

    useEffect(() => {
        if (bn_cnt > 0) {
            setClose(e => !e)
        }
    }, [Reload])

    useEffect(() => {
        if (login) {
            const objString = localStorage.getItem('token');
            if (objString) {
                const obj = JSON.parse(objString);
                let token = obj.value;

                B_FuturesBalanceUSDT(token);
            }
        }
    }, [login])

    const B_FuturesBalanceUSDT = async (token) => {
        await axios.get(process.env.REACT_APP_API + '/api/v1/wallet/bn/futures/info', {
            headers: { Authorization: `Bearer ${token}` }
        }).then((response) => {
            let tmp = response?.data?.data
            if (tmp) {
                if (tmp.length) {
                    for (let i = 0; i < tmp.length; i++) {
                        if (tmp[i].asset == "USDT") {
                            dispatch(change_Binance_wallet_futures([tmp[i]]))
                        }
                    }
                }
            }
        }).catch((error) => {
            dispatch(check_Error({ "bol": true, "no": 13, "msg": error }))
        })
    }

    let Alert = useSelector((state) => { return state.Alert });

    const [isPlaying, setIsPlaying] = useState(false);
    const [isPlaying1, setIsPlaying1] = useState(false);
    const [isPlaying2, setIsPlaying2] = useState(false);
    const [isPlaying3, setIsPlaying3] = useState(false);
    const [isPlaying4, setIsPlaying4] = useState(false);

    const playAudio = () => {
        setIsPlaying(true);
        setTimeout(() => {
            setIsPlaying(false);
        }, 1000)
    };

    const playAudio1 = () => {
        setIsPlaying1(true);
        setTimeout(() => {
            setIsPlaying1(false);
        }, 1000)
    };

    const playAudio2 = () => {
        setIsPlaying2(true);
        setTimeout(() => {
            setIsPlaying2(false);
        }, 1000)
    };

    const playAudio3 = () => {
        setIsPlaying3(true);
        setTimeout(() => {
            setIsPlaying3(false);
        }, 1000)
    };

    const playAudio4 = () => {
        setIsPlaying4(true);
        setTimeout(() => {
            setIsPlaying4(false);
        }, 1000)
    };

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString) {
            const obj = JSON.parse(objString);
            let token = obj.value;
            let payload = token.substring(token.indexOf('.') + 1, token.lastIndexOf('.'));
            let dec = base64.decode(payload);

            let socket = null;
            let reconnectTimeout = null; // 재연결 타이머
            let id = null; // setInterval 타이머

            // Binance 서버 타임스탬프 가져오기
            const Bn_timestamp = async () => {
                try {
                    const response = await axios.get('https://api.binance.com/api/v3/time');
                    let tmp = response.data;
                    if (tmp) {
                        return tmp.serverTime; // 서버 시간을 반환
                    }
                } catch (error) {
                    console.error(error);
                }
                return null; // 오류 발생 시 null 반환
            };
            
            const connectSocket = () => {
                socket = new WebSocket(`wss://ws-fapi.binance.com/ws-fapi/v1`);

                socket.onopen = () => {
                    console.log("WebSocket connected");

                    // 1초마다 타임스탬프를 받아와서 WebSocket으로 전송
                    id = setInterval(async () => {
                        const timet = await Bn_timestamp(); // 서버 시간 호출
                        
                        if (!timet) {
                            console.error("Failed to retrieve server time.");
                            return; // 서버 시간을 받아오지 못하면 실행 중단
                        }

                        let params = { apiKey: JSON.parse(dec).bnApiKey };
                        const queryString = Object.keys(params)
                            .map((key) => `${key}=${params[key]}`)
                            .join("&");
                        const signatureMessage = `${queryString === "" ? "" : queryString + "&"}timestamp=${timet}`;
                        const signature = CryptoJS.HmacSHA256(signatureMessage, JSON.parse(dec).bnSecretKey).toString();

                        socket.send(JSON.stringify({
                            method: "v2/account.balance",
                            // method: ["v2/account.balance", "v2/account.status"],
                            params: {
                                apiKey: JSON.parse(dec).bnApiKey,
                                timestamp: timet,
                                signature: signature
                            },
                            id: 123
                        }));

                        socket.send(JSON.stringify({
                            method: "account.position",
                            params: {
                                apiKey: JSON.parse(dec).bnApiKey,
                                timestamp: timet,
                                signature: signature
                            },
                            id: 124
                        }));

                    }, 1000); // 1초마다 실행
                };

                socket.onmessage = function (event) {
                    let tmp = JSON.parse(event.data);
                    if (tmp?.id === 123) {
                        if (tmp?.result) {
                            let usdt = tmp?.result.filter(i => i.asset === "USDT");
                            dispatch(change_Binance_wallet_futures(usdt));
                        }
                    }
                    if (tmp?.id === 124) {
                        if (tmp?.result && tmp.result.length > 0) {
                            let arr = tmp.result;
                            dispatch(change_Binance_position(arr));
                        }
                    }
                };

                socket.onclose = function () {
                    console.log('WebSocket disconnected, attempting to reconnect...');
                    clearInterval(id); // 기존의 setInterval 정리
                    if (!reconnectTimeout) {
                        reconnectTimeout = setTimeout(() => {
                            reconnectTimeout = null; // 재연결 후 타이머 초기화
                            console.log('Reconnecting...');
                            connectSocket(); // 재연결 시도
                        }, 3000); // 3초 후 재연결 시도
                    }
                };

                socket.onerror = function (error) {
                    console.log('WebSocket error:', error);
                    socket?.close();
                };
            };

            connectSocket();

            return () => {
                socket?.close(); // 컴포넌트 언마운트 시 소켓 닫기
                clearInterval(id); // setInterval 해제
                if (reconnectTimeout) clearTimeout(reconnectTimeout); // 타이머 해제
            };
        }
    }, [close]);

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString && listenkey_futures && listenkey_futures.length > 0) {
            let socket = null;
            let reconnectTimeout = null; // 재연결 타이머
            let id = null; // setInterval 타이머

            const connectSocket = () => {
                socket = new WebSocket(`wss://fstream.binance.com/ws/${listenkey_futures}`);

                socket.onopen = () => {
                    console.log('WebSocket connected');
                };

                socket.onmessage = function (event) {
                    let tmp = JSON.parse(event.data);
                    if (tmp && tmp.e) {
                        if (tmp.e === "ORDER_TRADE_UPDATE") {
                            if (Number(tmp.o?.rp) !== 0) {
                                if (Number(tmp.o?.rp) > 0 && Alert[1] === "ON") playAudio1();
                                else if (Number(tmp.o?.rp) < 0 && Alert[1] === "ON") playAudio2();
                            } else if (tmp.o?.o === "LIQUIDATION") {
                                if (Alert[1] === "ON") playAudio3();
                            } else if (tmp.o?.X === "NEW") {
                                if (Alert[2] === "ON") playAudio();
                            } else if (tmp.o?.X === "FILLED") {
                                if (Alert[1] === "ON") playAudio4();
                            }
                            dispatch(change_Binance_openorder_futures(tmp));
                        }
                    }
                };

                socket.onclose = function () {
                    console.log('WebSocket disconnected, attempting to reconnect...');
                    clearInterval(id); // 기존의 setInterval 정리
                    if (!reconnectTimeout) {
                        reconnectTimeout = setTimeout(() => {
                            reconnectTimeout = null; // 재연결 후 타이머 초기화
                            console.log('Reconnecting...');
                            connectSocket(); // 재연결 시도
                        }, 3000); // 3초 후 재연결 시도
                    }
                };

                socket.onerror = function (error) {
                    console.log('WebSocket error:', error);
                    socket?.close();
                };
            };

            connectSocket();

            return () => {
                socket?.close(); // 컴포넌트 언마운트 시 소켓 닫기
                clearInterval(id); // setInterval 해제
                if (reconnectTimeout) clearTimeout(reconnectTimeout); // 타이머 해제
            };
        }
    }, [listenkey_futures, close]);


    return (
        <div id='Binance_Wallet_futures'>
            {
                isPlaying
                    ?
                    <audio autoPlay={isPlaying}>
                        <source src="/audio/Sound.6.mp3" type="audio/mp3" />
                    </audio>
                    :
                    ""
            }
            {
                isPlaying1
                    ?
                    <audio autoPlay={isPlaying1}>
                        <source src="/audio/Sound.3.mp3" type="audio/mp3" />
                    </audio>
                    :
                    ""
            }
            {
                isPlaying2
                    ?
                    <audio autoPlay={isPlaying2}>
                        <source src="/audio/Sound.4.mp3" type="audio/mp3" />
                    </audio>
                    :
                    ""

            }
            {
                isPlaying3
                    ?
                    <audio autoPlay={isPlaying3}>
                        <source src="/audio/Sound.5.mp3" type="audio/mp3" />
                    </audio>
                    :
                    ""
            }
            {
                isPlaying4
                    ?
                    <audio autoPlay={isPlaying4}>
                        <source src="/audio/Sound.2.mp3" type="audio/mp3" />
                    </audio>
                    :
                    ""
            }
        </div>
    )
}

// function Binance_Position({ listenkey_futures }) {
//     const CryptoJS = require('crypto-js');
//     let Reload = useSelector((state) => { return state.Reload.value })
//     let dispatch = useDispatch();

//     const [close, setClose] = useState(false)

//     useEffect(() => {
//         if (bn_cnt > 0) {
//             setClose(e => !e)
//         }
//         bn_cnt += 1
//     }, [Reload])

//     useEffect(() => {
//         const objString = localStorage.getItem('token');
//         if (objString && listenkey_futures && listenkey_futures.length > 0) {
//             const obj = JSON.parse(objString);
//             let token = obj.value;
//             let payload = token.substring(token.indexOf('.') + 1, token.lastIndexOf('.'));
//             let dec = base64.decode(payload);

//             let socket = null;
//             let reconnectTimeout = null; // 재연결 타임아웃 변수
//             let id = null;

//             // Binance 서버 타임스탬프 가져오기
//             const Bn_timestamp = async () => {
//                 try {
//                     const response = await axios.get('https://api.binance.com/api/v3/time');
//                     let tmp = response.data;
//                     if (tmp) {
//                         return tmp.serverTime; // 서버 시간을 반환
//                     }
//                 } catch (error) {
//                     console.error(error);
//                 }
//                 return null; // 오류 발생 시 null 반환
//             };

//             const connectSocket = () => {
//                 socket = new WebSocket(`wss://ws-fapi.binance.com/ws-fapi/v1`);

//                 socket.onopen = () => {
//                     console.log("WebSocket connected");

//                     if (reconnectTimeout) {
//                         clearTimeout(reconnectTimeout); // 재연결 성공 시 타이머 해제
//                         reconnectTimeout = null; // 타이머 초기화
//                     }

//                     // 1초마다 타임스탬프를 받아와서 WebSocket으로 전송
//                     id = setInterval(async () => {
//                         const timet = await Bn_timestamp(); // 서버 시간 호출

//                         if (!timet) {
//                             console.error("Failed to retrieve server time.");
//                             return; // 서버 시간을 받아오지 못하면 실행 중단
//                         }

//                         let params = { apiKey: JSON.parse(dec).bnApiKey };
//                         const queryString = Object.keys(params)
//                             .map((key) => `${key}=${params[key]}`)
//                             .join("&");
//                         const signatureMessage = `${queryString === "" ? "" : queryString + "&"}timestamp=${timet}`;
//                         const signature = CryptoJS.HmacSHA256(signatureMessage, JSON.parse(dec).bnSecretKey).toString();

//                         socket.send(JSON.stringify({
//                             method: "account.position",
//                             params: {
//                                 apiKey: JSON.parse(dec).bnApiKey,
//                                 timestamp: timet,
//                                 signature: signature
//                             },
//                             id: 123
//                         }));
//                     }, 1000); // 1초마다 실행
//                 };

//                 socket.onmessage = function (event) {
//                     let tmp = JSON.parse(event.data);
//                     if (tmp && tmp.id === 123) {
//                         if (tmp?.result && tmp.result.length > 0) {
//                             let arr = tmp.result;
//                             // dispatch(change_Binance_position(arr));
//                         }
//                     }
//                 };

//                 socket.onclose = function () {
//                     console.log('WebSocket disconnected, attempting to reconnect...');
//                     clearInterval(id); // setInterval 정리
//                     if (!reconnectTimeout) {
//                         reconnectTimeout = setTimeout(() => {
//                             connectSocket(); // 3초 후 재연결 시도
//                         }, 3000);
//                     }
//                 };

//                 socket.onerror = function (error) {
//                     console.log('WebSocket error:', error);
//                     socket?.close(); // 에러 발생 시 소켓 닫고 재연결
//                 };
//             };

//             connectSocket(); // 소켓 연결 시작

//             return () => {
//                 socket?.close(); // 컴포넌트 언마운트 시 소켓 닫기
//                 if (reconnectTimeout) clearTimeout(reconnectTimeout); // 타임아웃 해제
//                 clearInterval(id); // setInterval 정리
//             };
//         }
//     }, [listenkey_futures, close]);



//     return (
//         <div id='Binance_Position'></div>
//     )
// }

let exinfo_futures = []
let exinfo_spot = []

function Binance_Spot({ }) {
    let Reload = useSelector((state) => { return state.Reload.value })
    let Binance_S_favorite = useSelector((state) => { return state.Binance_S_favorite })

    const [BinancedataSpot, setBinanceDataSpot] = useState([]);

    let dispatch = useDispatch()

    const getData = async () => {
        const resultS = await axios.get(Binance_urlS);
        setBinanceDataSpot(resultS.data)
    }

    useEffect(() => {
        ExchangeInformation_Spot()
        getData()
    }, [])

    const ExchangeInformation_Spot = async () => {
        let arr = []
        await axios.get('https://api.binance.com/api/v3/exchangeInfo'
        ).then((response) => {
            let tmp = response?.data?.symbols
            if (tmp) {
                tmp.map(function (a, i) {
                    if (a.symbol.includes('USDT') && a.status == "TRADING") arr.push(a.symbol)
                })
            }
        }).catch((error) => {
            // dispatch(check_Error({ "bol": true, "no": 16, "msg": error }))
        })
        exinfo_spot = arr
    }

    useEffect(() => {
        BinancedataSpot.map(function (a, i) {
            if (a.symbol.includes('USDT')) {
                if (a.volume > 0 || a.lastPrice > 0) {
                    let arr = a.symbol.split('USDT')
                    let point = Math.max(findSmallest(a.lastPrice), Math.max(findSmallest(a.highPrice), findSmallest(a.lowPrice)))
                    // let name = []
                    let name = bn_name.filter((item) => item.ccy == arr[0])

                    if (!arr[1] && exinfo_spot.includes(a.symbol)) {
                        if (Binance_S_favorite && Binance_S_favorite.includes(arr[0])) {
                            dispatch(Binance_addCoinS({
                                symbol: a.symbol, ccy: arr[0], name: arr[0] + "/USDT", fullName: name && name[0] ? name[0].name : arr[0] + "USDT", priceChangePercent: a.priceChangePercent, lastPrice: a.lastPrice, volume: a.volume
                                , highPrice: a.highPrice, lowPrice: a.lowPrice, priceChange: a.priceChange, check: false, favorite: true, point: point, exch: "Binance"
                            }))
                        }
                        else {
                            dispatch(Binance_addCoinS({
                                symbol: a.symbol, ccy: arr[0], name: arr[0] + "/USDT", fullName: name && name[0] ? name[0].name : arr[0] + "USDT", priceChangePercent: a.priceChangePercent, lastPrice: a.lastPrice, volume: a.volume
                                , highPrice: a.highPrice, lowPrice: a.lowPrice, priceChange: a.priceChange, check: false, favorite: false, point: point, exch: "Binance"
                            }))
                        }
                    }
                }
            }
        })
    }, [BinancedataSpot, Binance_S_favorite])

    useEffect(() => {
        let socket = null;
        let reconnectTimeout = null; // 재연결 타임아웃 변수

        const connectSocket = () => {
            socket = new WebSocket(`wss://stream.binance.com:9443/ws/!ticker@arr`);

            socket.onmessage = function (event) {
                let tmp = JSON.parse(event.data);
                tmp.map(function (a, i) {
                    if (a.s.includes('USDT')) {
                        if (a.v > 0 || a.c > 0) {
                            let arr = a.s.split('USDT');
                            if (!arr[1] && exinfo_spot.includes(a.s)) {
                                let point = Math.max(findSmallest(a.c), Math.max(findSmallest(a.h), findSmallest(a.l)));
                                let name = bn_name.filter((item) => item.ccy == arr[0]);
                                let priceChangePercent = (a.c - a.o) * 100 / a.o;

                                if (Binance_S_favorite && Binance_S_favorite.includes(arr[0])) {
                                    dispatch(Binance_addCoinS({
                                        symbol: a.s, ccy: arr[0], name: arr[0] + "/USDT", fullName: name && name[0] ? name[0].name : arr[0] + "USDT", priceChangePercent: priceChangePercent, lastPrice: a.c, volume: a.v,
                                        highPrice: a.h, lowPrice: a.l, priceChange: a.p, check: false, favorite: true, point: point, exch: "Binance"
                                    }));
                                } else {
                                    dispatch(Binance_addCoinS({
                                        symbol: a.s, ccy: arr[0], name: arr[0] + "/USDT", fullName: name && name[0] ? name[0].name : arr[0] + "USDT", priceChangePercent: priceChangePercent, lastPrice: a.c, volume: a.v,
                                        highPrice: a.h, lowPrice: a.l, priceChange: a.p, check: false, favorite: false, point: point, exch: "Binance"
                                    }));
                                }
                            }
                        }
                    }
                });
            };

            socket.onclose = function () {
                // console.log("WebSocket disconnected, attempting to reconnect...");
            };

            socket.onerror = function (error) {
                console.log("WebSocket error:", error);
                socket?.close(); // 에러 발생 시 소켓을 닫고 재연결 시도
            };
        };

        connectSocket(); // 소켓 연결 시작

        return () => {
            socket?.close(); // 컴포넌트가 언마운트될 때 소켓 닫기
            if (reconnectTimeout) clearTimeout(reconnectTimeout); // 재연결 타이머 해제
        };
    }, [Binance_S_favorite]);

    return (
        <div id='Binance_spot_api'></div>
    )
}

function Binance_Futures({ }) {
    let Reload = useSelector((state) => { return state.Reload.value })
    let Binance_F_favorite = useSelector((state) => { return state.Binance_F_favorite })

    const [BinancedataFutures, setBinanceDataFutures] = useState([]);
    let dispatch = useDispatch()

    const getData = async () => {
        const resultF = await axios.get(Binance_urlF);
        setBinanceDataFutures(resultF.data)
    }

    useEffect(() => {
        ExchangeInformation_Futures()
        getData()
    }, [])

    const ExchangeInformation_Futures = async () => {
        let arr = []
        await axios.get('https://fapi.binance.com/fapi/v1/exchangeInfo'
        ).then((response) => {
            let tmp = response?.data?.symbols
            if (tmp) {
                tmp.map(function (a, i) {
                    if (a.symbol.includes('USDT') && a.status == "TRADING") arr.push(a.symbol)
                })
            }
        }).catch((error) => {
            console.log(error)
            // dispatch(check_Error({ "bol": true, "no": 17, "msg": error }))
        })
        exinfo_futures = arr
    }

    useEffect(() => {
        BinancedataFutures.map(function (a, i) {
            if (a.symbol.includes('USDT')) {
                if (a.volume > 0 || a.lastPrice > 0) {
                    let arr = a.symbol.split('USDT')
                    if (!arr[1] && exinfo_futures.includes(a.symbol)) {
                        let point = Math.max(findSmallest(a.lastPrice), Math.max(findSmallest(a.highPrice), findSmallest(a.lowPrice)))
                        // let name = []
                        let name = bn_name ? bn_name.filter((item) => item.ccy == arr[0]) : []

                        if (Binance_F_favorite && Binance_F_favorite.includes(arr[0])) {
                            dispatch(Binance_addCoinF({
                                symbol: a.symbol, ccy: arr[0], name: arr[0] + "USDT", fullName: name && name[0] ? name[0].name : arr[0] + "USDT", priceChangePercent: a.priceChangePercent, lastPrice: a.lastPrice, volume: a.volume
                                , highPrice: a.highPrice, lowPrice: a.lowPrice, priceChange: a.priceChange, check: false, favorite: true, point: point, exch: "Binance"
                            }))
                        }
                        else {
                            dispatch(Binance_addCoinF({
                                symbol: a.symbol, ccy: arr[0], name: arr[0] + "USDT", fullName: name && name[0] ? name[0].name : arr[0] + "USDT", priceChangePercent: a.priceChangePercent, lastPrice: a.lastPrice, volume: a.volume
                                , highPrice: a.highPrice, lowPrice: a.lowPrice, priceChange: a.priceChange, check: false, favorite: false, point: point, exch: "Binance"
                            }))
                        }
                    }
                }
            }
        })
    }, [BinancedataFutures, Binance_F_favorite])

    useEffect(() => {
        let socket = null;
        let reconnectTimeout = null; // 재연결 타임아웃 변수

        const connectSocket = () => {
            socket = new WebSocket(`wss://fstream.binance.com/ws/!ticker@arr`);

            socket.onmessage = function (event) {
                let tmp = JSON.parse(event.data);
                tmp.map(function (a, i) {
                    if (a.s.includes('USDT')) {
                        if (a.v > 0 || a.c > 0) {
                            let arr = a.s.split('USDT');
                            if (!arr[1] && exinfo_futures.includes(a.s)) {
                                let point = Math.max(findSmallest(a.c), Math.max(findSmallest(a.h), findSmallest(a.l)));
                                let name = bn_name ? bn_name.filter((item) => item.ccy === arr[0]) : [];
                                let priceChangePercent = (a.c - a.o) * 100 / a.o;

                                if (Binance_F_favorite && Binance_F_favorite.includes(arr[0])) {
                                    dispatch(Binance_addCoinF({
                                        symbol: a.s, ccy: arr[0], name: arr[0] + "USDT", fullName: name && name[0] ? name[0].name : arr[0] + "USDT", priceChangePercent: priceChangePercent, lastPrice: a.c, volume: a.v,
                                        highPrice: a.h, lowPrice: a.l, priceChange: a.p, check: false, favorite: true, point: point, exch: "Binance"
                                    }));
                                } else {
                                    dispatch(Binance_addCoinF({
                                        symbol: a.s, ccy: arr[0], name: arr[0] + "USDT", fullName: name && name[0] ? name[0].name : arr[0] + "USDT", priceChangePercent: priceChangePercent, lastPrice: a.c, volume: a.v,
                                        highPrice: a.h, lowPrice: a.l, priceChange: a.p, check: false, favorite: false, point: point, exch: "Binance"
                                    }));
                                }
                            }
                        }
                    }
                });
            };

            socket.onclose = function () {
                // console.log("WebSocket disconnected, attempting to reconnect...");
            };

            socket.onerror = function (error) {
                console.log("WebSocket error:", error);
                socket?.close(); // 에러 발생 시 소켓 닫고 재연결 시도
            };
        };

        connectSocket(); // WebSocket 연결 시작

        return () => {
            socket?.close(); // 컴포넌트가 언마운트될 때 소켓 닫기
            if (reconnectTimeout) clearTimeout(reconnectTimeout); // 재연결 타임아웃 해제
        };
    }, [Binance_F_favorite]);

    return (
        <div id='Binance_futures_api'></div>
    )
}

export default Api;