import '../../css/futures.css'
import '../../css/modal.css'
import axios from 'axios';
import base64 from 'base-64';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCrown, faSquareCaretDown, faCircleXmark, faSquareCaretUp } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import Modal from '../../message/modal'
import { useTranslation } from "react-i18next";
import { change_Bn_openorder, checkAlert, check_Error } from '../../../store';
import { findSmallest, MakeNumber, changeNum, RoundDown, changeTime, padZero, makeSamllest, changeUnits, UTCTimestamp } from "../../common/function"
import { ClipLoader } from "react-spinners";
import { previousDay } from 'date-fns/fp';

function BFsection8({ tab5, setTab5, symbol, windowtypeM, USDTSymbolF, setPositionAmount, exinfo, positionData, setTradeVolume, tradeVolume, totalexinfo, currentPrice, checkCurrentPosition, setOrderReload }) {
    const { t } = useTranslation();
    let dispatch = useDispatch();

    let dark = useSelector((state) => { return state.Darkmode.value });
    let login = useSelector((state) => { return state.login.value });
    let Alert = useSelector((state) => { return state.Alert });
    let Binance_openorder_futures = useSelector((state) => { return state.Binance_openorder_futures });

    const darkname = dark ? 'dark' : "";

    const [positionbtn, setPositionbtn] = useState(true)
    const [openorder, setOpenorder] = useState([])
    const [message, setMessage] = useState([])
    const [qtypoint, setQtypoint] = useState(2)

    const [delay, setDelay] = useState(true)
    const [openorder_cancel, setOpenorder_cancel] = useState(true)

    useEffect(() => {
        if (exinfo[1]) {
            let point = findSmallest(Number(exinfo[1].minQty))
            setQtypoint(point)
        }
    }, [exinfo])

    useEffect(() => {
        if (login) {
            FuturesAllOpenOrder();
        }
    }, [login])

    const Bn_OpenOrder = (arr) => {
        dispatch(change_Bn_openorder(arr))
    }

    useEffect(() => {
        if (Binance_openorder_futures && Binance_openorder_futures.o) {
            let arr = [...openorder];
            let tmp = Binance_openorder_futures.o

            if (tmp.X == "NEW") {
                let fil = arr.filter((item) => item.orderId != tmp.i)
                fil.push({
                    orderId: tmp.i, symbol: tmp.s, type: tmp.o, price: tmp.o.toUpperCase() != "LIMIT" && tmp.o.toUpperCase() != "MARKET" ? tmp.sp : tmp.p,
                    executedQty: tmp.z, origQty: tmp.q, side: tmp.S, reduceOnly: tmp.R, timeInForce: tmp.f
                })
                setOpenorder(fil)
                Bn_OpenOrder(fil)
            }
            else if (tmp.X == "PARTIALLY_FILLED") {
                let arr1 = []
                let fil = arr.filter((item) => item.orderId != tmp.i)

                fil.map(function (a, i) {
                    arr1.push({
                        orderId: a.orderId, symbol: a.symbol, type: a.type, price: a.type.toUpperCase() != "LIMIT" && a.type.toUpperCase() != "MARKET" ? a.stopPrice : a.price,
                        executedQty: a.executedQty, origQty: a.origQty, side: a.side, reduceOnly: a.reduceOnly, timeInForce: a.timeInForce
                    })
                })
                arr1.push({
                    orderId: tmp.i, symbol: tmp.s, type: tmp.o, price: tmp.o.toUpperCase() != "LIMIT" && tmp.o.toUpperCase() != "MARKET" ? tmp.sp : tmp.p,
                    executedQty: tmp.z, origQty: tmp.q, side: tmp.S, reduceOnly: tmp.R, timeInForce: tmp.f
                })

                setOpenorder(arr1)
                Bn_OpenOrder(arr1)
            }
            else if (tmp.X == "CANCELED" || tmp.X == "EXPIRED") {
                let fil = arr.filter((item) => item.symbol != tmp.s)
                FuturesOpenOrder(fil, tmp.s);
            }
            else {
                let fil = arr.filter((item) => item.orderId != tmp.i)
                setOpenorder(fil)
                Bn_OpenOrder(fil)
            }
            setOrderReload(e => !e)
        }
    }, [Binance_openorder_futures])

    const FuturesOpenOrder = async (fil, sym) => {
        const objString = localStorage.getItem('token');
        if (objString) {
            let obj = JSON.parse(objString)
            let token = obj.value;
            await axios.get(process.env.REACT_APP_API + "/api/v1/trade/bn/futures/open/orders", {
                params: {
                    symbol: sym
                },
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                let tmp = response.data.data
                if (tmp) {
                    let arr = []
                    tmp.map(function (a, i) {
                        arr.push({
                            orderId: a.orderId, symbol: a.symbol, type: a.type, price: a.type.toUpperCase() != "LIMIT" && a.type.toUpperCase() != "MARKET" ? a.stopPrice : a.price,
                            executedQty: a.executedQty, origQty: a.origQty, side: a.side, reduceOnly: a.reduceOnly, timeInForce: a.timeInForce
                        })
                    })
                    setOpenorder([...fil, ...arr])
                    Bn_OpenOrder([...fil, ...arr])
                }
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 75, "msg": error }))
            })
        }
    }

    const FuturesAllOpenOrder = async () => {
        const objString = localStorage.getItem('token');
        if (objString) {
            let obj = JSON.parse(objString)
            let token = obj.value;
            await axios.get(process.env.REACT_APP_API + "/api/v1/trade/bn/futures/open/orders", {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                let tmp = response.data.data
                if (tmp) {
                    let arr = []
                    tmp.map(function (a, i) {
                        arr.push({
                            orderId: a.orderId, symbol: a.symbol, type: a.type, price: a.type.toUpperCase() != "LIMIT" && a.type.toUpperCase() != "MARKET" ? a.stopPrice : a.price,
                            executedQty: a.executedQty, origQty: a.origQty, side: a.side, reduceOnly: a.reduceOnly, timeInForce: a.timeInForce
                        })
                    })
                    setOpenorder(arr)
                    Bn_OpenOrder(arr)
                }
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 76, "msg": error }))
            })
        }
    }

    const CancelAll = () => {
        let list = []

        if (openorder) {
            for (let i = 0; i < openorder.length; i++) {
                let s = openorder[i].symbol
                if (s == symbol && !list.includes(s)) {
                    list.push(s)
                }
            }
        }

        if (list && list.length > 0) FuturesAllOpenOrderCancel(list)
    }

    const FuturesAllOpenOrderCancel = async (list) => {
        const objString = localStorage.getItem('token');
        if (objString) {
            let obj = JSON.parse(objString)
            let jwt = obj.value;

            setDelay(false)

            await axios.post(process.env.REACT_APP_API + "/api/v1/trade/bn/futures/all-order/cancel", {
                symbol: list
            }, {
                headers: { Authorization: `Bearer ${jwt}` }
            }).then((response) => {
                if (response?.data?.code >= 0) {
                    setMessage({ type: "binance", code: response?.data?.code, msg: `${t('message_13')}` })
                }
                else {
                    setMessage({ type: "binance", code: response?.data?.code, msg: t('message_34') })
                }
                setTimeout(() => {
                    setDelay(true)
                }, 500)
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 77, "msg": error }))
            })
        }
    }

    return (
        <div className={`futures-section8 ${darkname}`}>
            <Modal message={message} />
            <div className={`futures-section8-top ${darkname}`}>
                <div className='futures-section8-top-1'>
                    <div className={tab5 === 0 ? 'futures-section8-top-1-1 active' : 'futures-section8-top-1-1'} onClick={() => setTab5(0)}>{t('futures_79')}</div>
                    <div className={tab5 === 1 ? 'futures-section8-top-1-1 active' : 'futures-section8-top-1-1'} onClick={() => setTab5(1)}>
                        <span>{t('futures_80')}</span> <span>({openorder ? openorder.filter(item => item.symbol === symbol).length : "0"})</span></div>
                    <div className={tab5 === 2 ? 'futures-section8-top-1-1 active' : 'futures-section8-top-1-1'} onClick={() => setTab5(2)}>
                        <span>{t('futures_81')}</span> <span>({openorder ? openorder.length : "0"})</span></div>
                    <div className={tab5 === 3 ? 'futures-section8-top-1-1 display-none-1000 active' : 'futures-section8-top-1-1 display-none-1000'} onClick={() => setTab5(3)}>{t('futures_82')}</div>
                    {/* <div className={tab5 === 4 ? 'futures-section8-top-1-1 display-none-1000 active' : 'futures-section8-top-1-1 display-none-1000'} onClick={() => setTab5(4)} style={{ color: "#F5AC1D" }}>{t('futures_83')}</div> */}
                    <div className='futures-section8-top-1-2 display-none-1000'>
                        <input type='checkbox' disabled={true} />
                    </div>
                </div>
                <div className='futures-section8-top-2'>
                    {
                        delay
                            ?
                            <div className='futures-section8-top-1-3' onClick={() => CancelAll()}>{t('spot_46')}</div>
                            :
                            <div className='futures-section8-top-1-3'><ClipLoader color="#595959" size='17px' /></div>
                    }
                </div>
                <FontAwesomeIcon icon={positionbtn ? faSquareCaretDown : faSquareCaretUp} className='display-none-700-block' style={{ color: "gray", fontSize: "18px", paddingRight: "5px" }} onClick={() => setPositionbtn(btn => !btn)} />
            </div>
            <div className='futures-section8-bottom'>
                {
                    tab5 === 0
                        ?
                        <Position positionbtn={positionbtn} darkname={darkname} windowtypeM={windowtypeM} USDTSymbolF={USDTSymbolF} setMessage={setMessage} setPositionAmount={setPositionAmount} qtypoint={qtypoint} positionData={positionData} totalexinfo={totalexinfo} symbol={symbol} currentPrice={currentPrice} checkCurrentPosition={checkCurrentPosition} />
                        :
                        tab5 === 1
                            ?
                            <OpenOrder positionbtn={positionbtn} openorder={openorder} symbol={symbol} darkname={darkname} setMessage={setMessage} delay={delay} openorder_cancel={openorder_cancel} setOpenorder_cancel={setOpenorder_cancel} />
                            :
                            tab5 === 2
                                ?
                                <DiffOrder openorder={openorder} darkname={darkname} setMessage={setMessage} delay={delay} openorder_cancel={openorder_cancel} setOpenorder_cancel={setOpenorder_cancel} />
                                :
                                <Alarm setTradeVolume={setTradeVolume} tradeVolume={tradeVolume} />
                }
            </div>
        </div>
    )
}

function Position({ positionbtn, darkname, windowtypeM, USDTSymbolF, setMessage, setPositionAmount, qtypoint, positionData, totalexinfo, symbol, currentPrice, checkCurrentPosition }) {
    const { t } = useTranslation();
    let dispatch = useDispatch();

    const FuturesPositionClose = async (p, z, m) => {
        const objString = localStorage.getItem('token');
        if (objString) {
            let obj = JSON.parse(objString)
            let token = obj.value;

            await axios.post(process.env.REACT_APP_API + '/api/v1/trade/bn/futures/order', {
                symbol: p.symbol,
                side: Number(p.positionAmt) >= 0 ? "SELL" : "BUY",
                type: 'MARKET',
                quantity: z === 0 ? Number(RoundDown(Math.abs(p.positionAmt) / 2, m)) > 0 ? Number(RoundDown(Math.abs(p.positionAmt) / 2, m)) : Math.abs(p.positionAmt) : Math.abs(p.positionAmt),
                price: "",
                timeInForce: "",
                reduceOnly: true,
                stopPrice: "",
                closePosition: "true"
            }, {
                headers: { Authorization: `Bearer ${token}` }
            }
            ).then((response) => {
                if (response?.data?.code >= 0) {
                    setMessage({ type: "binance", code: response?.data?.code, msg: `${t('message_14')}` })
                }
                else {
                    setMessage({ type: "binance", code: response?.data?.code, msg: response?.data?.msg })
                }
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 78, "msg": error }))
            })
        }
    }

    return (
        <>
            {
                windowtypeM
                    ?
                    <div className='position-box'>
                        <div className='position'>
                            <div className='position-1'>
                                <div className='position-2'>
                                    <div className='position-3'>{t('futures_84')}</div>
                                </div>
                                <div className='position-2'>
                                    <div className='position-3'>{t('futures_85')}</div>
                                </div>
                                <div className='position-2'>
                                    <div className='position-3'>{t('futures_86')}</div>
                                </div>
                                <div className='position-2'>
                                    <div className='position-3'>{t('futures_87')}</div>
                                </div>
                                <div className='position-2'>
                                    <div className='position-3'>{t('futures_88')}</div>
                                </div>
                            </div>
                            <div className='position-1'>
                                <div className='position-2'>
                                    <div className='position-3'>{t('futures_89')}</div>
                                </div>
                                <div className='position-2'>
                                    <div className='position-3'>{t('futures_90')}</div>
                                </div>
                                <div className='position-2'>
                                    <div className='position-3'><span>{t('futures_91')}</span><span>(USDT)</span></div>
                                </div>
                                <div className='position-2'>
                                    <div className='position-3'>{t('futures_92')}(ROI) <FontAwesomeIcon icon={faCrown} /></div>
                                </div>
                                <div className='position-2'>
                                    <div className='position-3'>{t('futures_93')}</div>
                                </div>
                            </div>
                        </div>
                        {
                            checkCurrentPosition
                                ?
                                positionData.map(function (a, i) {
                                    let myData = USDTSymbolF.filter((item) => { return item.symbol === a.symbol })
                                    let minSize = totalexinfo.filter((item) => { return item.symbol === a.symbol })
                                    if (a.symbol == symbol) {
                                        return (
                                            <PositionWebItem key={i} positionData={a} darkname={darkname} FuturesPositionClose={FuturesPositionClose} myData={myData[0]} setPositionAmount={setPositionAmount} minSize={minSize && minSize[0] && minSize[0].filters && minSize[0].filters[2] ? minSize[0].filters[2].minQty : 0} symbol={symbol} currentPrice={currentPrice} />
                                        )
                                    }
                                })
                                :
                                <PositionNoneItem darkname={darkname} />
                        }
                        {
                            positionData
                                ?
                                positionData.map(function (a, i) {
                                    let myData = USDTSymbolF.filter((item) => { return item.symbol === a.symbol })
                                    let minSize = totalexinfo.filter((item) => { return item.symbol === a.symbol })
                                    if (a.symbol != symbol) {
                                        return (
                                            <PositionWebItem key={i} positionData={a} darkname={darkname} FuturesPositionClose={FuturesPositionClose} myData={myData[0]} setPositionAmount={setPositionAmount} minSize={minSize && minSize[0] && minSize[0].filters && minSize[0].filters[2] ? minSize[0].filters[2].minQty : 0} symbol={symbol} currentPrice={currentPrice} />
                                        )
                                    }
                                })
                                :
                                ""
                        }
                    </div>
                    :
                    positionbtn
                        ?
                        <>
                            {
                                checkCurrentPosition
                                    ?
                                    positionData.map(function (a, i) {
                                        let myData = USDTSymbolF.filter((item) => { return item.symbol === a.symbol })
                                        let minSize = totalexinfo.filter((item) => { return item.symbol === a.symbol })
                                        if (a.symbol == symbol) {
                                            return (
                                                <PositionMobileItem key={i} positionData={a} darkname={darkname} FuturesPositionClose={FuturesPositionClose} myData={myData[0]} setPositionAmount={setPositionAmount} minSize={minSize && minSize[0] && minSize[0].filters && minSize[0].filters[2] ? minSize[0].filters[2].minQty : 0} symbol={symbol} currentPrice={currentPrice} />
                                            )
                                        }
                                    })
                                    :
                                    <PositionNoneItem darkname={darkname} />
                            }
                            {
                                positionData
                                    ?
                                    positionData.map(function (a, i) {
                                        let myData = USDTSymbolF.filter((item) => { return item.symbol === a.symbol })
                                        let minSize = totalexinfo.filter((item) => { return item.symbol === a.symbol })
                                        if (a.symbol != symbol) {
                                            return (
                                                <PositionMobileItem key={i} positionData={a} darkname={darkname} FuturesPositionClose={FuturesPositionClose} myData={myData[0]} setPositionAmount={setPositionAmount} minSize={minSize && minSize[0] && minSize[0].filters && minSize[0].filters[2] ? minSize[0].filters[2].minQty : 0} symbol={symbol} currentPrice={currentPrice} />
                                            )
                                        }
                                    })
                                    :
                                    ""
                            }
                        </>
                        :
                        ""
            }
        </>
    )
}

function PositionNoneItem({ darkname }) {
    return (
        <div className='position'>
            <div className='position-1'>
                <div className='position-2'>
                    <div className={`position-4 ${darkname}`}></div>
                </div>
                <div className='position-2'>
                    <div className={`position-4 ${darkname}`}></div>
                </div>
                <div className='position-2'>
                    <div className={`position-4 ${darkname}`}></div>
                </div>
                <div className='position-2'>
                    <div className={`position-4 ${darkname}`}></div>
                </div>
                <div className='position-2'>
                    <div className={`position-4 ${darkname}`}></div>
                </div>
            </div>
            <div className='position-1'>
                <div className='position-2'>
                    <div className={`position-4 ${darkname}`}></div>
                </div>
                <div className='position-2'>
                    <div className={`position-4 ${darkname}`}></div>
                </div>
                <div className='position-2'>
                    <div className={`position-4 ${darkname}`}></div>
                </div>
                <div className='position-2'>
                    <div className={`position-4 ${darkname}`}></div>
                </div>
                <div className='position-2'>
                </div>
            </div>
        </div >
    )
}

function PositionWebItem({ positionData, darkname, FuturesPositionClose, myData, setPositionAmount, minSize, symbol, currentPrice }) {
    const { t } = useTranslation();
    let arr = positionData.symbol.split('USDT')

    return (
        <div className='position'>
            <div className='position-1'>
                <div className='position-2'>
                    <Link to={`/futures/binance/${positionData.symbol ? positionData.symbol : "BTCUSDT"}`} style={{ cursor: "pinter" }}>
                        <div className={`position-4 ${darkname}`}>{positionData.symbol ? `${arr[0]}USDT` : ""}</div>
                    </Link>
                </div>
                <div className='position-2'>
                    {
                        positionData.positionAmt
                            ?
                            Number(positionData.positionAmt) >= 0
                                ?
                                <div className={`position-4 ${darkname} green`}>{t('futures_145')}</div>
                                :
                                <div className={`position-4 ${darkname} red`}>{t('futures_146')}</div>
                            :
                            <div className={`position-4 ${darkname}`}></div>
                    }
                </div>
                <div className='position-2'>
                    <div className={`position-4 ${darkname}`}>{positionData.entryPrice ? myData && myData.point ? changeNum(Number(positionData.entryPrice).toFixed(myData.point)) : changeNum(Number(positionData.entryPrice)) : ""}</div>
                </div>
                <div className='position-2' onClick={() => setPositionAmount([e => !e, positionData.positionAmt ? `${Number(Math.abs(positionData.positionAmt))}` : ""])} style={{ cursor: "pointer" }}>
                    <div className={`position-4 ${darkname}`}>{positionData.positionAmt ? changeNum(Number(Math.abs(positionData.positionAmt))) : ""}</div>
                </div>
                <div className='position-2'>
                    <div className={`position-4 ${darkname}`}>{positionData.markPrice ? myData && myData.point ? changeNum(Number(positionData.markPrice).toFixed(myData.point)) : changeNum(Number(positionData.markPrice)) : ""}</div>
                </div>
            </div>
            <div className='position-1'>
                <div className='position-2'>
                    <div className={`position-4 ${darkname}`}>{positionData.marginType ? positionData.marginType === "CROSSED" || positionData.marginType === "cross" ? "Cross" : "Isolated" : ""}</div>
                </div>
                <div className='position-2'>
                    <div className={`position-4 ${darkname}`}>{positionData.liquidationPrice && Number(positionData.liquidationPrice) > 0 ? myData && myData.point ? Number(positionData.liquidationPrice).toFixed(myData.point) : Number(positionData.liquidationPrice) : "-"}</div>
                </div>
                <div className='position-2'>
                    <div className={`position-4 ${darkname}`}>{positionData.notional ? changeNum(Number(Math.abs(positionData.notional)).toFixed(4, '0')) : ""}</div>
                </div>
                <div className='position-2'>
                    {
                        positionData.unRealizedProfit
                            ?
                            symbol === positionData.symbol ?
                                Number((currentPrice - Number(positionData.entryPrice)) * Number(positionData.positionAmt)) >= 0 ?
                                    <div className={`position-4 ${darkname} green`}>
                                        {changeNum(Number((currentPrice - Number(positionData.entryPrice)) * Number(positionData.positionAmt)).toFixed(3))}
                                        ({(Number(currentPrice - Number(positionData.entryPrice)) * Number(positionData.positionAmt) * 100 / ((Number(positionData.entryPrice) * Math.abs(positionData.positionAmt) * (1 / Number(positionData?.leverage ?? 1))))).toFixed(2)}%)
                                    </div>
                                    :
                                    <div className={`position-4 ${darkname} red`}>
                                        {changeNum(Number((currentPrice - Number(positionData.entryPrice)) * Number(positionData.positionAmt)).toFixed(3))}
                                        ({(Number(currentPrice - Number(positionData.entryPrice)) * Number(positionData.positionAmt) * 100 / ((Number(positionData.entryPrice) * Math.abs(positionData.positionAmt) * (1 / Number(positionData?.leverage ?? 1))))).toFixed(2)}%)
                                    </div>
                                :
                                Number(positionData.unRealizedProfit) >= 0 ?
                                    <div className={`position-4 ${darkname} green`}>
                                        {changeNum(Number(positionData.unRealizedProfit).toFixed(3))}
                                        ({(Number(positionData.unRealizedProfit) * 100 / ((Number(positionData.entryPrice) * Math.abs(positionData.positionAmt) * (1 / Number(positionData?.leverage ?? 1))))).toFixed(2)}%)
                                    </div>
                                    :
                                    <div className={`position-4 ${darkname} red`}>
                                        {changeNum(Number(positionData.unRealizedProfit).toFixed(3))}
                                        ({(Number(positionData.unRealizedProfit) * 100 / ((Number(positionData.entryPrice) * Math.abs(positionData.positionAmt) * (1 / Number(positionData?.leverage ?? 1))))).toFixed(2)}%)
                                    </div>
                            :
                            <div className={`position-4 ${darkname}`}></div>
                    }
                </div>
                <div className='position-2'>
                    <div className='position-4-1'>
                        <div className='position-table-bg-1-1' onClick={() => FuturesPositionClose(positionData, 0, findSmallest(Number(minSize)))}>{t('futures_94')}</div>
                        <div className='position-table-bg-1-2' onClick={() => FuturesPositionClose(positionData, 1)}>{t('futures_95')}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function PositionMobileItem({ positionData, darkname, FuturesPositionClose, myData, setPositionAmount, minSize, symbol, currentPrice }) {
    const { t } = useTranslation();
    let arr = positionData.symbol.split('USDT')

    return (
        <div className='position'>
            <div className='position-1'>
                <div className='position-2'>
                    <div className='position-3'>{t('futures_84')}</div>
                    <Link to={`/futures/binance/${positionData.symbol ? positionData.symbol : "BTCUSDT"}`} style={{ cursor: "pinter" }}>
                        <div className={`position-4 ${darkname}`}>{positionData.symbol ? `${arr[0]}USDT` : ""}</div>
                    </Link>
                </div>
                <div className='position-2'>
                    <div className='position-3'>{t('futures_85')}</div>
                    {
                        positionData.positionAmt
                            ?
                            Number(positionData.positionAmt) >= 0
                                ?
                                <div className={`position-4 ${darkname} green`}>{t('futures_145')}</div>
                                :
                                <div className={`position-4 ${darkname} red`}>{t('futures_146')}</div>
                            :
                            <div className={`position-4 ${darkname}`}></div>
                    }
                </div>
                <div className='position-2'>
                    <div className='position-3'>{t('futures_86')}</div>
                    <div className={`position-4 ${darkname}`}>{positionData.entryPrice ? myData && myData.point ? changeNum(Number(positionData.entryPrice).toFixed(myData.point)) : changeNum(Number(positionData.entryPrice)) : ""}</div>
                </div>
                <div className='position-2' onClick={() => setPositionAmount([e => !e, positionData.positionAmt ? `${Number(Math.abs(positionData.positionAmt))}` : ""])} style={{ cursor: "pointer" }}>
                    <div className='position-3'>{t('futures_87')}</div>
                    <div className={`position-4 ${darkname}`}>{positionData.positionAmt ? changeNum(Number(Math.abs(positionData.positionAmt))) : ""}</div>
                </div>
                <div className='position-2'>
                    <div className='position-3'>{t('futures_88')}</div>
                    <div className={`position-4 ${darkname}`}>{positionData.markPrice ? myData && myData.point ? changeNum(Number(positionData.markPrice).toFixed(myData.point)) : changeNum(Number(positionData.markPrice)) : ""}</div>
                </div>
            </div>
            <div className='position-1'>
                <div className='position-2'>
                    <div className='position-3'>{t('futures_89')}</div>
                    <div className={`position-4 ${darkname}`}>{positionData.marginType ? positionData.marginType === "CROSSED" || positionData.marginType === "cross" ? "Cross" : "Isolated" : ""}</div>
                </div>
                <div className='position-2'>
                    <div className='position-3'>{t('futures_90')}</div>
                    <div className={`position-4 ${darkname}`}>{positionData.liquidationPrice && Number(positionData.liquidationPrice) > 0 ? myData && myData.point ? Number(positionData.liquidationPrice).toFixed(myData.point) : Number(positionData.liquidationPrice) : "-"}</div>
                </div>
                <div className='position-2'>
                    <div className='position-3'>{t('futures_91')}(USDT)</div>
                    <div className={`position-4 ${darkname}`}>{positionData.notional ? changeNum(Number(Math.abs(positionData.notional)).toFixed(4, '0')) : ""}</div>
                </div>
                <div className='position-2'>
                    <div className='position-3'>{t('futures_92')}(ROI) <FontAwesomeIcon icon={faCrown} /></div>
                    {
                        positionData.unRealizedProfit
                            ?
                            symbol === positionData.symbol
                                ?
                                Number((currentPrice - Number(positionData.entryPrice)) * Number(positionData.positionAmt)) >= 0
                                    ?
                                    <div className={`position-4 ${darkname} green`}>
                                        {changeNum(Number((currentPrice - Number(positionData.entryPrice)) * Number(positionData.positionAmt)).toFixed(3))}
                                        ({(Number(currentPrice - Number(positionData.entryPrice)) * Number(positionData.positionAmt) * 100 / ((Number(positionData.entryPrice) * Math.abs(positionData.positionAmt) * (1 / positionData.leverage)))).toFixed(2)}%)

                                    </div>
                                    :
                                    <div className={`position-4 ${darkname} red`}>
                                        {changeNum(Number((currentPrice - Number(positionData.entryPrice)) * Number(positionData.positionAmt)).toFixed(3))}
                                        ({(Number(currentPrice - Number(positionData.entryPrice)) * Number(positionData.positionAmt) * 100 / ((Number(positionData.entryPrice) * Math.abs(positionData.positionAmt) * (1 / positionData.leverage)))).toFixed(2)}%)
                                    </div>
                                :
                                Number(positionData.unRealizedProfit) >= 0 ?
                                    <div className={`position-4 ${darkname} green`}>
                                        {changeNum(Number(positionData.unRealizedProfit).toFixed(3))}
                                        ({(Number(positionData.unRealizedProfit) * 100 / ((Number(positionData.entryPrice) * Math.abs(positionData.positionAmt) * (1 / positionData.leverage)))).toFixed(2)}%)
                                    </div>
                                    :
                                    <div className={`position-4 ${darkname} red`}>
                                        {changeNum(Number(positionData.unRealizedProfit).toFixed(3))}
                                        ({(Number(positionData.unRealizedProfit) * 100 / ((Number(positionData.entryPrice) * Math.abs(positionData.positionAmt) * (1 / positionData.leverage)))).toFixed(2)}%)
                                    </div>

                            // Number(positionData.unRealizedProfit) >= 0
                            //     ?
                            //     <div className={`position-4 ${darkname} green`}>{symbol === positionData.symbol ? changeNum(Number((currentPrice - Number(positionData.entryPrice)) * Number(positionData.positionAmt)).toFixed(3)) : changeNum(Number(positionData.unRealizedProfit).toFixed(3))}</div>
                            //     :
                            //     <div className={`position-4 ${darkname} red`}>{symbol === positionData.symbol ? changeNum(Number((currentPrice - Number(positionData.entryPrice)) * Number(positionData.positionAmt)).toFixed(3)) : changeNum(Number(positionData.unRealizedProfit).toFixed(3))}</div>
                            :
                            <div className={`position-4 ${darkname}`}></div>
                    }
                </div>
                <div className='position-2'>
                    <div className='position-3'>{t('futures_93')}</div>
                    <div className='position-4-1'>
                        <div className='position-table-bg-1-1' onClick={() => FuturesPositionClose(positionData, 0, findSmallest(Number(minSize)))}>{t('futures_94')}</div>
                        <div className='position-table-bg-1-2' onClick={() => FuturesPositionClose(positionData, 1)}>{t('futures_95')}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function OpenOrder({ positionbtn, openorder, symbol, darkname, setMessage, delay, openorder_cancel, setOpenorder_cancel }) {
    const { t } = useTranslation();

    return (
        <>
            {
                positionbtn
                    ?
                    <div className='futures-order'>
                        <div className='futures-order1'>
                            <div className='futures-order-1'>
                                <div className={`futures-order-1-top ${darkname}`}><span>{t('futures_96')}</span></div>
                                <div className='futures-order-11'>
                                    <table className={`futures-order-3 ${darkname}`}>
                                        <thead>
                                            <tr>
                                                <th><span>{t('futures_97')}</span></th>
                                                <th><span>{t('futures_98')}</span></th>
                                                <th><span>{t('futures_99')}</span></th>
                                                <th><span>{t('futures_100')}</span><span>/</span><span>{t('futures_101')}</span></th>
                                                <th><span>{t('futures_102')}</span></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                openorder.map(function (a, i) {
                                                    if (a.side === "BUY") {
                                                        if (a.symbol === symbol) {
                                                            return (
                                                                <OpenOrderItem key={i} item={a} side={true} setMessage={setMessage} delay={delay} openorder_cancel={openorder_cancel} setOpenorder_cancel={setOpenorder_cancel} />
                                                            )
                                                        }
                                                    }
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className='futures-order-1'>
                                <div className={`futures-order-2-top ${darkname}`}>{t('futures_103')}</div>
                                <div className='futures-order-11'>
                                    <table className={`futures-order-3 ${darkname}`}>
                                        <thead>
                                            <tr>
                                                <th><span>{t('futures_97')}</span></th>
                                                <th><span>{t('futures_98')}</span></th>
                                                <th><span>{t('futures_99')}</span></th>
                                                <th><span>{t('futures_100')}</span><span>/</span><span>{t('futures_101')}</span></th>
                                                <th><span>{t('futures_102')}</span></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                openorder.map(function (a, i) {
                                                    if (a.side === "SELL") {
                                                        if (a.symbol === symbol) {
                                                            return (
                                                                <OpenOrderItem key={i} item={a} side={false} setMessage={setMessage} delay={delay} openorder_cancel={openorder_cancel} setOpenorder_cancel={setOpenorder_cancel} />
                                                            )
                                                        }
                                                    }
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div >
                    :
                    ""
            }
        </>
    )
}

function OpenOrderItem({ item, side, setMessage, delay, openorder_cancel, setOpenorder_cancel }) {
    const { t } = useTranslation();
    let dispatch = useDispatch();
    let arr = item.symbol.split('USDT')

    const FuturesOpenOrderCancel = async () => {
        const objString = localStorage.getItem('token');
        if (objString) {
            let obj = JSON.parse(objString)
            let jwt = obj.value;
            setOpenorder_cancel(false)
            await axios.post(process.env.REACT_APP_API + "/api/v1/trade/bn/futures/order/cancel", {
                orderId: item.orderId,
                symbol: item.symbol
            },
                {
                    headers: { Authorization: `Bearer ${jwt}` }
                }).then((response) => {
                    if (response?.data?.code >= 0) {
                        setMessage({ type: "binance", code: response?.data?.code, msg: `${t('message_13')}` })
                    }
                    else {
                        setMessage({ type: "binance", code: response?.data?.code, msg: t('message_34') })
                    }
                    setTimeout(() => {
                        setOpenorder_cancel(true)
                    }, 500)
                }).catch((error) => {
                    dispatch(check_Error({ "bol": true, "no": 79, "msg": error }))
                })
        }
    }

    return (
        <tr>
            <td className={side ? "futures-order-3-buy" : "futures-order-3-sell"}><span>{arr[0]}USDT<span></span></span></td>
            <td className={side ? "futures-order-3-buy" : "futures-order-3-sell"}><span>{item.type}</span></td>
            <td className={side ? "futures-order-3-buy" : "futures-order-3-sell"}><span>{changeNum(item.price)}</span></td>
            <td className={side ? "futures-order-3-buy" : "futures-order-3-sell"}><span>{changeNum(item.executedQty)}</span><span>/</span><span>{changeNum(item.origQty)}</span></td>
            {
                openorder_cancel && delay
                    ?
                    <td className={side ? "futures-order-3-buy" : "futures-order-3-sell"} style={{ cursor: "pointer" }} onClick={() => FuturesOpenOrderCancel()}>
                        <FontAwesomeIcon icon={faCircleXmark} />
                    </td>
                    :
                    <td className={side ? "futures-order-3-buy" : "futures-order-3-sell"} style={{ cursor: "pointer" }}>
                        <ClipLoader color="#595959" size='14px' />
                    </td>
            }
        </tr>
    )
}

function DiffOrder({ openorder, darkname, setMessage, delay, openorder_cancel, setOpenorder_cancel }) {
    const { t } = useTranslation();
    return (
        <div className='futures-order'>
            <div className='futures-order1'>
                <div className='futures-order-1'>
                    <div className={`futures-order-1-top ${darkname}`}>{t('futures_96')}</div>
                    <div className='futures-order-11'>
                        <table className={`futures-order-3 ${darkname}`}>
                            <thead>
                                <tr>
                                    <th><span>{t('futures_97')}</span></th>
                                    <th><span>{t('futures_98')}</span></th>
                                    <th><span>{t('futures_99')}</span></th>
                                    <th><span>{t('futures_100')}</span><span>/</span><span>{t('futures_101')}</span></th>
                                    <th><span>{t('futures_102')}</span></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    openorder.map(function (a, i) {
                                        if (a.side === "BUY") {
                                            return (
                                                <OpenOrderItem key={i} item={a} side={true} setMessage={setMessage} delay={delay} openorder_cancel={openorder_cancel} setOpenorder_cancel={setOpenorder_cancel} />
                                            )
                                        }
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className='futures-order-1'>
                    <div className={`futures-order-2-top ${darkname}`}>{t('futures_103')}</div>
                    <div className='futures-order-11'>
                        <table className={`futures-order-3 ${darkname}`}>
                            <thead>
                                <tr>
                                    <th><span>{t('futures_97')}</span></th>
                                    <th><span>{t('futures_98')}</span></th>
                                    <th><span>{t('futures_99')}</span></th>
                                    <th><span>{t('futures_100')}</span><span>/</span><span>{t('futures_101')}</span></th>
                                    <th><span>{t('futures_102')}</span></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    openorder.map(function (a, i) {
                                        if (a.side === "SELL") {
                                            return (
                                                <OpenOrderItem key={i} item={a} side={false} setMessage={setMessage} delay={delay} openorder_cancel={openorder_cancel} setOpenorder_cancel={setOpenorder_cancel} />
                                            )
                                        }
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

function Alarm({ setTradeVolume, tradeVolume }) {
    const { t } = useTranslation();
    let Alert = useSelector((state) => { return state.Alert });
    let dispatch = useDispatch();

    const onChangeTradeAlert = (e) => {
        dispatch(checkAlert({ num: 0, value: e.target.value }))
    }
    const onChangeFilledAlert = (e) => {
        dispatch(checkAlert({ num: 1, value: e.target.value }))
    }
    const onChangeOrderAlert = (e) => {
        dispatch(checkAlert({ num: 2, value: e.target.value }))
    }
    const onChangeCancelAlert = (e) => {
        dispatch(checkAlert({ num: 3, value: e.target.value }))
    }
    const onChangeTradeVolume = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^0-9]/g, '')
        setTradeVolume(onlyNumber)
        localStorage.setItem('TradeVolume', onlyNumber)
    }

    return (
        <div className='futures-alarm'>
            <div className='futures-alarm-box'>
                <div className='futures-alarm-1'>{t('futures_111')}</div>
                <select value={Alert[0]} onChange={onChangeTradeAlert}>
                    <option value={"ON"}><span>{t('futures_11')}</span></option>
                    <option value={"OFF"}><span>{t('futures_12')}</span></option>
                </select>
                <div className='futures-alarm-1'>{t('futures_112')}</div>
                <input type='number' placeholder='0' value={tradeVolume} onChange={onChangeTradeVolume} />
            </div>
            <div className='futures-alarm-box'>
                <div className='futures-alarm-1'>{t('futures_113')}</div>
                <select value={Alert[1]} onChange={onChangeFilledAlert}>
                    <option value={"ON"}><span>{t('futures_11')}</span></option>
                    <option value={"OFF"}><span>{t('futures_12')}</span></option>
                </select>
                <div className='futures-alarm-1'>{t('futures_147')}</div>
                <select value={Alert[2]} onChange={onChangeOrderAlert}>
                    <option value={"ON"}><span>{t('futures_11')}</span></option>
                    <option value={"OFF"}><span>{t('futures_12')}</span></option>
                </select>
                {/* <div className='futures-alarm-1'>Order Cancel Alert</div>
                <select value={Alert[3]} onChange={onChangeCancelAlert}>
                    <option value={"ON"}><span>{t('futures_11')}</span></option>
                    <option value={"OFF"}><span>{t('futures_12')}</span></option>
                </select> */}
            </div>
        </div>
    )
}

export default BFsection8;