import '../css/wallet.css'
import { Link, useNavigate, useLocation } from "react-router-dom"
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { checkSelectType, checkLogin, changeOKX_Message, changeWalletPage } from '../../store';
import AOS from "aos";
import { useTranslation } from "react-i18next";

import Bybit_Overview from "./Bywallet/by_overview"
import Bybit_Deposit from "./Bywallet/by_deposit"
import Bybit_Withdrawal from './Bywallet/by_withdraw';
import Bybit_Exchange from './Bywallet/by_exchange';
import Bybit_Transfer from './Bywallet/by_transfer';
import Bybit_Gift from './Bywallet/by_gift';
import Bybit_QuickWithdrawal from './Bywallet/by_quickWithdrawl';

import Common_Deposit from '../common/C_wallet/c_deposit';
import Common_Withdrawal from '../common/C_wallet/c_withdraw';
import Common_Exchange from '../common/C_wallet/c_exchange';
import Common_Transfer from '../common/C_wallet/c_transfer';
import Common_Gift from '../common/C_wallet/c_gift';

import { C_Wallet_Sidebar } from '../common/C_wallet/W_wallet';

function ByWallet() {
    const { t } = useTranslation();
    let dark = useSelector((state) => { return state.Darkmode.value });
    let BybitS = useSelector((state) => { return state.Bybit_coinS });
    let check_KYC = useSelector((state) => { return state.check_KYC.value });
    let Wallet_Page = useSelector((state) => { return state.Wallet_Page.value });
    let Bybit_wallet = useSelector((state) => { return state.Bybit_wallet });
    let Bybit_wallet_usdt = useSelector((state) => { return state.Bybit_wallet_usdt });
    let API_Address = useSelector((state) => { return state.API_Address.value })
    let SelectType = useSelector((state) => { return state.SelectType.value })
    let OKX_Mode = useSelector((state) => { return state.OKX_Mode.value })

    const darkname = dark ? 'dark' : "";

    const location = useLocation();
    let dispatch = useDispatch();
    const navigate = useNavigate();

    const [tab, setTab] = useState(location.state === null ? 0 : location.state.num)
    const [Bbalance, setBBalance] = useState([]);
    const [spotBalance, setSpotBalance] = useState([])
    const [BcurrentUSDT, setBCurrentUSDT] = useState("")
    const [walletData, setWalletData] = useState([])
    const [reload, setReload] = useState(false)
    const [fastCoin, setFastCoin] = useState("")
    const [check_bnKyc, setCheck_bnKyc] = useState(false)

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString) {
            const obj = JSON.parse(objString);
            if (Date.now() > obj.expire) {
                localStorage.removeItem('token');
                dispatch(checkLogin(false));
                navigate('/')
            }
            else {
                dispatch(checkLogin(true));
            }
        }
        else {
            dispatch(checkLogin(false));
            navigate('/')
        }
    }, [])

    useEffect(() => {
        if (Bybit_wallet) {
            let cnt = true
            if (Bybit_wallet?.length > 0) {
                for (let i = 0; i < Bybit_wallet.length; i++) {
                    if (Bybit_wallet[i].coin == "USDT") {
                        setSpotBalance({ asset: "USDT", free: Bybit_wallet[i].availableToWithdraw, freeze: Bybit_wallet[i].locked })
                        cnt = false
                    }
                }
                if (cnt) {
                    setSpotBalance({ asset: "USDT", free: "0", freeze: "0" })
                }
            }
            else {
                setSpotBalance({ asset: "USDT", free: "0", freeze: "0" })
            }
            setBBalance(Bybit_wallet)
        }
    }, [reload, Bybit_wallet])

    useEffect(() => {
        if (Bybit_wallet_usdt && Bybit_wallet_usdt[0]) {
            setBCurrentUSDT(Bybit_wallet_usdt[0].availableToWithdraw)
        }
    }, [Bybit_wallet_usdt])

    useEffect(() => {
        let data = [];
        if (BybitS && Bbalance) {
            for (let i = 0; i < BybitS.length; i++) {
                let num = 0;
                for (let j = 0; j < Bbalance.length; j++) {
                    if (BybitS[i].ccy == Bbalance[j].coin) {
                        num = 1
                        data.push({ symbol: BybitS[i].ccy, name: BybitS[i].name, fullName: BybitS[i].fullName, lastPrice: BybitS[i].lastPrice, volume: BybitS[i].volume, availBal: Bbalance[j].availableToWithdraw, frozenBal: Bbalance[j].locked })
                    }
                }
                if (num == 0) data.push({ symbol: BybitS[i].ccy, name: BybitS[i].name, fullName: BybitS[i].fullName, lastPrice: BybitS[i].lastPrice, volume: BybitS[i].volume, availBal: 0, frozenBal: 0 })
            }

            for (let j = 0; j < Bbalance.length; j++) {
                if ("USDT" == Bbalance[j].coin) {
                    data.push({ symbol: "USDT", name: "USDT", fullName: "USDT", lastPrice: 1, volume: Number(Bbalance[j].walletBalance), availBal: Bbalance[j].availableToWithdraw, frozenBal: Bbalance[j].locked })
                }
            }

            const isSymbolIncluded = data.some(item => item.symbol === "USDT");
            if (!isSymbolIncluded) {
                data.push({ symbol: "USDT", name: "USDT", fullName: "USDT", lastPrice: 1, volume: 0, availBal: 0, frozenBal: 0 })
            }

            data.sort((a, b) => Number(b.volume) * Number(b.lastPrice) - Number(a.volume) * Number(a.lastPrice))
            setWalletData(data)
        }
    }, [Bbalance, BybitS])

    useEffect(() => {
        if (location.state === null) setTab(0)
        else {
            // if (location.state.num === 5 && check_KYC && check_KYC.bnKyc === 0 || location.state.num === 5 && check_KYC && check_KYC.etcKyc === 0) {
            //     setCheck_bnKyc(true)
            //     setTab(location.state.num)
            // }
            // else {
            //     setCheck_bnKyc(false)
            //     setTab(location.state.num)
            // }
            setTab(location.state.num)
        }
    }, [location, check_KYC])

    useEffect(() => {
        AOS.init();
    }, [])

    useEffect(() => {
        document.title = "NAMUBIT│One-Stop Trading Platform - Crypto Trading Universe";
    }, [API_Address]);

    return (
        <div className={`wallet ${darkname}`}>
            <div className='history-container'>
                {/* <div className='history-container' style={{ filter: check_KYC && check_KYC.etcKyc === 0 && Wallet_Page === 2 && tab != 0 || check_bnKyc ? "blur(5px)" : "" }}> */}
                <div className='history-wrap'>
                    <C_Wallet_Sidebar tab={tab} setTab={setTab} />
                    {
                        tab === 0
                            ?
                            <Bybit_Overview darkname={darkname} walletData={walletData} BcurrentUSDT={BcurrentUSDT} setFastCoin={setFastCoin} />
                            :
                            tab === 1
                                ?
                                Wallet_Page === -1
                                    ?
                                    <Common_Deposit darkname={darkname} />
                                    :
                                    <Bybit_Deposit walletData={walletData} fastCoin={fastCoin} />
                                :
                                tab === 2
                                    ?
                                    Wallet_Page === -1
                                        ?
                                        <Common_Withdrawal darkname={darkname} />
                                        :
                                        <Bybit_Withdrawal darkname={darkname} walletData={walletData} setReload={setReload} fastCoin={fastCoin} />
                                    :
                                    tab === 3
                                        ?
                                        Wallet_Page === -1
                                            ?
                                            <Common_Exchange darkname={darkname} />
                                            :
                                            <Bybit_Exchange walletData={walletData} spotBalance={spotBalance} setReload={setReload} fastCoin={fastCoin} />
                                        :
                                        tab === 4
                                            ?
                                            Wallet_Page === -1
                                                ?
                                                <Common_Transfer darkname={darkname} />
                                                :
                                                <Bybit_Transfer darkname={darkname} spotBalance={spotBalance} BcurrentUSDT={BcurrentUSDT} setReload={setReload} />
                                            :
                                            tab === 5
                                                ?
                                                check_bnKyc
                                                    ?
                                                    <Bybit_Overview darkname={darkname} walletData={walletData} BcurrentUSDT={BcurrentUSDT} setFastCoin={setFastCoin} />
                                                    :
                                                    <Bybit_QuickWithdrawal setReload={setReload} />
                                                :
                                                Wallet_Page === -1
                                                    ?
                                                    <Common_Gift darkname={darkname} />
                                                    :
                                                    <Bybit_Gift darkname={darkname} BcurrentUSDT={BcurrentUSDT} reload={reload} setReload={setReload} />
                    }
                </div>
            </div>
            {/* {
                check_KYC && check_KYC.etcKyc === 0 && Wallet_Page === 2 && tab != 0 || check_bnKyc
                    ?
                    <div className='spot-kyc-modal'>
                        <div className={`spot-kyc-modal-1 ${darkname}`}>
                            <div className='spot-kyc-modal-text'>{t('message_4')}<br />{t('message_5')}</div>
                            <Link to={`/dashboard`} state={{ num: 1 }} onClick={() => { setCheck_bnKyc(false); dispatch(changeWalletPage(-1)) }}>
                                <div className='spot-kyc-modal-btn'>{t('message_6')}</div>
                            </Link>
                        </div>
                    </div>
                    :
                    ""
            } */}
        </div>
    )
}

export default ByWallet;