import '../css/futures.css'
import '../css/goexF.css'
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux'
import { checkSelectType, checkLogin, check_Error, changeOKX_markPrice, change_OKX_openorder } from '../../store';
import base64 from 'base-64';
import { useTranslation } from "react-i18next";
import ErrorBoundary from '../ErrorBoundary'
import isEqual from 'lodash/isEqual';
import Modal from '../message/modal'
import { findSmallest, changeNum, padZero } from "../common/function"
import '@fortawesome/fontawesome-free/css/all.css';

import Fsection1 from './futures/Fsection1';
import Fsection4 from './futures/Fsection4';
import Fsection8 from './futures/Fsection8';
import Fsection7 from './futures/Fsection7';
import Fsection6 from './futures/Fsection6';

let data = []
let preArr = []
let arr = []
let prePrice = 0
let pre_symbol = ""
let openorder_data = []
let postOnly_data = []

function Futures() {
    const { t } = useTranslation();

    let OKXF = useSelector((state) => { return state.OKX_coinF });
    let dark = useSelector((state) => { return state.Darkmode.value });
    let OKX_position = useSelector((state) => { return state.OKX_position });
    let OKX_liquidation = useSelector((state) => { return state.OKX_liquidation });
    let Alert = useSelector((state) => { return state.Alert });
    let OKX_wallet_usdt = useSelector((state) => { return state.OKX_wallet_usdt });
    let OKX_timestamp = useSelector((state) => { return state.OKX_timestamp.value });
    let login = useSelector((state) => { return state.login.value });

    const CryptoJS = require('crypto-js');
    const darkname = dark ? 'dark' : "";

    let { symbol } = useParams();
    let dispatch = useDispatch();

    const [windowtype, setWindowtype] = useState(true);
    const [windowtypeM, setWindowtypeM] = useState(true);
    const [tab, setTab] = useState(localStorage.getItem('f_coinList') ? localStorage.getItem('f_coinList') == "true" ? true : false : true)
    const [tab1, setTab1] = useState(localStorage.getItem('f_orderbook') ? localStorage.getItem('f_orderbook') == "true" ? true : false : true)
    const [tab3, setTab3] = useState(true)
    const [tab4, setTab4] = useState(1)
    const [tab5, setTab5] = useState(0)
    const [lastBuy, setLastBuy] = useState(0);
    const [lastSell, setLastSell] = useState(0);
    const [checkordertab, setCheckordertab] = useState(true)
    const [speedorder, setSpeedorder] = useState([0, 0])
    const [instrumentsData, setInstrumentData] = useState([])
    const [fwallet, setFwallet] = useState([])
    const [current, setCurrent] = useState('0');
    const [positionAmount, setPositionAmount] = useState([false, ""])
    const [orderReload, setOrderReload] = useState(false)

    const [max, setMax] = useState(4)
    const [maxCnt, setMaxCnt] = useState(true)
    const [positionData, setPositionData] = useState([]);

    const [firstmargin, setFirstmargin] = useState([])
    const [marginbtn, setMarginbtn] = useState(0)
    const [marginbtn1, setMarginbtn1] = useState(1)
    const [marginList, setMarginList] = useState([])
    const [maxPosition, setMaxPosition] = useState(0)

    const [isPlaying, setIsPlaying] = useState(false);
    const [isPlaying1, setIsPlaying1] = useState(false);
    const [isPlaying2, setIsPlaying2] = useState(false);
    const [isPlaying3, setIsPlaying3] = useState(false);
    const [isPlaying4, setIsPlaying4] = useState(false);
    const [checkisPlaying, setCheckisPlaying] = useState(false)

    const [tradeVolume, setTradeVolume] = useState("10000")
    const [currentPrice, setCurrentPrice] = useState(0.00000000001)
    const [checkCurrentPosition, setCheckCurrentPosition] = useState(false)

    const [btnbtn, setBtnbtn] = useState(0);
    const [markPrice, setMarkPrice] = useState([])
    const [fundingFee, setFundingFee] = useState(0)
    const [positionDirect, setPositionDirect] = useState(0)
    const [risk_balance, setRisk_balance] = useState(0)
    const [risk_position, setRisk_position] = useState(0)
    const [margin_type, setMargin_type] = useState("")

    const [tmm, setTMM] = useState(0)
    const [upnl, setUPNL] = useState(0)
    const [pBoth, setPBoth] = useState([0, 0, 1])
    const [minSz, setMinSz] = useState(2)
    const [minCoin, setMinCoin] = useState("Loding")
    const [qtypoint, setQtypoint] = useState(2)

    const [Mtab, setMtab] = useState(0)
    const [Mordertab, setMordertab] = useState(true)
    const [openorder, setOpenorder] = useState([])
    const [timestamp, setTimestamp] = useState("")
    const [timestamp1, setTimestamp1] = useState("")
    const [message, setMessage] = useState([])

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString) {
            const obj = JSON.parse(objString);
            if (Date.now() > obj.expire) {
                localStorage.removeItem('token');
                dispatch(checkLogin(false));
            }
            else {
                dispatch(checkLogin(true));
            }
        }
        else {
            dispatch(checkLogin(false));
        }
    }, []);

    let USDTSymbolF = OKXF ? OKXF : [];
    dispatch(checkSelectType(0))

    USDTSymbolF.map(function (a, i) {
        if (a.symbol === symbol) {
            if (maxCnt) {
                setMax(a.point)
                setMaxCnt(false)
            }
            data = {
                symbol: a.symbol, ccy: a.ccy, priceChangePercent: a.priceChangePercent, lastPrice: a.lastPrice, volume: a.volume
                , highPrice: a.highPrice, lowPrice: a.lowPrice, priceChange: a.priceChange, point: a.point
            }
        }
    })

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 1550) setTab(false)
            else setTab(true)
            if (window.innerWidth >= 1300) setWindowtype(true)
            else {
                setWindowtype(false)
                change_OrderbookTab(true)
            }
            if (window.innerWidth >= 1000) {
                setWindowtypeM(true)
                setMordertab(true)
            }
            else setWindowtypeM(false)
        };

        window.addEventListener('resize', handleResize);
        handleResize()

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (instrumentsData) {
            let minsz = (Number(instrumentsData.minSz) * Number(instrumentsData.ctVal)).toFixed(5)
            let point = findSmallest(minsz)
            let min = findSmallest(Number(instrumentsData.minSz))

            setMinSz(min)
            setMinCoin(Number(minsz))
            setQtypoint(point)
            setMtab(0)
        }
    }, [instrumentsData, symbol])

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            OKX_First_PositionData(token)
        }
    }, [])

    useEffect(() => {
        let objString = localStorage.getItem("TradeVolume")
        if (objString) {
            setTradeVolume(objString)
        }
    }, [])

    const playAudio = () => {
        setIsPlaying(true);
        setCheckisPlaying(true)

        setTimeout(() => {
            setIsPlaying(false);
            setCheckisPlaying(false)
        }, 1000)
    };

    const playAudio1 = () => {
        setIsPlaying1(true);
        setCheckisPlaying(true)

        setTimeout(() => {
            setIsPlaying1(false);
            setCheckisPlaying(false)
        }, 1000)
    };

    const playAudio2 = () => {
        setIsPlaying2(true);
        setCheckisPlaying(true)

        setTimeout(() => {
            setIsPlaying2(false);
            setCheckisPlaying(false)
        }, 1000)
    };

    const playAudio3 = () => {
        setIsPlaying3(true);
        setCheckisPlaying(true)

        setTimeout(() => {
            setIsPlaying3(false);
            setCheckisPlaying(false)
        }, 1000)
    };

    const playAudio4 = () => {
        setIsPlaying4(true);
        setCheckisPlaying(true)

        setTimeout(() => {
            setIsPlaying4(false);
            setCheckisPlaying(false)
        }, 1000)
    };

    useEffect(() => {
        if (!currentPrice || Number(currentPrice) === 0.00000000001) {
            document.title = `${data.lastPrice ? Number(data.lastPrice).toFixed(max) : "0.0000"} | ${data.ccy ? data.ccy + "USDT" : ""} | OKX`;
            setCurrentPrice(data.lastPrice ? data.lastPrice : "0.00000000001")
        }
        else {
            document.title = `${Number(currentPrice).toFixed(max)} | ${data.ccy ? data.ccy + "USDT" : ""} | OKX`;
        }
    }, [currentPrice, data]);

    useEffect(() => {
        setMaxCnt(true)
        setMtab(0)
        FuturesInstruments()
        setCurrentPrice(0.00000000001)
    }, [symbol])

    useEffect(() => {
        if (login) {
            FuturesOpenOrder();
        }
    }, [login])

    useEffect(() => {
        if (OKX_timestamp && OKX_timestamp.length > 0) {
            setTimestamp(OKX_timestamp)
            setTimestamp1(OKX_timestamp)
        }
    }, [OKX_timestamp])


    const Timestamp = async () => {
        let local_time = localStorage.getItem("okx_timestamp")
        if (local_time && JSON.parse(local_time).delay >= Date.now()) {
            setTimestamp(JSON.parse(local_time).time)
        }
        else {
            await axios.get("https://www.okx.com/api/v5/public/time", {
            }).then((response) => {
                let tmp = response.data.data
                if (tmp && tmp[0] && tmp[0].ts) {
                    localStorage.removeItem("okx_timestamp")
                    setTimestamp(String(Number(tmp[0].ts) / 1000))
                    let obj = {
                        time: String(Number(tmp[0].ts) / 1000),
                        delay: Date.now() + 20000
                    }
                    const objString = JSON.stringify(obj);
                    localStorage.setItem("okx_timestamp", objString)
                }
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 127, "msg": error }))
            })
        }
    }

    const Timestamp1 = async () => {
        let local_time = localStorage.getItem("okx_timestamp")
        if (local_time && JSON.parse(local_time).delay >= Date.now()) {
            setTimestamp1(JSON.parse(local_time).time)
        }
        else {
            await axios.get("https://www.okx.com/api/v5/public/time", {
            }).then((response) => {
                let tmp = response.data.data
                if (tmp && tmp[0] && tmp[0].ts) {
                    localStorage.removeItem("okx_timestamp")
                    setTimestamp1(String(Number(tmp[0].ts) / 1000))
                    let obj = {
                        time: String(Number(tmp[0].ts) / 1000),
                        delay: Date.now() + 20000
                    }
                    const objString = JSON.stringify(obj);
                    localStorage.setItem("okx_timestamp", objString)
                }
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 128, "msg": error }))
            })
        }
    }

    const FuturesOpenOrder = async () => {
        const objString = localStorage.getItem('token');
        if (objString) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            await axios.get(process.env.REACT_APP_API + "/api/v1/trade/okx/swap/open-orders", {
                headers: { Authorization: `Bearer ${token}` }
            }).then((response) => {
                let tmp = response.data.data
                if (tmp) {
                    let arr = []
                    for (let i = 0; i < tmp.length; i++) {
                        let data = tmp[i]
                        if (data.ordType == "trigger") arr.push({ algoId: data.algoId, ordId: data.ordId, instId: data.instId, ordType: data.ordType, px: data.triggerPx, fillSz: 0, sz: data.sz, side: data.side, tdMode: data.tdMode })
                        else if (data.ordType == "conditional") arr.push({ algoId: data.algoId, ordId: data.ordId, instId: data.instId, ordType: data.ordType, px: data.slTriggerPx.length > 0 ? data.slTriggerPx : data.tpTriggerPx, fillSz: 0, sz: data.sz, side: data.side, tdMode: data.tdMode })
                        else arr.push({ algoId: data.algoId, ordId: data.ordId, instId: data.instId, ordType: data.ordType, px: data.px, fillSz: 0, sz: data.sz, side: data.side, tdMode: data.tdMode })
                    }
                    setOpenorder(arr)
                    dispatch(change_OKX_openorder(arr))
                    openorder_data = arr
                }
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 129, "msg": error }))
            })
        }
    }

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString && timestamp.length > 0) {
            const obj = JSON.parse(objString);
            let token = obj.value;
            let payload = token.substring(token.indexOf('.') + 1, token.lastIndexOf('.'));
            let dec = base64.decode(payload);

            const loginMessage = {
                op: "login",
                args: [
                    {
                        apiKey: JSON.parse(dec).apiKey,
                        passphrase: JSON.parse(dec).passphrase,
                        timestamp: timestamp,
                        sign: CryptoJS.enc.Base64.stringify(CryptoJS.HmacSHA256(timestamp + 'GET' + '/users/self/verify', JSON.parse(dec).secretKey))
                    }
                ]
            }
            const subscribeMessage = {
                op: "subscribe",
                args: [
                    {
                        channel: "orders",
                        instType: "SWAP",
                    }
                ]
            }

            let socket = JSON.parse(dec).userId === 1 ? new WebSocket(`wss://wspap.okx.com:8443/ws/v5/private?brokerId=9999`) : new WebSocket(`wss://ws.okx.com:8443/ws/v5/private`);
            socket.onopen = function () {
                socket.send(JSON.stringify(loginMessage))
                const intervalId = setInterval(() => {
                    if (socket.readyState === WebSocket.OPEN) {
                        socket.send("ping");
                    }
                }, 25000)

                return () => {
                    clearInterval(intervalId)
                }
            }
            socket.onmessage = function (event) {
                if (event.data != "pong") {
                    let tmp = JSON.parse(event.data);
                    if (tmp && tmp.event === "login") {
                        socket.send(JSON.stringify(subscribeMessage))
                    }
                    if (tmp && tmp.data && tmp.data[0]) {
                        let arr = openorder_data;
                        let data = tmp.data[0]

                        if (data.ordType == "post_only") {
                            if (data.state == "canceled") {
                                let d = postOnly_data.filter((item) => item.ordId == data.ordId)
                                let r = postOnly_data.filter((item) => item.ordId != data.ordId)
                                if (Number(data.uTime) - Number(d[0].time) <= 2000) {
                                    setTimeout(() => {
                                        setMessage({ type: "binance", code: -123, msg: "Due to the order could not be executed as maker, the Post Only order will be rejected. The order will not be recorded in the order history" })
                                    }, 500)
                                }
                            }
                            else {
                                postOnly_data.push({ ordId: data.ordId, time: data.uTime })
                            }
                        }

                        if (data.state == "live") {
                            let fil = arr.filter((item) => item.ordId != data.ordId)
                            fil.push({ ordId: data.ordId, instId: data.instId, ordType: data.ordType, px: data.px, fillSz: data.fillSz, sz: data.sz, side: data.side, tdMode: data.tdMode })
                            if (Alert[2] == "ON") {
                                playAudio2()
                            }
                            setOpenorder(fil)
                            dispatch(change_OKX_openorder(fil))
                            openorder_data = fil
                        }
                        else if (data.state == "partially_filled") {
                            let arr1 = []
                            let fil = arr.filter((item) => item.ordId != data.ordId)

                            fil.map(function (a, i) {
                                if (a.ordType == "conditional" || a.ordType == "trigger") arr1.push({ algoId: a.algoId, ordId: a.ordId, instId: a.instId, ordType: a.ordType, px: data.px, fillSz: a.fillSz, sz: a.sz, side: a.side, tdMode: a.tdMode })
                                else arr1.push({ ordId: a.ordId, instId: a.instId, ordType: a.ordType, px: a.px, fillSz: a.fillSz, sz: a.sz, side: a.side, tdMode: a.tdMode })
                            })
                            arr1.push({ ordId: data.ordId, instId: data.instId, ordType: data.ordType, px: data.px, fillSz: data.fillSz, sz: data.sz, side: data.side, tdMode: data.tdMode })

                            setOpenorder(arr1)
                            dispatch(change_OKX_openorder(arr1))
                            openorder_data = arr1
                        }
                        else {
                            if (data.state == "filled" && Number(data.pnl) != 0) {
                                if (Number(data.pnl) > 0 && Alert[1] == "ON") {
                                    playAudio3()
                                }
                                else if (Number(data.pnl) < 0 && Alert[1] == "ON") {
                                    playAudio4()
                                }
                            }

                            let arr1 = []
                            let fil = arr.filter((item) => item.ordId != data.ordId)

                            fil.map(function (a, i) {
                                if (a.ordType == "conditional" || a.ordType == "trigger") arr1.push({ algoId: a.algoId, ordId: a.ordId, instId: a.instId, ordType: a.ordType, px: data.px, fillSz: a.fillSz, sz: a.sz, side: a.side, tdMode: a.tdMode })
                                else arr1.push({ ordId: a.ordId, instId: a.instId, ordType: a.ordType, px: a.px, fillSz: a.fillSz, sz: a.sz, side: a.side, tdMode: a.tdMode })
                            })
                            setOpenorder(arr1)
                            dispatch(change_OKX_openorder(arr1))
                            openorder_data = arr1
                        }
                        setOrderReload(e => !e)
                    }
                }
            }
            socket.onclose = function (event) {
                console.log("okx futures_openorder Close")
                Timestamp()
            };
            socket.error = function (error) {
                window.location.reload()
            }
            return () => {
                socket.close();
            }
        }
    }, [login, timestamp])

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString && timestamp.length > 0) {
            const obj = JSON.parse(objString);
            let token = obj.value;
            let payload = token.substring(token.indexOf('.') + 1, token.lastIndexOf('.'));
            let dec = base64.decode(payload);
            const loginMessage = {
                op: "login",
                args: [
                    {
                        apiKey: JSON.parse(dec).apiKey,
                        passphrase: JSON.parse(dec).passphrase,
                        timestamp: timestamp,
                        sign: CryptoJS.enc.Base64.stringify(CryptoJS.HmacSHA256(timestamp + 'GET' + '/users/self/verify', JSON.parse(dec).secretKey))
                    }
                ]
            }
            const subscribeMessage = {
                op: "subscribe",
                args: [
                    {
                        channel: "orders-algo",
                        instType: "SWAP",
                    }
                ]
            }

            let socket = JSON.parse(dec).userId === 1 ? new WebSocket(`wss://wspap.okx.com:8443/ws/v5/business?brokerId=9999`) : new WebSocket(`wss://ws.okx.com:8443/ws/v5/business`);
            socket.onopen = function () {
                socket.send(JSON.stringify(loginMessage))
                const intervalId = setInterval(() => {
                    if (socket.readyState === WebSocket.OPEN) {
                        socket.send("ping");
                    }
                }, 25000)

                return () => {
                    clearInterval(intervalId)
                }
            }
            socket.onmessage = function (event) {
                if (event.data != "pong") {
                    let tmp = JSON.parse(event.data);

                    if (tmp.event === "login") {
                        socket.send(JSON.stringify(subscribeMessage))
                    }
                    if (tmp && tmp.data && tmp.data[0]) {
                        let arr = openorder_data
                        let data = tmp.data[0]

                        if (data.state == "live") {
                            let fil = arr.filter((item) => item.algoId != data.algoId)
                            if (data.ordType == "conditional") fil.push({ algoId: data.algoId, ordId: data.ordId, instId: data.instId, ordType: data.ordType, px: data.slTriggerPx.length > 0 ? data.slTriggerPx : data.tpTriggerPx, fillSz: 0, sz: data.sz, side: data.side, tdMode: data.tdMode })
                            else fil.push({ algoId: data.algoId, ordId: data.ordId, instId: data.instId, ordType: data.ordType, px: data.triggerPx, fillSz: 0, sz: data.sz, side: data.side, tdMode: data.tdMode })

                            if (Alert[2] == "ON") {
                                playAudio2()
                            }
                            setOpenorder(fil)
                            dispatch(change_OKX_openorder(fil))
                            openorder_data = fil
                        }
                        else if (data.state == "partially_filled") {
                            let arr1 = []
                            let fil = arr.filter((item) => item.algoId != data.algoId)

                            fil.map(function (a, i) {
                                if (a.ordType == "conditional" || a.ordType == "trigger") arr1.push({ algoId: a.algoId, ordId: a.ordId, instId: a.instId, ordType: a.ordType, px: a.px, fillSz: a.fillSz, sz: a.sz, side: a.side, tdMode: a.tdMode })
                                else arr1.push({ ordId: a.ordId, instId: a.instId, ordType: a.ordType, px: a.px, fillSz: a.fillSz, sz: a.sz, side: a.side, tdMode: a.tdMode })
                            })

                            if (data.ordType == "conditional") arr1.push({ algoId: data.algoId, ordId: data.ordId, instId: data.instId, ordType: data.ordType, px: data.slTriggerPx.length > 0 ? data.slTriggerPx : data.tpTriggerPx, fillSz: 0, sz: data.sz, side: data.side, tdMode: data.tdMode })
                            else arr1.push({ algoId: data.algoId, ordId: data.ordId, instId: data.instId, ordType: data.ordType, px: data.triggerPx, fillSz: 0, sz: data.sz, side: data.side, tdMode: data.tdMode })

                            setOpenorder(arr1)
                            dispatch(change_OKX_openorder(arr1))
                            openorder_data = arr1
                        }
                        else {
                            let arr1 = []
                            let fil = arr.filter((item) => item.algoId != data.algoId)

                            fil.map(function (a, i) {
                                if (a.ordType == "conditional" || a.ordType == "trigger") arr1.push({ algoId: a.algoId, ordId: a.ordId, instId: a.instId, ordType: a.ordType, px: a.px, fillSz: a.fillSz, sz: a.sz, side: a.side, tdMode: a.tdMode })
                                else arr1.push({ ordId: a.ordId, instId: a.instId, ordType: a.ordType, px: a.px, fillSz: a.fillSz, sz: a.sz, side: a.side, tdMode: a.tdMode })
                            })
                            setOpenorder(arr1)
                            dispatch(change_OKX_openorder(arr1))
                            openorder_data = arr1
                        }
                        setOrderReload(e => !e)
                    }
                }
            }
            socket.onclose = function (event) {
                console.log("okx futures_algo_openorder Close")
                Timestamp1()
            };
            socket.error = function (error) {
                window.location.reload()
            }
            return () => {
                socket.close();
            }
        }
    }, [login, timestamp1])




    useEffect(() => {
        let nextArr = []
        let cnt = false
        let t_tmm = 0
        let t_upnl = 0
        let t_price = 0
        let t_quantity = 0
        let t_leverage = 1

        const objString = localStorage.getItem('token');
        if (objString && OKX_position) {
            arr = OKX_position
            // arr.sort((a, b) => {
            //     if (a.instId > b.instId) return 1;
            //     if (a.instId < b.instId) return -1;
            // })

            arr.map(function (a, i) {
                if (symbol == a.instId) {
                    t_price = Number(a.avgPx)
                    t_quantity = Number((Number(a.pos) * Number(instrumentsData.ctVal)).toFixed(5))
                    t_leverage = Number(a.lever)
                    cnt = true
                }
                else {
                    t_tmm += Number(a.notionalUsd)
                    t_upnl += Number(a.uplLastPx)
                }
                nextArr.push({ symbol: a.instId, size: a.pos })
            })

            setCheckCurrentPosition(cnt)

            let ArraysEqual = isEqual(preArr, nextArr);

            if (!ArraysEqual) {
                setOrderReload(e => !e)
                if (Alert[1] == "ON") {
                    playAudio()
                }
            }

            preArr = nextArr
            setTMM(t_tmm)
            setUPNL(t_upnl)
            setPBoth([t_price, t_quantity, t_leverage])
            setPositionData(arr)
        }
    }, [OKX_position, symbol])

    useEffect(() => {
        let cnt = 0
        let marginType = ""
        let risk_cross = 0
        let risk_isolated = 0
        let risk_cross_WB = 0
        let risk_isolated_WB = 0

        if (positionData) {
            for (let i = 0; i < positionData.length; i++) {
                let tmp = positionData[i]
                if (symbol == tmp.instId) {
                    cnt = 1
                    setFundingFee(Math.abs(tmp.notionalUsd))
                    setPositionDirect(Number(tmp.pos))

                    marginType = tmp.mgnMode
                    risk_isolated = Math.abs(Number(tmp.notionalUsd))
                    risk_isolated_WB = Math.abs(Number(tmp.notionalUsd) / Number(tmp.lever))
                }
                if (tmp.mgnMode == "cross") {
                    risk_cross += Math.abs(Number(tmp.notionalUsd))
                }
                else {
                    risk_cross_WB += Math.abs(Number(tmp.notionalUsd) / Number(tmp.lever))
                }
            }
            if (cnt === 0) {
                setFundingFee(0)
                setPositionDirect(0)
                setRisk_balance(0)
                setRisk_position(0)
                setMargin_type("0")
            }
            else {
                setMargin_type(marginType)
                if (marginType == "isolated") {
                    setRisk_position(risk_isolated)
                    setRisk_balance(risk_isolated_WB)
                }
                else {
                    setRisk_position(risk_cross)
                    setRisk_balance(risk_cross_WB)
                }
            }
        }
    }, [positionData])

    let cnt_liq = 0

    useEffect(() => {
        if (cnt_liq > 0) {
            if (Alert[1] == "ON") {
                playAudio1()
            }
        }
        cnt_liq = 1
    }, [OKX_liquidation])

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            FuturesLever(token)
            OKXMargin()
        }
    }, [marginbtn, symbol])

    const OKXMargin = async () => {
        let arr = symbol.split("-SWAP")
        await axios.get(`https://www.okx.com/api/v5/public/position-tiers`, {
            params: {
                instType: "SWAP",
                instId: symbol,
                tdMode: "cross",
                uly: arr && arr[0] ? arr[0] : "",
            },
        }).then((response) => {
            let tmp = response.data.data
            if (tmp) {
                setMarginList(tmp)
            }
        }).catch((error) => {
            console.log(error)
            // dispatch(check_Error({ "bol": true, "no": 100, "msg": error }))
        })
    }

    const FuturesLever = async (token) => {
        await axios.get(process.env.REACT_APP_API + `/api/v1/trade/okx/swap/leverage-info`, {
            params: {
                instId: symbol,
                mgnMode: marginbtn === 0 ? "cross" : "isolated"
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            let tmp = response.data.data
            if (tmp && tmp[0]) {
                setMarginbtn1(Number(tmp[0].lever))
            }
        }).catch((error) => {
            dispatch(check_Error({ "bol": true, "no": 101, "msg": error }))
        })
    }

    const FuturesInstruments = async () => {
        await axios.get(process.env.REACT_APP_API + "/api/v1/market/okx/instruments/swap", {
            params: {
                instId: symbol
            }
        }).then((response) => {
            let tmp = response.data.data
            if (tmp && tmp[0]) {
                setInstrumentData(tmp[0])
                setFirstmargin(Number(tmp[0].lever))
            }
        }).catch((error) => {
            dispatch(check_Error({ "bol": true, "no": 102, "msg": error }))
        })
    }

    const OKX_First_PositionData = async (token) => {
        await axios.get(process.env.REACT_APP_API + `/api/v1/trade/okx/swap/position`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            let tmp = response.data.data
            if (tmp) {
                let cnt = false
                for (let i = 0; i < tmp.length; i++) {
                    if (symbol == tmp[i].instId) {
                        cnt = true
                    }
                }
                setCheckCurrentPosition(cnt)
                setPositionData(tmp)
            }
        }).catch((error) => {
            dispatch(check_Error({ "bol": true, "no": 103, "msg": error }))
        })
    }

    useEffect(() => {
        if (OKX_wallet_usdt && OKX_wallet_usdt[0] && OKX_wallet_usdt[0].availBal) {
            setCurrent(OKX_wallet_usdt[0].availBal)
            setFwallet(OKX_wallet_usdt[0])
        }
    }, [OKX_wallet_usdt])

    const changeTab = (e) => {
        setTab(e)
        localStorage.setItem('f_coinList', e)
    }

    const change_OrderbookTab = (e) => {
        setTab1(e)
        localStorage.setItem('f_orderbook', e)
    }

    const [isBlinking, setIsBlinking] = useState(false);
    const [priceDirect, setPriceDirect] = useState(false)
    const [indexPrice, setIndexPrice] = useState([])
    const [fundingRate, setFundingRate] = useState("")
    const [nextTime, setNextTime] = useState("00:00:00")
    const [close, setClose] = useState(false)
    const [close1, setClose1] = useState(false)

    useEffect(() => {
        if (prePrice != currentPrice) {
            if (currentPrice > prePrice) setPriceDirect(true)
            else setPriceDirect(false)
            prePrice = currentPrice
        }
    }, [data])

    const handleButtonClick = () => {
        setIsBlinking(true);

        setTimeout(() => {
            setIsBlinking(false);
        }, 400);
    };

    useEffect(() => {
        pre_symbol = symbol
        let date = 0
        let id;

        let socket = new WebSocket(`wss://ws.okx.com:8443/ws/v5/public`);
        const subscribeMessage = JSON.stringify({
            op: "subscribe",
            args: [
                {
                    channel: "funding-rate",
                    instId: symbol
                }
            ]
        });

        socket.onopen = () => {
            socket.send(subscribeMessage);
        };

        socket.onmessage = function (event) {
            if (event.data) {
                let tmp = JSON.parse(event.data);
                if (tmp.data && tmp.data[0]) {
                    let tmp1 = tmp.data[0]
                    setFundingRate(Number(tmp1.fundingRate))

                    clearInterval(id);
                    date = Math.floor((Number(tmp1.fundingTime) - new Date().getTime()) / 1000)
                    id = setInterval(() => {
                        date -= 1
                        let formattedDate = `${padZero(Math.floor(date / (60 * 60)))}:${padZero(Math.floor((date / 60) % 60))}:${padZero(Math.floor(date % 60))}`;
                        setNextTime(formattedDate)
                    }, 1000)
                }
            }
        }
        socket.onclose = function (event) {
            if (pre_symbol == symbol) {
                setClose(e => !e)
            }
        };
        socket.error = function (error) {
            window.location.reload()
        }
        return () => {
            socket.close();
            clearInterval(id);
        }
    }, [symbol, close])

    useEffect(() => {
        pre_symbol = symbol
        let ccy = symbol ? symbol.split("-SWAP")[0] : ""

        let socket1 = new WebSocket(`wss://ws.okx.com:8443/ws/v5/public`);
        const subscribeMessage1 = JSON.stringify({
            op: "subscribe",
            args: [
                {
                    channel: "mark-price",
                    instId: symbol
                },
                {
                    channel: "index-tickers",
                    instId: ccy
                }
            ]
        });

        socket1.onopen = () => {
            socket1.send(subscribeMessage1);
        };

        socket1.onmessage = function (event) {
            if (event.data) {
                let tmp = JSON.parse(event.data);
                if (tmp.arg && tmp.arg.channel && tmp.arg.channel == 'mark-price') {
                    if (tmp.data && tmp.data[0]) {
                        setMarkPrice(tmp.data[0].markPx)
                        dispatch(changeOKX_markPrice(tmp.data[0].markPx))
                    }
                }
                else if (tmp.arg && tmp.arg.channel && tmp.arg.channel == 'index-tickers')
                    if (tmp.data && tmp.data[0]) {
                        setIndexPrice(tmp.data[0].idxPx)
                    }
            }
        }
        socket1.onclose = function (event) {
            if (pre_symbol == symbol) {
                setClose1(e => !e)
            }
        };
        socket1.error = function (error) {
            window.location.reload()
        }
        return () => {
            socket1.close();
        }
    }, [symbol, close1])
    let positionD = positionData.filter((item) => item.symbol == symbol)
    let inst = symbol.split('-')

    return (
        <div className={`futures ${darkname}`}>
            <Modal message={message} />
            {
                isPlaying
                    ?
                    <audio autoPlay={isPlaying}>
                        <source src="/audio/Sound.2.mp3" type="audio/mp3" />
                    </audio>
                    :
                    ""
            }
            {
                isPlaying1
                    ?
                    <audio autoPlay={isPlaying1}>
                        <source src="/audio/Sound.5.mp3" type="audio/mp3" />
                    </audio>
                    :
                    ""
            }
            {
                isPlaying2
                    ?
                    <audio autoPlay={isPlaying2}>
                        <source src="/audio/Sound.6.mp3" type="audio/mp3" />
                    </audio>
                    :
                    ""
            }
            {
                isPlaying3
                    ?
                    <audio autoPlay={isPlaying3}>
                        <source src="/audio/Sound.3.mp3" type="audio/mp3" />
                    </audio>
                    :
                    ""
            }
            {
                isPlaying4
                    ?
                    <audio autoPlay={isPlaying4}>
                        <source src="/audio/Sound.4.mp3" type="audio/mp3" />
                    </audio>
                    :
                    ""

            }
            {
                windowtype
                    ?
                    <div className='futures-box'>
                        <ErrorBoundary>
                            <Fsection1 tab={tab} changeTab={changeTab} USDTSymbolF={USDTSymbolF} symbol={symbol} />
                        </ErrorBoundary>
                        <div className={tab ? 'futures-section2' : 'futures-section2-check'}>
                            <div className='futures-section3'>
                                <div className='futures-section5'>
                                    <ErrorBoundary>
                                        <Fsection6 tab={tab} tab1={tab1} changeTab={changeTab} change_OrderbookTab={change_OrderbookTab} data={data} symbol={symbol} instrumentsData={instrumentsData} max={max} positionData={positionData} currentPrice={currentPrice} firstmargin={firstmargin} marginbtn={marginbtn} setMarginbtn={setMarginbtn} marginbtn1={marginbtn1} setMarginbtn1={setMarginbtn1} markPrice={markPrice} setMarkPrice={setMarkPrice} maxPosition={maxPosition} fundingFee={fundingFee} positionDirect={positionDirect} orderReload={orderReload} />
                                    </ErrorBoundary>
                                    <ErrorBoundary>
                                        <Fsection7 tab1={tab1} tab3={tab3} tab4={tab4} setTab4={setTab4} setTab3={setTab3} setLastBuy={setLastBuy} setLastSell={setLastSell} data={data} setCheckordertab={setCheckordertab} setSpeedorder={setSpeedorder} symbol={symbol} instrumentsData={instrumentsData} setMax={setMax} max={max} tradeVolume={tradeVolume} setCurrentPrice={setCurrentPrice} currentPrice={currentPrice} checkisPlaying={checkisPlaying} btnbtn={btnbtn} markPrice={markPrice} qtypoint={qtypoint} />
                                    </ErrorBoundary>
                                </div>
                                <ErrorBoundary>
                                    <Fsection8 tab5={tab5} setTab5={setTab5} symbol={symbol} windowtypeM={windowtypeM} USDTSymbolF={USDTSymbolF} setPositionAmount={setPositionAmount} positionData={positionData} setTradeVolume={setTradeVolume} tradeVolume={tradeVolume} setCheckisPlaying={setCheckisPlaying} checkCurrentPosition={checkCurrentPosition} currentPrice={currentPrice} setOrderReload={setOrderReload} />
                                </ErrorBoundary>
                            </div>
                            <ErrorBoundary>
                                <Fsection4 data={data} lastBuy={lastBuy} lastSell={lastSell} symbol={symbol} setCheckordertab={setCheckordertab} checkordertab={checkordertab} speedorder={speedorder} instrumentsData={instrumentsData} positionAmount={positionAmount} max={max} currentPrice={currentPrice} checkCurrentPosition={checkCurrentPosition} current={current} fwallet={fwallet} firstmargin={firstmargin} marginbtn={marginbtn} setMarginbtn={setMarginbtn} marginbtn1={marginbtn1} setMarginbtn1={setMarginbtn1} btnbtn={btnbtn} setBtnbtn={setBtnbtn} risk_balance={risk_balance} risk_position={risk_position} margin_type={margin_type} marginList={marginList} setMaxPosition={setMaxPosition} tmm={tmm} upnl={upnl} pBoth={pBoth} minCoin={minCoin} qtypoint={qtypoint} minSz={minSz} />
                            </ErrorBoundary>
                        </div>
                    </div>
                    :
                    <div className='futures-box'>
                        <ErrorBoundary>
                            <Fsection1 tab={tab} changeTab={changeTab} USDTSymbolF={USDTSymbolF} symbol={symbol} />
                        </ErrorBoundary>
                        <div className='futures-section9'>
                            <ErrorBoundary>
                                <Fsection6 tab={tab} tab1={tab1} changeTab={changeTab} change_OrderbookTab={change_OrderbookTab} data={data} symbol={symbol} instrumentsData={instrumentsData} max={max} positionData={positionData} currentPrice={currentPrice} firstmargin={firstmargin} marginbtn={marginbtn} setMarginbtn={setMarginbtn} marginbtn1={marginbtn1} setMarginbtn1={setMarginbtn1} markPrice={markPrice} setMarkPrice={setMarkPrice} maxPosition={maxPosition} fundingFee={fundingFee} positionDirect={positionDirect} orderReload={orderReload} />
                            </ErrorBoundary>
                            <ErrorBoundary>
                                <Fsection8 tab5={tab5} setTab5={setTab5} symbol={symbol} windowtypeM={windowtypeM} USDTSymbolF={USDTSymbolF} setPositionAmount={setPositionAmount} positionData={positionData} setTradeVolume={setTradeVolume} tradeVolume={tradeVolume} setCheckisPlaying={setCheckisPlaying} checkCurrentPosition={checkCurrentPosition} currentPrice={currentPrice} setOrderReload={setOrderReload} />
                            </ErrorBoundary>
                            <div className='futures-section10'>
                                <ErrorBoundary>
                                    <Fsection7 tab1={tab1} tab3={tab3} tab4={tab4} setTab4={setTab4} setTab3={setTab3} setLastBuy={setLastBuy} setLastSell={setLastSell} data={data} setCheckordertab={setCheckordertab} setSpeedorder={setSpeedorder} symbol={symbol} instrumentsData={instrumentsData} setMax={setMax} max={max} tradeVolume={tradeVolume} setCurrentPrice={setCurrentPrice} currentPrice={currentPrice} checkisPlaying={checkisPlaying} btnbtn={btnbtn} markPrice={markPrice} qtypoint={qtypoint} />
                                </ErrorBoundary>
                                <ErrorBoundary>
                                    <Fsection4 data={data} lastBuy={lastBuy} lastSell={lastSell} symbol={symbol} setCheckordertab={setCheckordertab} checkordertab={checkordertab} speedorder={speedorder} instrumentsData={instrumentsData} positionAmount={positionAmount} max={max} currentPrice={currentPrice} checkCurrentPosition={checkCurrentPosition} current={current} fwallet={fwallet} firstmargin={firstmargin} marginbtn={marginbtn} setMarginbtn={setMarginbtn} marginbtn1={marginbtn1} setMarginbtn1={setMarginbtn1} btnbtn={btnbtn} setBtnbtn={setBtnbtn} risk_balance={risk_balance} risk_position={risk_position} margin_type={margin_type} marginList={marginList} setMaxPosition={setMaxPosition} tmm={tmm} upnl={upnl} pBoth={pBoth} minCoin={minCoin} qtypoint={qtypoint} minSz={minSz} />
                                </ErrorBoundary>
                            </div>

                            <div style={{ padding: "5px 5px 0" }}>
                                <div className='futures-section4-top-9 display-none-700-ok'>
                                    <div className='futures-section4-top-9-1 red'>{t('futures_152')}</div>
                                    <div className='futures-section4-top-9-2 green'>{fwallet.eq ? changeNum((risk_balance * 100 / Number(fwallet.eq)).toFixed(2)) : "0.00"}%</div>
                                    <div className='futures-section4-top-9-2'>%</div>
                                </div>
                                <div className='futures-section4-top-10 display-none-700-block'>
                                    <div className='futures-section4-top-10-1'>
                                        <div className='futures-section4-top-10-1-1'>{t('futures_0')}</div>
                                        <div className='futures-section4-top-10-1-2'>{fwallet.eq ? changeNum(Number(fwallet.eq).toFixed(3)) : "0.000"}</div>
                                        <div className='futures-section4-top-10-1-2'>USDT</div>
                                    </div>
                                    <div className='futures-section4-top-10-1'>
                                        <div className='futures-section4-top-10-1-1'>{t('futures_1')}</div>
                                        <div className='futures-section4-top-10-1-2'>{fwallet.isoEq ? changeNum(Number(fwallet.isoEq).toFixed(3)) : "0.000"}</div>
                                        <div className='futures-section4-top-10-1-2'>USDT</div>
                                    </div>
                                    <div className='futures-section4-top-10-1'>
                                        <div className='futures-section4-top-10-1-1'>{t('futures_2')}</div>
                                        <div className='futures-section4-top-10-1-2'>{fwallet.availBal ? changeNum(Number(fwallet.availBal).toFixed(3)) : "0.000"}</div>
                                        <div className='futures-section4-top-10-1-2'>USDT</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </div>
    )
}

export default Futures;